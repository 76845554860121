import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import {
  LookupRequest,
  SaveEmbeddingsRequest,
  SummarizationRequest,
} from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

function Data() {
  useLogPageView("Source");
  const [record, setRecord] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [lookupRequestVisible, setLookupRequestVisible] = useState(true);
  const [calculateEmbeddingsVisible, setCalculateEmbeddingsVisible] =
    useState(true);
  const [calculateEmbeddingsId, setCalculateEmbeddingsId] = useState("");
  const [calculateEmbeddingsRequest, setCalculateEmbeddingsRequest] =
    useState(null);
  const [embeddingMessage, setEmbeddingMessage] = useState("");
  const [url, setUrl] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();
  const colPath = `projects/${activeProjectId}/${COLLECTION}/${id}/chunks`;
  const [chunks = [], loadMore, hasMore] = useFirestorePagination(colPath);

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
      setDisplayName(data.displayName || "");
      setDescription(data.description || "");
      setUrl(data.url || "");
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  useEffect(() => {
    if (!record) return;
    if (!calculateEmbeddingsId) return;
    // subscribe to that calculate_embeddings record
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "calculate_embeddings",
      calculateEmbeddingsId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      if (data.status === "COMPLETE") {
        setEmbeddingMessage("Embeddings calculated!");
      } else if (data.status === "ERROR") {
        setEmbeddingMessage("Error calculating embeddings...");
      } else if (data.status === "PENDING") {
        setEmbeddingMessage("Calculating embeddings...");
      }
      setCalculateEmbeddingsRequest(data);
    });
    return unsubscribe;
  }, [record, calculateEmbeddingsId, activeProjectId]);

  const changeName = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { displayName });
  };

  const changeDescription = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { description });
  };

  const changeUrl = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { url });
  };

  const deleteChunk = async chunkId => {
    if (!activeProjectId) return;
    log("deleteSourceChunk");
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "chunks",
      chunkId
    );
    return await deleteDoc(docRef);
  };

  const populateSource = async () => {
    if (!activeProjectId) return;
    log("populateSource");
    const { url, id } = record;
    if (!url) return;
    setLookupRequestVisible(false);
    // we'll use the url to get the source by calling firebase function to
    // scrape the url onCreate of a new lookup_request document
    const lookupRequest = LookupRequest(url, id);
    if (!lookupRequest) return;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "lookup_requests",
      lookupRequest.id
    );
    await setDoc(docRef, lookupRequest);
  };

  const calculateEmbeddings = async () => {
    if (!activeProjectId) return;
    log("calculateEmbeddings");
    setCalculateEmbeddingsVisible(false);
    const calculateEmbeddingsRequest = {
      sourceId: id,
      id: uuid(),
      lastUpdated: Timestamp.now(),
    };
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "calculate_embeddings",
      calculateEmbeddingsRequest.id
    );
    setCalculateEmbeddingsId(calculateEmbeddingsRequest.id);
    setEmbeddingMessage("Calculating embeddings...");
    await setDoc(docRef, calculateEmbeddingsRequest);
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, update);
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const createSummarizationRequest = async () => {
    if (!activeProjectId || !id || !record) return;
    const req = SummarizationRequest();
    req.sourceId = id;
    req.projectId = activeProjectId;
    req.sourceDisplayName = record.displayName;
    // pick which chunks to summarize
    req.chunkIdsToSummarize = chunks.filter(c => c.saved).map(c => c.id);
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "sources",
      id,
      "summarization_requests",
      req.id
    );
    await setDoc(docRef, req);
  };

  const saveChunks = async () => {
    if (!activeProjectId) return;
    const rec = SaveEmbeddingsRequest();
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "save_embeddings",
      rec.id
    );
    await setDoc(docRef, rec);
  };

  if (!record) return null;

  const renderNoteArea = () => {
    if (record?.locked) {
      return (
        <div className="note-area">
          <h3>Embeddings have been calculated. Source Locked.</h3>
        </div>
      );
    }
    return (
      <div className="note-area">
        <Tf
          fullWidth
          label="Notes go here..."
          multiline
          value={record?.content?.text || ""}
          onChange={e => updateRecord({ content: { text: e.target.value } })}
        />
        {record?.locked ||
        (calculateEmbeddingsVisible && record?.content?.text) ? (
          <Button
            variant="contained"
            sx={{ background: "#F37", float: "right" }}
            onClick={calculateEmbeddings}
          >
            Calculate Embeddings
          </Button>
        ) : (
          <div style={{ display: "inline-block", float: "right" }}>
            {embeddingMessage}
          </div>
        )}
      </div>
    );
  };

  const renderUrlArea = () => {
    if (record?.locked) {
      return (
        <>
          <h3>URL:</h3>
          <a href={record?.url}> {record?.url}</a>
        </>
      );
    }
    return (
      <>
        <h3>Change URL</h3>
        <TextField
          fullWidth
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
        <div style={{ marginTop: 12 }}>
          <Button onClick={changeUrl}>Update URL</Button>
          {lookupRequestVisible && record?.url && (
            <Button
              variant="contained"
              sx={{ background: "black", marginLeft: "12px" }}
              onClick={populateSource}
            >
              Read Webpage
            </Button>
          )}
          {calculateEmbeddingsVisible && url && record?.content?.text ? (
            <Button
              variant="contained"
              sx={{ background: "#F37", float: "right" }}
              onClick={calculateEmbeddings}
            >
              Calculate Embeddings
            </Button>
          ) : (
            <div style={{ display: "inline-block", float: "right" }}>
              {embeddingMessage}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderWebpageArea = () => {
    return (
      <div className="webpage-area">
        {renderUrlArea()}
        <div className="source-content-area">
          <h3>Source Text</h3>
          <p>{record?.content?.text || "No source text found."}</p>
        </div>
      </div>
    );
  };

  const AddUrlInstruction = () => (
    <div className="source-instruction">Add and save a URL to get started.</div>
  );

  const ReadWebpageInstruction = ({ populateSource }) => (
    <div className="source-instruction">
      Click the{" "}
      <Button
        variant="contained"
        sx={{ background: "black" }}
        onClick={populateSource}
      >
        Read Webpage
      </Button>{" "}
      button to get the source
    </div>
  );

  const GettingSourceInstruction = () => (
    <div className="source-instruction">
      Getting the source. This may take a few seconds...
    </div>
  );

  const SourceChunks = ({ chunks = [], deleteChunk }) => {
    const saveEmbeddingsVisible = !!chunks.find(c => !c.saved);
    return (
      <div className="source-content-area">
        <h3>Source Chunks</h3>
        {chunks.map(c => (
          <div className="chunk-card" key={c.id}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => deleteChunk(c.id)}
            >
              <DeleteIcon />
            </IconButton>
            <ReactMarkdown className="react-markdown-code-block">
              {c.text}
            </ReactMarkdown>
            <div style={{ float: "right", marginTop: -24 }} className="faint">
              {c.saved ? (
                <div className="saved-chunk">Saved</div>
              ) : (
                <div className="unsaved-chunk">Unsaved</div>
              )}
            </div>
            <div style={{ clear: "both" }} />
          </div>
        ))}
        {hasMore ? (
          <Button onClick={loadMore} disabled={!hasMore}>
            Load more
          </Button>
        ) : (
          <p>All loaded</p>
        )}
        <Button onClick={createSummarizationRequest}>
          Summarize These Memories
        </Button>
        {saveEmbeddingsVisible ? (
          <Button
            variant="contained"
            sx={{ background: "#F37" }}
            onClick={saveChunks}
          >
            Save these Chunks to Vector Database
          </Button>
        ) : null}
      </div>
    );
  };

  const renderContentArea = () => {
    // first, if there are chunks, render those
    if (chunks.length) {
      return <SourceChunks chunks={chunks} deleteChunk={deleteChunk} />;
    }
    let paragraphs = [];
    if (record.content?.text) {
      const text = record.content.text;
      paragraphs = text.split("\n\n");
    }
    if (record?.type === "WEBPAGE" && !record?.url) {
      return <AddUrlInstruction />;
    }
    if (
      record?.type === "WEBPAGE" &&
      !paragraphs.length &&
      lookupRequestVisible
    ) {
      return <ReadWebpageInstruction populateSource={populateSource} />;
    }
    // this should actually be if a lookup request is in progress
    if (calculateEmbeddingsRequest?.status === "PENDING") {
      return <GettingSourceInstruction />;
    }
    return null;
  };

  const renderSummaryStats = () => {
    if (!record.content?.text) return null;
    const text = record.content.text;
    const sentences = text.split(".").length;
    const paragraphs = text.split("\n\n").length;
    const words = text.split(" ").length;
    const letters = record.content?.text.length;
    const averageLettersPerParagraph = Math.round(letters / paragraphs);
    const costPertoken = 0.0000004;
    const cost = costPertoken * letters;
    return (
      <div className="summary-stats">
        <span>Letters: {letters}</span>
        <span>Words: {words}</span>
        <span>Sentences: {sentences}</span>
        <span>Paragraphs: {paragraphs}</span>
        <span>Average letters per paragraph: {averageLettersPerParagraph}</span>
        <span>Approximate Cost to calculate embeddings: {cost}</span>
      </div>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <div>
            <h1>{record.displayName}</h1>
            <p>{record.description || ""}</p>
            {renderSummaryStats()}
            {record.type === "NOTE" ? renderNoteArea() : renderWebpageArea()}
            <h3>Edit Name</h3>
            <TextField
              fullWidth
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={changeName}
              sx={{ marginTop: "12px" }}
            >
              Change Name
            </Button>
            <h3>Change Description</h3>
            <TextField
              fullWidth
              multiline
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={changeDescription}
              sx={{ marginTop: "12px" }}
            >
              Change Description
            </Button>
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          {renderContentArea()}
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" className="padded DeletionArea">
            <h3> Danger Zone</h3>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteRecord}
            >
              Delete {record.displayName}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
