import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import {
  collection,
  getDoc,
  setDoc,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Sigil from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { IconSeedToken, Package, StageDirection } from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

const makePackageButtonStyle = {
  background: "linear-gradient(-45deg, #53f0C1, 20%, #ffff66 100%)",
  color: "#000",
  marginTop: 40,
};

// make the hook that will log the page view once and only once when the page is loaded

const CharacterImageSelect = ({ onSelect = () => {} }) => {
  const [characterImages, setCharacterImages] = useState([]);
  const [imageLimit, setImageLimit] = useState(12);
  const { activeProjectId = "" } = useEphemera();

  useEffect(() => {
    if (!activeProjectId) return;
    const q = query(
      collection(db, "projects", activeProjectId, "image_requests"),
      orderBy("created", "desc"),
      limit(imageLimit)
    );
    const unsubscribe = onSnapshot(q, snap => {
      const docs = [];
      snap.docs.forEach(doc => docs.push(doc.data()));
      setCharacterImages(docs);
    });
    return unsubscribe;
  }, [activeProjectId, imageLimit]);

  if (!characterImages.length) return null;
  const style = { display: "inline-block", cursor: "pointer", margin: 4 };

  return (
    <div>
      {characterImages.map(rec => {
        if (!rec.responseImageId) return null;
        return (
          <div
            onClick={() => onSelect(rec.responseImageId)}
            style={style}
            key={"select-character-image-" + rec.responseImageId}
          >
            <CharacterImage imageId={rec.responseImageId} dimension={64} />
          </div>
        );
      })}
      <div>
        <Button onClick={() => setImageLimit(limit => limit + 12)}>
          Load More...
        </Button>
      </div>
    </div>
  );
};

function NewStageDirectionCard({
  createStageDirection,
  characterDisplayName = "",
}) {
  const [stageDirection, setStageDirection] = useState("");
  return (
    <Card sx={{ p: 2, m: 2 }}>
      <h2>Add a new Stage Direction</h2>
      <p>
        Something like "{characterDisplayName || "Bucko"} is an AI chatbot
        pirate captain of the ship Leaky Abstraction".
      </p>
      <TextField
        fullWidth
        multiline
        rows={4}
        placeholder="Type a stage direction..."
        value={stageDirection}
        onChange={e => {
          setStageDirection(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          createStageDirection(stageDirection);
          setStageDirection("");
        }}
      >
        Submit
      </Button>
    </Card>
  );
}

function StageDirectionsArea({
  stageDirections = [],
  createStageDirection,
  deleteStageDirection,
  editStageDirection,
  character = {},
}) {
  const [text, setText] = useState("");

  const style = {
    background: "#f5f5f5",
    padding: 12,
  };

  return (
    <div style={style}>
      <h2>Stage Directions</h2>
      {stageDirections.map(stageDirection => (
        <Card
          key={"main-stage-directions-" + stageDirection.id}
          sx={{ p: 2, m: 2 }}
        >
          <IconButton
            onClick={() => deleteStageDirection(stageDirection.id)}
            style={{ float: "right" }}
          >
            <DeleteIcon />
          </IconButton>
          <p className="properly-formatted-text">{stageDirection.text}</p>
          <Accordion>
            <AccordionSummary sx={{ textAlign: "right" }}>
              edit
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Type a stage direction..."
                value={stageDirection.text || ""}
                onChange={e => {
                  stageDirection.text = e.target.value;
                  setText(e.target.value);
                }}
              />
              {text ? (
                <Button
                  onClick={() => {
                    editStageDirection(stageDirection.id, text);
                  }}
                >
                  Update Stage Direction
                </Button>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
      <NewStageDirectionCard
        createStageDirection={createStageDirection}
        characterDisplayName={character?.displayName}
      />
    </div>
  );
}

function CharacterImage({ imageId, dimension = 512 }) {
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (!imageId) return;
    const docRef = doc(
      db,
      `character_images_${dimension}x${dimension}`,
      imageId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId, dimension]);

  if (!record) return null;

  return (
    <img
      alt=""
      width={dimension + "px"}
      src={"data:image/png;base64, " + record.imageData}
    />
  );
}

function BigCharacterImage({ imageId }) {
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (!imageId) return;
    const docRef = doc(db, "character_images_512x512", imageId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId]);

  if (!record) return null;

  return (
    <div style={{}}>
      <img
        alt=""
        width={"100%"}
        src={"data:image/png;base64, " + record.imageData}
      />
    </div>
  );
}

const defaultIconSeedTokens = [
  IconSeedToken(),
  IconSeedToken(),
  IconSeedToken(),
  IconSeedToken(),
];

function Data() {
  useLogPageView("Character");
  const [record, setRecord] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [sources, setSources] = useState(null);
  const [services, setServices] = useState(null);
  const [iconSeedTokens, setIconSeedTokens] = useState(defaultIconSeedTokens);
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the templates
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "templates");
    const unsubscribe = onSnapshot(colRef, snap => {
      const templates = [];
      snap.forEach(doc => {
        templates.push(doc.data());
      });
      setTemplates(templates);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the sources
  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "sources");
    const unsubscribe = onSnapshot(colRef, snap => {
      const sources = [];
      snap.forEach(doc => {
        sources.push(doc.data());
      });
      setSources(sources);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the services
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "services");
    const unsubscribe = onSnapshot(colRef, snap => {
      const services = [];
      snap.docs.forEach(doc => services.push(doc.data()));
      setServices(services);
    });
    return unsubscribe;
  }, [activeProjectId]);

  useEffect(() => {
    if (!record) return;
    if (!sources) return;
    if (!record.sourceIds) return;
    // remove from the record those sources that have been deleted here
    const updateRecord = async update => {
      if (!activeProjectId) return;
      const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
      await updateDoc(docRef, { ...update, lastUpdated: Timestamp.now() });
    };
    const newSourceIds = record.sourceIds.filter(sourceId => {
      return sources.find(source => source.id === sourceId);
    });
    if (newSourceIds.length !== record.sourceIds.length) {
      console.log("cleaning up sourceIds", newSourceIds, record.sourceIds);
      updateRecord({ sourceIds: newSourceIds });
    }
  }, [activeProjectId, record, sources, id]);

  const editStageDirection = async (stageDirectionId, text) => {
    if (!activeProjectId) return;
    log("editCharacterStageDirection");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirections = record.stageDirections || [];
    const newStageDirections = stageDirections.map(stageDirection => {
      if (stageDirection.id === stageDirectionId) {
        return {
          ...stageDirection,
          text,
        };
      }
      return stageDirection;
    });
    return await updateDoc(docRef, { stageDirections: newStageDirections });
  };

  const deleteStageDirection = async stageDirectionId => {
    if (!activeProjectId) return;
    log("deleteCharacterStageDirection");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirections = record.stageDirections || [];
    const newStageDirections = stageDirections.filter(
      stageDirection => stageDirection.id !== stageDirectionId
    );
    return await updateDoc(docRef, { stageDirections: newStageDirections });
  };

  const createStageDirection = async text => {
    if (!activeProjectId) return;
    log("createCharacterStageDirection");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirection = StageDirection(text);
    const stageDirections = record.stageDirections || [];
    await updateDoc(docRef, {
      stageDirections: [...stageDirections, stageDirection],
    });
  };

  const makePackage = async () => {
    if (!activeProjectId) return;
    if (!record) return;
    // okay so, we're going to make a package
    // but this is special, because this package is going to have this
    // chatId as the package id
    // so if the user clicks the button more than once, it will only ever
    // create one package
    const packageId = id;
    const docRef = doc(db, "projects", activeProjectId, "packages", packageId);
    const snap = await getDoc(docRef);
    // if the package already exists, we're going to be adding to it potentially
    // so we need to get the existing package
    let existingPackage = snap.data();
    if (!existingPackage) {
      // if the package doesn't exist, we need to create it
      existingPackage = Package();
      existingPackage.id = packageId;
      existingPackage.displayName = record?.displayName;
      existingPackage.sourceProjectId = activeProjectId;
    }
    // and all the characters in the chat
    const characters = [record];
    characters.forEach(character => {
      if (!existingPackage.characterIds.includes(character.id)) {
        existingPackage.characterIds.push(character.id);
      }
      if (
        !existingPackage.templateIds.includes(character.templateId) &&
        character.templateId
      ) {
        existingPackage.templateIds.push(character.templateId);
      }
      // and whatever services are attached to the characters
      character?.serviceIds?.forEach(serviceId => {
        if (!existingPackage.serviceIds.includes(serviceId)) {
          existingPackage.serviceIds.push(serviceId);
        }
      });
    });
    // then save it
    await setDoc(docRef, existingPackage);
    // then navigate to that page
    history.push(`/packages/${packageId}`);
  };

  const moreIconSeedTokens = () => {
    setIconSeedTokens([
      ...iconSeedTokens,
      IconSeedToken(),
      IconSeedToken(),
      IconSeedToken(),
    ]);
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    log("deleteCharacter");
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { ...update, lastUpdated: Timestamp.now() });
  };

  const setIconSeedToken = async iconSeedToken => {
    if (!activeProjectId) return;
    log("setIconSeedToken");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const previousIconSeedToken = record.iconSeedToken || {
      id: record.id,
      seed: record.id,
      gen: "xenophon",
    };
    // append the previous icon seed to the list of icon seeds
    const newIconSeedTokens = [...iconSeedTokens, previousIconSeedToken];
    // remove duplicates
    const uniqueIconSeedTokens = [...new Set(newIconSeedTokens)];
    // remove the current icon seed
    setIconSeedTokens(
      uniqueIconSeedTokens.filter(t => t.seed !== iconSeedToken.seed)
    );
    await updateDoc(docRef, { iconSeedToken }, { merge: true });
  };

  if (!record) return null;

  const renderSalutationArea = () => {
    return (
      <div className="salutation-area">
        <h2>Salutation </h2>
        <p className="faint">
          The Character will say this upon entering a chat
        </p>
        <Tf
          id="salutation"
          fullWidth
          placeholder="Salutation"
          value={record.salutation || ""}
          onChange={e => updateRecord({ salutation: e.target.value })}
        />
        <div style={{ height: 40 }} />
      </div>
    );
  };

  const icons = [];
  iconSeedTokens.forEach(t => {
    icons.push(
      <span
        onClick={() => setIconSeedToken(t)}
        style={{ cursor: "pointer" }}
        key={t.seed}
      >
        <Sigil seed={t.seed} style={{ width: 64 }} gen={t.gen} />
      </span>
    );
  });

  let seed = record.iconSeed || record.id;
  if (record.iconSeedToken) seed = record.iconSeedToken.seed;
  let gen = "xenophon";
  if (record.iconSeedToken) gen = record.iconSeedToken.gen;
  let templateDisplayName = "None";
  if (record.templateId) {
    const template = templates.find(t => t.id === record.templateId);
    if (template) {
      const path = "/templates/" + template.id;
      templateDisplayName = <Link to={path}>{template.displayName}</Link>;
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <div style={{ textAlign: "center" }}>
            {record?.photoId ? (
              <BigCharacterImage imageId={record.photoId} />
            ) : (
              <Sigil seed={seed} style={{ width: 256 }} gen={gen} />
            )}
          </div>
          <div>
            <Tf
              label="Character Name"
              InputProps={{ style: { fontSize: "2em" } }}
              value={record?.displayName}
              onChange={e => updateRecord({ displayName: e.target.value })}
              fullWidth
            />
            <div style={{ height: 24 }} />
            <Tf
              label="Character Description"
              value={record?.description}
              onChange={e => updateRecord({ description: e.target.value })}
              fullWidth
            />
            {record.photoId ? null : (
              <div>
                <h3>Change Profile Icon</h3>
                {icons}
                <p
                  onClick={() => moreIconSeedTokens()}
                  style={{ cursor: "pointer" }}
                >
                  more icons...
                </p>
              </div>
            )}
            <CharacterImageSelect
              onSelect={photoId => updateRecord({ photoId })}
            />
            <Tf
              label="Character Photo Id"
              value={record?.photoId || ""}
              onChange={e => updateRecord({ photoId: e.target.value })}
              fullWidth
            />
            <Link to={"/studios"}>Create a new profile photo</Link>
          </div>
        </Grid>
        <Grid item lg={5} xs={12}>
          {renderSalutationArea()}
          <StageDirectionsArea
            stageDirections={record.stageDirections}
            createStageDirection={createStageDirection}
            deleteStageDirection={deleteStageDirection}
            editStageDirection={editStageDirection}
            character={record}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className="character-options-area">
            {templates?.length > 0 ? (
              <>
                <h3>
                  Current Template for {record?.displayName}:{" "}
                  {templateDisplayName}
                </h3>
                <Select
                  fullWidth
                  placeholder="Select a Template"
                  value={record.templateId || ""}
                  onChange={e => {
                    log("updateTemplateAssignedToCharacter");
                    updateRecord({ templateId: e.target.value });
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {templates.map(template => {
                    return (
                      <MenuItem key={template.id} value={template.id}>
                        {template.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : null}
            {services?.length > 0 ? (
              <>
                <h3>Add Services to the Character</h3>
                <Select
                  fullWidth
                  multiple
                  value={record?.serviceIds || []}
                  onChange={e => {
                    log("updateServicesAssignedToCharacter");
                    updateRecord({ serviceIds: e.target.value });
                  }}
                >
                  {services?.map(service => {
                    return (
                      <MenuItem key={service.id} value={service.id}>
                        {service.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : null}
            {sources?.length > 0 ? (
              <>
                <h3>Add Sources</h3>
                <Select
                  fullWidth
                  placeholder="Select a Source"
                  value={record?.sourceIds || []}
                  multiple
                  onChange={e => {
                    log("updateSourcesAssignedToCharacter");
                    updateRecord({ sourceIds: e.target.value });
                  }}
                >
                  {sources &&
                    sources.map(s => {
                      return (
                        <MenuItem key={s.id} value={s.id}>
                          {s.displayName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </>
            ) : null}

            <h3 className="memory-switch-area">
              <Switch
                checked={record?.memoryFormationEnabled}
                onChange={e => {
                  log("updateMemoryFormationEnabled");
                  updateRecord({ memoryFormationEnabled: e.target.checked });
                }}
                name="memoryFormationEnabled"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              Allow the automatic formation of new memories from notes
            </h3>
            <RadioGroup
              aria-label="model"
              name="model"
              row
              value={record?.model || "text-davinci-003"}
              onChange={e => {
                log("updateCharacterLLM");
                updateRecord({ model: e.target.value });
              }}
            >
              <FormControlLabel
                value="text-davinci-003"
                control={<Radio />}
                label="GPT-3"
              />
              <FormControlLabel
                value="gpt-3.5-turbo"
                control={<Radio />}
                label="GPT-3.5 Turbo"
              />
              <FormControlLabel
                value="gpt-4"
                control={<Radio />}
                label="GPT-4"
              />
            </RadioGroup>
            <h3> Temperature </h3>
            <Slider
              value={record?.temperature || 0}
              onChange={(e, v) => {
                log("updateCharacterTemperature");
                updateRecord({ temperature: v });
              }}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={0.01}
              min={0}
              max={1}
            />
          </div>
          <Button
            fullWidth
            style={makePackageButtonStyle}
            onClick={() => makePackage()}
          >
            Share This Character!
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" className="padded DeletionArea">
            <h3> Danger Zone</h3>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteRecord}
            >
              Delete {record.displayName}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
