import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Plan } from "../../models";
import "./style.css";

const COLLECTION = "plans";
const COLLECTION_DISPLAY_NAME = "Plans";

const reCountNodes = (node, count = 0) => {
  // given an object with a subtasks array property
  // recursively count the number of nodes in the tree
  // including the root node
  if (!node) return count;
  count++;
  if (!node.subtasks) return count;
  node.subtasks.forEach(subtask => {
    count = reCountNodes(subtask, count);
  });
  return count;
};

function Data() {
  useLogPageView("plans");
  //const [records, setRecords] = useState([]);
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const colPath = "projects/" + activeProjectId + "/" + COLLECTION;
  const [records = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );

  const createRecord = async evt => {
    if (!activeProjectId) return null;
    evt.preventDefault();
    const rec = Plan();
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    await setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + rec.id);
  };

  // display records in an mui table with a load more button at the bottom if
  // hasMore is true
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        onClick={createRecord}
        variant="contained"
        className="float-right"
      >
        New Plan
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <p>
        Plans are collections of tasks that can be assigned to a chat. Start by
        creating a single objective and then click the Plan button to create a
        plan. You may want to click the Plan button several times, each time the
        system will try to create new subtasks, like a nested TODO list.
      </p>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Number of Tasks</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const { id, displayName, created } = record;
              let description = record?.description || "";
              if (!description) {
                description = record?.tasks[0]?.description;
              }
              const count = reCountNodes(record?.tasks[0]);
              const path = "/" + COLLECTION + "/" + id;
              return (
                <TableRow
                  key={id}
                  className="animated-fade-in-from-left"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "--delay": `${index * 0.1}s`,
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}>{displayName}</Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {count}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {description?.slice(0, 50)}...
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(created.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && <Button onClick={loadMore}>Load More</Button>}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
