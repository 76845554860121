import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
// import the react markdown package
import "./style.css";

const COLLECTION = "example_datas";
const Spacer = () => <div style={{ height: 40 }} />;

// get the last item in an array
Handlebars.registerHelper("last", function (arr, key) {
  return arr[arr.length - 1][key] || "";
});

Handlebars.registerHelper("json", function (context) {
  return JSON.stringify(context, null, 2);
});

// human readable date and time handlebars helper
Handlebars.registerHelper("now", function () {
  return new Date().toString();
});

function Data() {
  useLogPageView("ExampleData");
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();
  const [record, setRecord] = useState(null);
  const [content, setContent] = useState({});
  const [stringifiedContent, setStringifiedContent] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setContent(rec.content || {});
      setStringifiedContent(JSON.stringify(rec.content || {}, null, 2));
      setRecord(rec);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  if (!record) return null;

  const updateRecord = update => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    update.lastUpdated = serverTimestamp();
    updateDoc(docRef, update, { merge: true });
  };

  const deleteRecord = async () => {
    if (!record) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await docRef.delete();
    history.push(`/admin/example-datas`);
  };

  const duplicateRecord = async () => {
    log("duplicateExampleData");
    const newRec = { ...record };
    const id = uuid();
    newRec.id = id;
    newRec.displayName = `${record.displayName} (copy)`;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await setDoc(docRef, newRec);
    history.push(`/${COLLECTION}/${id}`);
  };

  const saveContent = () => {
    updateRecord({ content });
  };

  const saveStringifiedContent = () => {
    let content = {};
    try {
      content = JSON.parse(stringifiedContent);
    } catch (e) {
      log("error parsing json", e);
      return null;
    }
    updateRecord({ content });
  };

  const onEditExampleData = data => {
    updateRecord({ content: data.updated_src });
  };

  const onDeleteExampleData = data => {
    updateRecord({ content: data.updated_src });
  };

  const onAddExampleData = data => {
    updateRecord({ content: data.updated_src });
  };

  if (!record) return <div>Loading...</div>;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <Tf
        label="Example Data Name"
        fullWidth
        value={record?.displayName}
        onChange={e => updateRecord({ displayName: e.target.value })}
        InputProps={{ style: { fontSize: 24 } }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <h3>Example Data</h3>
          <Button variant="contained" onClick={saveStringifiedContent}>
            Save
          </Button>
          <Spacer />
          <TextField
            value={stringifiedContent}
            onChange={e => setStringifiedContent(e.target.value)}
            multiline
            rows={20}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <h3>Example Data JSON</h3>
          <Button variant="contained" onClick={saveContent}>
            Save
          </Button>
          <Spacer />
          <ReactJson
            src={content}
            name={false}
            indentWidth={2}
            collapsed={2}
            collapseStringsAfterLength={12}
            displayDataTypes={true}
            onEdit={onEditExampleData}
            onDelete={onDeleteExampleData}
            onAdd={onAddExampleData}
          />
        </Grid>
      </Grid>
      <Spacer />
      <Button color="error" variant="contained" onClick={deleteRecord}>
        Delete {record?.displayName}
      </Button>
      <Button
        style={{ background: "#555", color: "#fff", float: "right" }}
        variant="contained"
        onClick={duplicateRecord}
      >
        Duplicate {record?.displayName}
      </Button>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
