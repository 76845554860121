import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Package } from "../../models";
import "./style.css";

const COLLECTION = "packages";
const COLLECTION_DISPLAY_NAME = "packages";

function Data() {
  useLogPageView("Packages");
  const [records, setRecords] = useState([]);
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();

  useEffect(() => {
    if (!activeProjectId) return;
    const q = query(collection(db, "projects", activeProjectId, COLLECTION));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [activeProjectId]);

  const createRecord = async evt => {
    if (!activeProjectId) return null;
    evt.preventDefault();
    const rec = Package();
    rec.sourceProjectId = activeProjectId;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    await setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + rec.id);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        sx={{ float: "right" }}
        variant="contained"
        onClick={createRecord}
      >
        New Package
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      {records.map((pkg, index) => {
        const { id, displayName } = pkg;
        const path = "/packages/" + id;
        return (
          <div
            key={id}
            className="character-card animated-fade-in-from-bottom"
            style={{ "--delay": index * 0.1 + "s" }}
          >
            <h3>
              <Link to={path}> {displayName || ""}</Link>
            </h3>
          </div>
        );
      })}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
