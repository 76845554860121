import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Link } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { useLogPageView } from "../../db";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import "./style.css";

const COLLECTION = "projects";
const COLLECTION_DISPLAY_NAME = "Projects";

function Data() {
  useLogPageView("Projects");
  const [records = [], loadMore, hasMore] = useFirestorePagination(COLLECTION);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(record => {
              const { id, displayName, creatorId, created } = record;
              const projectPath = "/admin/projects/" + id;
              const userPath = "/admin/users/" + creatorId;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={projectPath}>{displayName}</Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={userPath}>{record?.creatorDisplayName}</Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(created.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && (
        <Button className="load-more" onClick={loadMore}>
          Load More
        </Button>
      )}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
