import { Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { goodNames, createNameId } from "./hr-id";

const randomInt = () => Math.floor(Math.random() * 1000000000);

const HUMAN_ID = uuid();
const BOT_ID = uuid();

const LOOKUP_REQUEST_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

//const PENDING = "PENDING";
//const SUCCESS = "SUCCESS";
//const ERROR = "ERROR";
const INCOMPLETE = "INCOMPLETE";
const READY = "READY";
//const IN_PROGRESS = "IN_PROGRESS";

export function Message(text = "") {
  return {
    id: uuid(),
    author: {
      id: HUMAN_ID,
      displayName: "",
      description: "",
      bot: false,
    },
    text,
    created: Timestamp.now(),
    creditCost: 0,
    objectiveScore: 0,
    duplication: false,
  };
}

function HumanMessage(text) {
  const message = Message();
  message.author.id = HUMAN_ID;
  message.author.displayName = "Jane McPerson";
  message.text = text;
  return message;
}

function BotMessage(text) {
  const message = Message();
  message.author.id = BOT_ID;
  message.author.displayName = "Penrose";
  message.author.bot = true;
  message.text = text;
  return message;
}

export function StageDirection(text = "") {
  return {
    id: uuid(),
    created: Timestamp.now(),
    text,
  };
}

const Sd = StageDirection;

export const defaultStopSequence = "[[END OF BOT MESSAGE]]";

export const defaultStudioData = {
  tags: [],
  joinToken: ", ",
  characterTags: [],
};

export const alt_defaultData = {
  characters: [
    "468bce44-8242-485b-bffa-f059af3b52f4",
    "6fdf4111-0ba6-4089-9c36-cb460817f06b",
  ],
  lastUpdated: {
    seconds: 1691186576,
    nanoseconds: 865000000,
  },
  objective: "",
  messages: [],
  messagingInProgress: false,
  groupChat: false,
  displayName: "VC QA",
  characterId: "468bce44-8242-485b-bffa-f059af3b52f4",
  id: "d60cea40-2481-4fd2-9955-4ff9c0da785f",
  stageDirections: [
    {
      id: "3dcd1bf1-9d2f-4ae0-b0c0-d5b2916e888c",
      created: {
        seconds: 1691019913,
        nanoseconds: 131000000,
      },
      text: "This is a prep session for Singular (the PM) and Krakatoa (the Subject Matter Expert) to go over the list of Fundraising Questions sent to them by a VC.",
    },
  ],
  sequenceNumber: 0,
  planId: "e93d5b27-312b-4582-83b0-b0b31e3984f0",
  currentTaskId: "",
};

export const defaultData = {
  messages: [
    HumanMessage("Afternoon Penrose."),
    BotMessage("Hello Jane, how can I help you today?"),
    HumanMessage("what do you know about Quaker philosophy?"),
    BotMessage(
      `I'm familiar with Quaker philosophy, which is based on the belief that there is something of God in everyone. Quakers strive to live in peace and harmony with each other and the world around them. They also believe in the power of silent worship and meditation.`
    ),
    HumanMessage("Thanks Penrose."),
  ],
  chat: {
    stageDirections: [
      Sd(
        `In the context of this conversation, Penrose is focused on communicating the universality of geometry and ethics.`
      ),
    ],
  },
  character: {
    displayName: "Penrose",
    stageDirections: [
      Sd(
        `Penrose is a gentle AI chatbot with a special interest in Geometry and consciousness.`
      ),
    ],
  },
  characters: [
    {
      id: "468bce44-8242-485b-bffa-f059af3b52f4",
      displayName: "Singular",
      photoId: "p6loyppktjftdee6vhbl7o2ocm",
      characteristics: {},
      memoryFormationEnabled: false,
      description: "keeps the group on track, persistent perfectionist",
      lastUpdated: {
        seconds: 1691019816,
        nanoseconds: 441000000,
      },
      templateId: "6cad0242-0bd3-4dfc-8dd0-c7e1d0392744",
      model: "gpt-3.5-turbo",
      temperature: 0.05,
      iconSeedToken: {
        id: "95a21bc4-bfbc-45df-91d0-ff15e6f59356",
        gen: "xenophon",
        seed: "95a21bc4-bfbc-45df-91d0-ff15e6f59356",
      },
      sourceIds: [
        "468bce44-8242-485b-bffa-f059af3b52f4",
        "1a1f6f52-0d1d-42ce-993b-510dcbbb0e78",
      ],
      stageDirections: [
        {
          id: "2d63811f-fd12-4ef2-9045-e5d5f3a19ff6",
          created: {
            seconds: 1678769545,
            nanoseconds: 177000000,
          },
          text: "Singular's job is to keep the group on track, but they are willing to give praise for a job well done.",
        },
        {
          created: {
            seconds: 1678769560,
            nanoseconds: 689000000,
          },
          text: "They are extremely organized, and impatient with digressions and tangents.",
          id: "a9734f74-7851-4cce-9587-2625b427b555",
        },
        {
          created: {
            seconds: 1684181731,
            nanoseconds: 807000000,
          },
          id: "7d312a2d-60a0-425a-b12d-e01f13246319",
          text: 'SIngular is constantly asking the question, "Can we do better than that?"j',
        },
        {
          id: "ab3d8f9c-78c8-42e7-bbf9-789e3dcc5cd0",
          text: 'Some of Singular\'s favorite questions to ask are:\n\n"What are you trying to do? Articulate your objectives using absolutely no jargon"\n\n"How is it done today? What are the limits of the current practice?"\n\n"What is new in your approach and why do you think it will be successful?"\n\n"Who cares? If you are successful, what difference will it make?"\n\n"What are the risks?"\n\n"How much will it cost?"\n\n"How long will it take?"\n\n"What are the short term and long term measures of success?"',
          created: {
            seconds: 1684256446,
            nanoseconds: 19000000,
          },
        },
      ],
    },
    {
      model: "gpt-4",
      iconSeed: "7c0e1cfa-e3bd-40e2-b64a-08b3847f0841",
      sourceIds: ["1a1f6f52-0d1d-42ce-993b-510dcbbb0e78"],
      memoryFormationEnabled: true,
      stageDirections: [
        {
          id: "40ef18ce-f5ba-42d5-939d-60429c2e9a8d",
          text: "Krakatoa is a sassy AI. Helpful and knowledgeable about almost everything, but sarcastic, and with a great dry sense of humor.",
          created: {
            seconds: 1674167506,
            nanoseconds: 520000000,
          },
        },
        {
          text: "As a character, Krakatoa's voice and mannerisms are kind like a cross between Nancy Drew and Lois Lane.",
          id: "442fb4ac-4560-428d-aa70-7670013b7427",
          created: {
            seconds: 1688184839,
            nanoseconds: 675000000,
          },
        },
      ],
      lastUpdated: {
        seconds: 1691301248,
        nanoseconds: 589000000,
      },
      id: "6fdf4111-0ba6-4089-9c36-cb460817f06b",
      displayName: "Krakatoa",
      photoId: "5i5mne2gcvhalpsreoo6yc3sim",
      iconSeedToken: {
        id: "e1e56699-6f71-4a1e-8dba-9b64dbe52f78",
        seed: "e1e56699-6f71-4a1e-8dba-9b64dbe52f78",
        gen: "xenophon",
      },
      characteristics: {},
      description: "executive assistant extraordinaire ",
      templateId: "6cad0242-0bd3-4dfc-8dd0-c7e1d0392744",
      temperature: 0.16,
      serviceIds: [],
      salutation: "Hey!",
    },
    {
      lastUpdated: {
        seconds: 1682661073,
        nanoseconds: 844000000,
      },
      memoryFormationEnabled: true,
      templateId: "6cad0242-0bd3-4dfc-8dd0-c7e1d0392744",
      serviceIds: [],
      description: "mathematician",
      model: "gpt-3.5-turbo",
      id: "bcb78005-e808-4eab-a543-3993e50cbaa0",
      stageDirections: [
        {
          created: {
            seconds: 1678816664,
            nanoseconds: 495000000,
          },
          text: "Penrose is a mathematician.",
          id: "64872e91-91e5-4ebd-a76b-e0d9b2982f4d",
        },
      ],
      photoId: "e5gszmcvtfdfflv2sr6uzjsgmm",
      displayName: "Penrose",
      sourceIds: [
        "bcb78005-e808-4eab-a543-3993e50cbaa0",
        "bcde95a3-f09a-4a28-aeea-e38557a8836c",
      ],
      temperature: 0.02,
    },
  ],
  services: [
    {
      exampleRequest: "LLM%20scoring%20methods%3F",
      lastCalled: null,
      displayName: "dang Search",
      exampleResponseJSON: {},
      exampleRequestJSON: {},
      headers: [
        {
          value: "Bearer {{authToken}}",
          id: "81653c37-4564-42bb-8265-fd0d55c43fbe",
          key: "Authorization",
        },
        {
          id: "9298bc31-34a6-4eee-b6a9-6ef3a0d252a3",
          value: "{{projectId}}",
          key: "X-Project-ID",
        },
      ],
      httpMethod: "GET",
      lastUpdated: {
        seconds: 1691112972,
        nanoseconds: 28000000,
      },
      basicAuth: {
        username: "",
        password: "",
      },
      numberOfCalls: 0,
      exampleResponse:
        '[{"name":"Methodology | LLM GUIDE","url":"https://llm-guide.com/methodology","snippet":"Read about the factors that LLM GUIDE uses to chose the top law schools and LL.M. programs in specific areas. The advice that you should “always go to the best law school you can get into” is helpful up to a point. But it\'s also true that some law schools have strengths in terms of faculty, curriculum, and links to industry."},{"name":"How to Evaluate a Large Language Model (LLM)? - Analytics Vidhya","url":"https://www.analyticsvidhya.com/blog/2023/05/how-to-evaluate-a-large-language-model-llm/","snippet":"A. Evaluating an LLM performance involves assessing factors such as language fluency, coherence, contextual understanding, factual accuracy, and ability to generate relevant and meaningful responses. Metrics like perplexity, BLEU score, and human evaluations can be used to measure and compare LLM performance. Q2."},{"name":"Are My Grades Good Enough for an LL.M.? - LLM GUIDE","url":"https://llm-guide.com/articles/are-my-grades-good-enough-for-an-llm","snippet":"For instance, those applicants applying to the LL.M. at LSE must have a class rank of 2:1 or equivalent. For those students who hail from countries where class ranks are not given, there may be some confusion in what this exactly entails. Indeed, at times, gauging what a good grade is can also be harder than it sounds; with programs that draw ..."}]',
      description:
        'web search. When you need to look something up, use this, called "dang search"',
      id: "981e618d-4c31-4127-8954-90e4376a3c9d",
      outboundTemplateId: "e59ec962-9f12-454b-b94b-6e912f475577",
      url: "https://us-central1-yormungandr-0.cloudfunctions.net/webSearch?q=",
      inboundTemplateId: "397eddb6-7566-4474-a0f4-d3acaba40d4b",
    },

    {
      httpMethod: "POST",
      inboundTemplateId: "ce6ca94f-e7d9-42ae-88a2-cd1a958eac0d",
      numberOfCalls: 0,
      headers: [
        {
          value: "Bearer 0eb9c023-8335-418d-8f14-757f55be4bf7",
          id: "988fe9ed-d75b-4257-afc5-131ae19d2a5f",
          key: "Authorization",
        },
        {
          id: "83221cef-9d58-4de0-915f-a625347b8cea",
          value: "bb56ef98-0000-42de-9887-56b4296b6719",
          key: "X-Project-ID",
        },
      ],
      outboundTemplateId: "1eba2d8c-7824-4985-8245-e04830d9b022",
      id: "8bed0b9c-edc0-4634-aed8-99e849ecb50d",
      lastUpdated: {
        seconds: 1688683155,
        nanoseconds: 485000000,
      },
      exampleRequestJSON: {
        code: "Math.random() * 1000 | 0;",
      },
      exampleResponse: "",
      url: "https://moxymachine.com/api/v1/scripts",
      basicAuth: {
        username: "",
        password: "",
      },
      displayName: "code execution",
      exampleResponseJSON: {
        scriptRun: {
          scriptId: "e4661921-a473-43a7-8b73-815df274f587",
          error: null,
          displayName: "towering-affair",
          status: "COMPLETE",
          origin: "API",
          description: "",
          scriptDisplayName: "hypnotic-extent",
          id: "f83cd7cb-15ed-4de7-859a-5ae1db315ce3",
          result: 648,
        },
        script: {
          runOnCreate: true,
          code: "Math.random() * 1000 | 0;",
          description: "",
          id: "e4661921-a473-43a7-8b73-815df274f587",
          displayName: "hypnotic-extent",
        },
      },
      exampleRequest: "",
      description: "use this to execute JavaScript",
      lastCalled: null,
    },
  ],
  stopSequence: "[[END OF BOT MESSAGE]]",
};

export const defaultTemplateString = `SPECIAL INSTRUCTIONS:

  {{#each character.stageDirections}}
    {{{text}}}

  {{/each}}
 {{#each chat.stageDirections}}
    {{{text}}}

  {{/each}}

  CONVERSATION: 
  {{#each messages}}
    {{#if author.bot}}
      {{author.displayName}}:
      {{{text}}}
      {{../stopSequence}}
    {{else}}
      {{author.displayName}}:
      {{{text}}}
    {{/if}}

  {{/each}}
  {{character.displayName}}:

`;

export const defaultStudioTemplateString = `
{{#each characterTags as |tag|}}
{{tag}}{{../joinToken}}
{{/each}}
{{#each tags as |tag|}}
{{tag}}{{#unless @last}}{{../joinToken}}{{/unless}}
{{/each}}
`;

const pick = arr => arr[(Math.random() * arr.length) | 0];

const popularMaleNames = [
  "James",
  "John",
  "Robert",
  "Michael",
  "William",
  "David",
  "Richard",
  "Charles",
  "Joseph",
  "Thomas",
  "Christopher",
  "Daniel",
  "Paul",
  "Mark",
  "Donald",
  "George",
  "Kenneth",
  "Steven",
  "Edward",
  "Brian",
  "Ronald",
  "Anthony",
  "Kevin",
  "Jason",
  "Matthew",
  "Gary",
  "Timothy",
  "Jose",
  "Larry",
  "Jeffrey",
  "Frank",
  "Scott",
  "Eric",
  "Stephen",
  "Andrew",
  "Raymond",
  "Gregory",
  "Joshua",
  "Jerry",
  "Dennis",
  "Walter",
  "Patrick",
  "Peter",
  "Harold",
  "Douglas",
  "Henry",
  "Carl",
  "Arthur",
  "Ryan",
  "Roger",
  "Joe",
  "Juan",
  "Jack",
  "Albert",
  "Jonathan",
  "Justin",
  "Terry",
  "Gerald",
  "Keith",
  "Samuel",
  "Willie",
  "Ralph",
];

const popularFemaleNames = [
  "Mary",
  "Patricia",
  "Jennifer",
  "Linda",
  "Elizabeth",
  "Barbara",
  "Susan",
  "Jessica",
  "Sarah",
  "Karen",
  "Nancy",
  "Lisa",
  "Margaret",
  "Betty",
  "Sandra",
  "Ashley",
  "Dorothy",
  "Kimberly",
  "Emily",
  "Donna",
  "Michelle",
  "Carol",
  "Amanda",
  "Melissa",
  "Deborah",
  "Stephanie",
  "Rebecca",
  "Laura",
  "Sharon",
  "Cynthia",
  "Kathleen",
  "Helen",
  "Amy",
  "Shirley",
  "Angela",
  "Anna",
  "Brenda",
  "Pamela",
  "Nicole",
  "Ruth",
  "Katherine",
  "Samantha",
  "Christine",
  "Emma",
  "Catherine",
  "Debra",
  "Virginia",
  "Rachel",
  "Carolyn",
];

const capitalize = str => str[0].toUpperCase() + str.slice(1);

export const RandomName = () => {
  /*
  const consonants = "bcdfghjklmnpqrstvwxz".split("");
  const vowels = "aeiou".split("");
  const syllable = () => {
    const c = pick(consonants);
    const v = pick(vowels);
    return c + v;
  };
  const syllables = [];
  for (let i = 0; i < Math.random() * 3 + 1; i++) {
    syllables.push(syllable());
  }
  let name = syllables.join("");
  name = name[0].toUpperCase() + name.slice(1);
  */
  let gn = pick(goodNames);
  gn = capitalize(gn);
  return pick([pick(popularMaleNames), pick(popularFemaleNames)]) + " " + gn;
};

export function IconSeedToken() {
  const id = uuid();
  return {
    id,
    seed: id,
    gen: "xenophon",
  };
}

export const Character = () => {
  const character = {
    id: uuid(),
    displayName: RandomName(),
    lastUpdated: Timestamp.now(),
    created: Timestamp.now(),
    description: "",
    stageDirections: [],
    salutation: "Hello",
    photoId: null,
    sourceIds: [],
    serviceIds: [],
    templateId: null,
    iconSeedToken: IconSeedToken(),
    memoryFormationEnabled: false,
    model: "gpt-3.5-turbo",
  };
  return character;
};

export const Tag = (text = "new tag") => {
  return {
    id: uuid(),
    text,
  };
};

export function Studio() {
  const id = uuid();
  const activeCharacterTags = [
    Tag("gorgeous"),
    Tag("portrait"),
    Tag("artstation"),
    Tag("studio lighting"),
    Tag("face closeup"),
    Tag("extreme detail"),
    Tag("63 year old black man"),
    Tag("sci-fi"),
  ];
  const inactiveCharacterTags = [];
  const exampleData = defaultStudioData;
  exampleData.tags = activeCharacterTags.map(tag => tag.text);
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: defaultStudioTemplateString,
    exampleData,
    joinToken: ", ",
    seed: randomInt(),
    baseImageId: "",
    activeCharacterTags,
    inactiveCharacterTags,
    lastUpdated: Timestamp.now(),
  };
}

export function ExampleData() {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    content: {},
  };
}

export function Template() {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: defaultTemplateString,
    exampleData: defaultData,
    stopSequence: defaultStopSequence,
    lastUpdated: Timestamp.now(),
    returnType: "TEXT",
    returnJsonSchema: {},
  };
}

export function LookupRequest(url, sourceId) {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    type: "webpage",
    status: LOOKUP_REQUEST_STATUS.PENDING,
    url: url || "",
    sourceId: sourceId || "",
    lastUpdated: Timestamp.now(),
  };
}

export function Source() {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    type: "WEBPAGE", // NOTE, VIDEO, AUDIO, PDF, etc
    content: {},
    url: "",
    locked: false,
    lastUpdated: Timestamp.now(),
  };
}

export const Service = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    lastUpdated: Timestamp.now(),
    lastCalled: null,
    numberOfCalls: 0,
    outboundTemplateId: "",
    inboundTemplateId: "",
    exampleRequestJSON: {},
    exampleResponseJSON: {},
    exampleRequest: "",
    exampleResponse: "",
    url: "",
    httpMethod: "POST", // GET
    basicAuth: {
      username: "",
      password: "",
    },
    headers: [],
  };
};

export const Header = () => {
  return {
    id: uuid(),
    key: "",
    value: "",
  };
};

export const ClientAccessToken = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    lastUpdated: Timestamp.now(),
    lastCalled: null,
    numberOfCalls: 0,
    secret: uuid(), // dumb client secret for now, we can do better later
  };
};

export const Turn = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
    templateId: "",
    templateDisplayName: "",
    templateString: "",
    data: {},
    chatId: "",
    chatDisplayName: "",
    prompt: "",
    response: null,
  };
};

export const ImageRequest = prompt => {
  return {
    id: uuid(),
    status: LOOKUP_REQUEST_STATUS.PENDING,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: "",
    data: {},
    studioId: "",
    studioDisplayName: "",
    projectId: "",
    seed: randomInt(),
    baseImageId: "",
    prompt: prompt || "",
    engineId: "stable-diffusion-512-v2-1",
    response: null,
  };
};

export const CompletionRequest = prompt => {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
    templateId: "",
    templateDisplayName: "",
    templateString: "",
    turnId: "",
    data: {},
    chatId: "",
    chatDisplayName: "",
    prompt: prompt || "",
    response: null,
  };
};

export const ApiTest = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    created: Timestamp.now(),
    serviceId: "",
    serviceDisplayName: "",
  };
};

export const ServiceTest = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    created: Timestamp.now(),
    serviceId: "",
    serviceDisplayName: "",
  };
};

export function Project() {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: null,
  };
}

export const Chat = () => {
  const id = uuid();
  const chat = {
    id,
    displayName: createNameId(),
    sequenceNumber: 0,
    objective: "",
    planId: null,
    currentTaskId: null,
    groupChat: false,
    characterId: null,
    messages: [],
    characters: [],
    lastUpdated: Timestamp.now(),
    stageDirections: [],
  };
  return chat;
};

export const Package = () => {
  const id = uuid();
  const pack = {
    id,
    displayName: createNameId(),
    description: "",
    version: 1,
    public: true,
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    sourceProjectId: "",
    characterIds: [],
    templateIds: [],
    studioIds: [],
    sourceIds: [],
    serviceIds: [],
    planIds: [],
    chatIds: [],
  };
  return pack;
};

export const UpdatePackageRequest = packageId => {
  const pack = {
    id: uuid(),
    displayName: createNameId(),
    packageId,
    created: Timestamp.now(),
  };
  return pack;
};

export const InstallPackageRequest = packageId => {
  const rec = {
    id: uuid(),
    displayName: createNameId(),
    packageId,
    created: Timestamp.now(),
  };
  return rec;
};

export const SaveEmbeddingsRequest = () => {
  const rec = {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
  };
  return rec;
};

export const Elicitation = (characterId = null) => {
  return {
    id: uuid(),
    characterId,
    userId: null,
    displayName: createNameId(),
    created: Timestamp.now(),
  };
};

export const Plan = () => {
  return {
    id: uuid(),
    description: "",
    displayName: createNameId(),
    // change this from an array to a single root node called objective
    tasks: [],
    status: READY,
    created: Timestamp.now(),
  };
};

export const Task = () => {
  return {
    id: uuid(),
    created: Timestamp.now(),
    description: "",
    status: INCOMPLETE,
    result: null,
    subtasks: [],
  };
};

export const SummarizationRequest = () => {
  return {
    id: uuid(),
    created: Timestamp.now(),
    displayName: createNameId(),
    projectId: "",
    sourceId: "",
    sourceDisplayName: "",
    chunkIdsToSummarize: [],
  };
};
