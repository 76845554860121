import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import the icon button
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
//import Ajv from "ajv";
import { v4 as uuid } from "uuid";

//const ajv = new Ajv();
const clone = json => JSON.parse(JSON.stringify(json));

const STRING = "string";
const NUMBER = "number";
const BOOLEAN = "boolean";
const ARRAY = "array";
const OBJECT = "object";
const NULL = "null";
const INTEGER = "integer";

const typeMap = {
  string: STRING,
  number: NUMBER,
  boolean: BOOLEAN,
  array: ARRAY,
  object: OBJECT,
  null: NULL,
  integer: INTEGER,
};

const schemaInterfaceStyle = {
  border: "1px solid black",
  padding: "12px",
  paddingRight: 0,
  margin: 100,
};

const subunitStyle = {
  border: "1px solid black",
  padding: "8px",
  paddingRight: 0,
  paddingLeft: 24,
  margin: 0,
  marginBottom: "12px",
  borderRight: 0,
};

function AddProperty({ onChange, hideKey = false }) {
  const [key, setKey] = useState("");
  const [type, setType] = useState(OBJECT);
  // this is the default value for the field
  const [value, setValue] = useState("");
  const [isNullable, setIsNullable] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isEnum, setIsEnum] = useState(false);

  const renderStringInput = () => {
    return (
      <>
        <input
          type="text"
          placeholder="default value"
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        {/*
        <span>ENUM</span>
        <input type="checkbox" onChange={e => setIsEnum(val => !val)} />
        */}
        <span>Nullable</span>
        <input type="checkbox" onChange={e => setIsNullable(val => !val)} />
        <span>Required</span>
        <input type="checkbox" onChange={e => setIsRequired(val => !val)} />
      </>
    );
  };

  const renderNumberInput = () => {
    return (
      <>
        <input
          type="text"
          placeholder="default value"
          value={parseFloat(value) || 0}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <span>Nullable</span>
        <input type="checkbox" onChange={e => setIsNullable(val => !val)} />
        <span>Required</span>
        <input type="checkbox" onChange={e => setIsRequired(val => !val)} />
      </>
    );
  };

  const renderArrayInput = () => {
    // this is tricky,
    return (
      <>
        <span>Nullable</span>
        <input type="checkbox" onChange={e => setIsNullable(val => !val)} />
        <span>Required</span>
        <input type="checkbox" onChange={e => setIsRequired(val => !val)} />
      </>
    );
  };

  const renderBooleanInput = () => {
    // this one will be a pair of radio buttons
    return (
      <>
        <span>true</span>
        <input
          type="radio"
          name="boolean"
          checked={value === true}
          onChange={e => {
            setValue(true);
          }}
        />
        <span>false</span>
        <input
          type="radio"
          name="boolean"
          checked={!value}
          onChange={e => {
            setValue(false);
          }}
        />
        <span>Nullable</span>
        <input type="checkbox" onChange={e => setIsNullable(val => !val)} />
        <span>Required</span>
        <input type="checkbox" onChange={e => setIsRequired(val => !val)} />
      </>
    );
  };

  const renderProperInputField = () => {
    if (type === STRING) return renderStringInput();
    if (type === NUMBER) return renderNumberInput();
    if (type === BOOLEAN) return renderBooleanInput();
    if (type === ARRAY) return renderArrayInput();
    return null;
  };

  return (
    <div>
      <div>
        {!hideKey && (
          <input
            type="text"
            placeholder="key"
            value={key}
            onChange={e => {
              setKey(e.target.value);
            }}
          />
        )}
        <select
          value={type}
          onChange={e => {
            setType(e.target.value);
          }}
        >
          <option value={OBJECT}>Object</option>
          <option value={STRING}>String</option>
          <option value={NUMBER}>Number</option>
          <option value={BOOLEAN}>Boolean</option>
          <option value={ARRAY}>Array</option>
        </select>
      </div>
      {renderProperInputField()}
      <div>
        <button
          onClick={() => {
            const updatedState = {
              key,
              type,
              value,
              isNullable,
              isEnum,
              isRequired,
              $id: uuid(),
            };
            onChange(updatedState);
            // then clear the state
            setKey("");
            setType(OBJECT);
            setValue("");
            setIsNullable(false);
            setIsEnum(false);
            setIsRequired(false);
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
}

function AddField({ onChange }) {
  const [key, setKey] = useState("");
  const [type, setType] = useState(STRING);
  // this is the default value for the field
  const [value, setValue] = useState("");
  const [isNullable, setIsNullable] = useState(false);
  const [isEnum, setIsEnum] = useState(false);

  return (
    <div>
      <h4>Add Field</h4>
      <div>
        <input
          type="text"
          placeholder="key"
          onChange={e => {
            setKey(e.target.value);
          }}
        />
        <select
          onChange={e => {
            setType(e.target.value);
          }}
        >
          <option value={STRING}>String</option>
          <option value={NUMBER}>Number</option>
          <option value={BOOLEAN}>Boolean</option>
          <option value={ARRAY}>Array</option>
          <option value={OBJECT}>Object</option>
        </select>
      </div>
      <div>
        <input
          type="text"
          placeholder="default value"
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <span>ENUM</span>
        <input type="checkbox" onChange={e => setIsEnum(val => !val)} />
        <span>Nullable</span>
        <input type="checkbox" onChange={e => setIsNullable(val => !val)} />
      </div>
      <div>
        <button
          onClick={() => {
            const updatedState = {
              key,
              type,
              value,
              isNullable,
              isEnum,
              $id: uuid(),
            };
            onChange(updatedState);
            // then clear the state
            setKey("");
            setType(STRING);
            setValue("");
            setIsNullable(false);
            setIsEnum(false);
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
}

// a GUI for building and editing JSON schemas
// we're going to use fluent-json-schema for this
export function SchemaInterface({ schema = {}, onChange }) {
  const [innerSchema, setInnerSchema] = useState(schema);
  const [showAddingField, setShowAddingField] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  //const stringifiedSchema = JSON.stringify(innerSchema, null, 2);

  const renderArraySchema = (obj, key) => {
    // and make it possible to add a property
    const items = obj.items || [];
    return (
      <div key={obj["$id"]}>
        <p>{key + ": "}ARRAY </p>
        {items.map(renderSchema)}
        <h4>Add Item</h4>
        <AddProperty
          hideKey
          onChange={val => {
            console.log(val);
            if (!val) return;
            // add this to the inner schema
            // and then update the state
            const updateObj = {
              type: val.type,
              nullable: val.isNullable,
              $id: val.$id,
            };
            if (val?.type === STRING) updateObj.default = val.value;
            if (val?.isEnum) updateObj.enum = [val.value];
            if (val?.type === OBJECT) {
              updateObj.properties = {};
              updateObj.required = [];
              updateObj.additionalProperties = false;
            }
            if (val?.type === ARRAY) {
            }
            if (val?.type === BOOLEAN) {
              // parse the value as a boolean
              console.log(val.value);
              updateObj.default = !!val.value;
            }
            if (val?.type === NUMBER) {
              // parse the value as a float
              updateObj.default = parseFloat(val.value);
            }
            // here instead of adding a property to an object, we've got to add an item to an array
            // so we need to find the array that we're adding to
            console.log(val);
            //obj.properties[val.key] = updateObj;
            if (!obj.items) obj.items = [];
            obj.items.push(updateObj);
            setInnerSchema(innerSchema => clone(innerSchema));
          }}
        />
      </div>
    );
  };

  const getIsRequired = (id, key, obj) => {
    if (!obj) obj = innerSchema;
    // we're going to look through the schema
    // each object potentially has a required array, which has keys for
    // required properties of that object (but not necessarily nested objects)
    if (
      obj.required &&
      obj.properties[key] &&
      obj.required.includes(key) &&
      obj.properties[key]["$id"] === id
    ) {
      return true;
    }
    // otherwise we're going to look through the properties
    for (let subKey in obj.properties) {
      const subObj = obj.properties[subKey];
      const res = getIsRequired(id, key, subObj);
      if (res) return true;
    }
    return false;
  };

  const renderObjectSchema = (obj, key) => {
    const propertyKeys = Object.keys(obj.properties);
    const isRequired = getIsRequired(obj["$id"], key);
    console.log("isRequired: ", isRequired);
    // and make it possible to add a property
    return (
      <div key={obj["$id"]}>
        <p>
          {key !== undefined && key + ": "}OBJECT {isRequired && "[required]"}
        </p>
        {propertyKeys.map(key => renderSchema(obj.properties[key], key))}
        <h4>Add Property</h4>
        <AddProperty
          onChange={val => {
            if (!val) return;
            if (!val.key) return;
            // add this to the inner schema
            // and then update the state
            const updateObj = {
              type: val.type,
              nullable: val.isNullable,
              $id: val.$id,
            };
            if (val?.type === STRING) updateObj.default = val.value;
            if (val?.isEnum) updateObj.enum = [val.value];
            if (val?.type === OBJECT) {
              updateObj.properties = {};
              updateObj.required = [];
              updateObj.additionalProperties = false;
            }
            if (val?.type === ARRAY) {
            }
            if (val?.type === BOOLEAN) {
              // parse the value as a boolean
              console.log(val.value);
              updateObj.default = !!val.value;
            }
            if (val?.type === NUMBER) {
              // parse the value as a float
              updateObj.default = parseFloat(val.value);
            }
            obj.properties[val.key] = updateObj;
            if (val.isRequired) {
              // check to make sure that the key is in the required array
              if (!obj.required) obj.required = [];
              if (!obj.required.includes(val.key)) obj.required.push(val.key);
            }
            console.log(obj, val);
            setInnerSchema(innerSchema => clone(innerSchema));
          }}
        />
      </div>
    );
  };

  const renderBooleanSchema = (obj, key) => {
    let isRequired = getIsRequired(obj["$id"], key);
    return (
      <span key={obj["$id"]}>
        {key}: <span>BOOLEAN</span>
        <span> {obj?.nullable && "NULLABLE"}</span>
        <span> {obj?.default && `DEFAULT: ${obj.default}`}</span>
        <span>REQUIRED: {"" + isRequired}</span>
      </span>
    );
  };

  const renderNumberSchema = (obj, key) => {
    return (
      <span key={obj["$id"]}>
        {key}: <span>NUMBER</span>
        <span> {obj?.nullable && "NULLABLE"}</span>
        <span> {obj?.default && `DEFAULT: ${obj.default}`}</span>
      </span>
    );
  };

  const renderStringSchema = (obj, key) => {
    return (
      <span key={obj["$id"]}>
        {key}: <span>STRING</span>
        <span> {obj?.nullable && "NULLABLE"}</span>
        <span> {obj?.default && `DEFAULT: ${obj.default}`}</span>
      </span>
    );
  };

  const renderSchema = (obj, key) => {
    if (!obj) return null;
    if (Object.keys(obj).length === 0) return null;
    if (!typeMap[obj.type]) return null;
    // and now we take them one by one, with objects last
    let res = null;
    if (obj.type === OBJECT) res = renderObjectSchema(obj, key);
    if (obj.type === STRING) res = renderStringSchema(obj, key);
    if (obj.type === NUMBER) res = renderNumberSchema(obj, key);
    if (obj.type === BOOLEAN) res = renderBooleanSchema(obj, key);
    if (obj.type === ARRAY) res = renderArraySchema(obj, key);
    return (
      <div key={obj["$id"]} style={subunitStyle}>
        {res}
      </div>
    );
  };

  const renderCopyToClipboardButton = () => {
    if (!Object.keys(innerSchema).length) return null;
    return (
      <>
        <Tooltip title="Copy to Clipboard">
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(innerSchema, null, 2)
              );
              // show a snackbar
              setShowSnackbar(true);
              setTimeout(() => {
                setShowSnackbar(false);
              }, 2000);
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          message="JSON Schema copied to Clipboard"
        />
      </>
    );
  };

  return (
    <div style={schemaInterfaceStyle}>
      <h1>JSON Schema Playground</h1>
      <button
        onClick={() => {
          // instead of showing the add field button
          // we're just going to add an object at the top level
          const obj = {
            type: OBJECT,
            properties: {},
            required: [],
            additionalProperties: false,
            $id: uuid(),
          };
          setInnerSchema(obj);
        }}
      >
        {Object.keys(innerSchema).length ? "Reset Schema" : "Add Object"}
      </button>
      {showAddingField && (
        <AddField
          onChange={val => {
            // add this to the inner schema
            // and then update the state
            console.log(val);
            const updateObj = {
              type: val.type,
              nullable: val.isNullable,
              $id: val.$id,
            };
            if (val?.type === STRING) updateObj.default = val.value;
            if (val?.isEnum) updateObj.enum = [val.value];
            if (val?.type === OBJECT) {
              updateObj.properties = {};
              updateObj.required = [];
              updateObj.additionalProperties = false;
            }
            setInnerSchema(updateObj);
            setShowAddingField(false);
          }}
        />
      )}
      {renderSchema(innerSchema)}
      {renderCopyToClipboardButton()}
      <pre>{JSON.stringify(innerSchema, null, 2)}</pre>
      {/*
      <button
        onClick={() => {
          // validate the schema
          try {
            const valid = ajv.validateSchema(innerSchema);
            console.log(valid);
          } catch (e) {
            console.log(e);
          }
        }}
      >
        Validate
      </button>
      */}
    </div>
  );
}
