import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getCountFromServer } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";

import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import "./style.css";

const COLLECTION = "users";
const COLLECTION_DISPLAY_NAME = "Users";

function Data() {
  useLogPageView("Users");
  const [records = [], loadMore, hasMore] = useFirestorePagination(COLLECTION);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const colRef = collection(db, COLLECTION);
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setUserCount(count);
    };
    fetchData();
  }, []);

  console.log("records", records);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>
        {COLLECTION_DISPLAY_NAME}: {userCount}
      </h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(record => {
              const { uid, displayName } = record;
              const id = uid;
              if (!id) return null;
              const path = "/admin/" + COLLECTION + "/" + id;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(record.created.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && (
        <Button className="load-more" onClick={loadMore}>
          Load More
        </Button>
      )}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
