import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Canvas from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import { db } from "../../db";
import "./style.css";

const COLLECTION = "icons";
const COLLECTION_DISPLAY_NAME = "Icons";

const pick = arr => arr[(Math.random() * arr.length) | 0];

const Icon = () => {
  const id = uuid();
  const displayName = id;
  //const gen = pick(["xenophon", "archimedes"]);
  const gen = pick(["thales"]);
  return { id, displayName, gen };
};

const WrappedCanvas = React.forwardRef(function WC(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <span {...props} ref={ref}>
      {props.children}
    </span>
  );
});

function Data() {
  const [proposedIcons, setProposedIcons] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    for (let i = 0; i < 33; i++) {
      proposedIcons.push(Icon());
    }
    setProposedIcons(proposedIcons);
  }, [proposedIcons]);

  const saveIcon = async (id, displayName, gen = "archimedes") => {
    const record = { id, displayName, gen };
    setProposedIcons(prev => {
      const newProposedIcons = prev.filter(icon => icon.id !== id);
      newProposedIcons.push(Icon());
      return newProposedIcons;
    });
    const docRef = doc(db, COLLECTION, id);
    return await setDoc(docRef, record);
  };

  useEffect(() => {
    const q = query(collection(db, COLLECTION));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        records.push(doc.data());
      });
      setRecords(records);
    });
    return () => unsub();
  }, []);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <div>
        <h3>Proposed Icons</h3>
        {proposedIcons.map(({ id, displayName, gen = "thales" }) => {
          return (
            <div className="icon-card" key={id} elevation={1}>
              <Button onClick={() => saveIcon(id, displayName, gen)}>
                <Tooltip
                  title={<Canvas seed={id} style={{ width: 256 }} gen={gen} />}
                >
                  <WrappedCanvas>
                    <Canvas seed={id} style={{ width: 256 }} gen={gen} />
                  </WrappedCanvas>
                </Tooltip>
              </Button>
            </div>
          );
        })}
      </div>
      <div>
        <h3>Saved Icons</h3>
        {records.map(({ id, displayName, gen = "archimedes" }) => {
          const path = "/" + COLLECTION + "/" + id;
          return (
            <div className="icon-card" key={id} elevation={1}>
              <Link to={path}>
                <Tooltip
                  title={<Canvas seed={id} style={{ width: 256 }} gen={gen} />}
                >
                  <WrappedCanvas>
                    <Canvas seed={id} style={{ width: 64 }} gen={gen} />
                  </WrappedCanvas>
                </Tooltip>
              </Link>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
