import Button from "@mui/material/Button";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import { auth, db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import "./style.css";

function ProductCard({ product, createPayment, waiting = false, price = {} }) {
  const { name, description, images } = product;
  const { currency = "usd", unit_amount = 0 } = price;
  const priceString = (unit_amount / 100).toFixed(2);
  let image = null;
  if (images && images.length) {
    image = (
      <img
        src={images[0]}
        alt={name}
        width="256px"
        height="256px"
        style={{ objectFit: "contain" }}
      />
    );
  }

  const buttonOrSpinner = waiting ? (
    <div className="spinner"></div>
  ) : (
    <Button variant="contained" onClick={() => createPayment(price)}>
      buy
    </Button>
  );

  return (
    <div className="product-card">
      <div className="product-card-image"></div>
      <div className="product-card-content">
        <h2>{name}</h2>
        {image}
        <p>{description}</p>
        <p>
          <strong>{priceString}</strong> {currency}
        </p>
        <div>{buttonOrSpinner}</div>
      </div>
    </div>
  );
}

function Data() {
  useLogPageView("Payments");
  const [user] = useAuthState(auth);
  const { activeProjectId = "" } = useEphemera();
  const [products, setProducts] = useState([]);
  const [productPrices, setProductPrices] = useState({});
  const [credits, setCredits] = useState(0);
  const [waiting, setWaiting] = useState(false);

  // load the products
  useEffect(() => {
    const colRef = collection(db, "products");
    const unsub = onSnapshot(colRef, snapshot => {
      const docs = snapshot.docs.map(doc => {
        const rec = doc.data();
        rec.id = doc.id;
        return rec;
      });
      setProducts(docs);
    });
    return unsub;
  }, []);

  // once we have all the products, we can subscribe to the prices
  useEffect(() => {
    if (!products.length) return;
    const unsubbables = [];
    products.forEach(product => {
      const pricesColRef = collection(db, "products", product.id, "prices");
      const unsubby = onSnapshot(pricesColRef, pricesCollectionSnap => {
        const priceDocs = pricesCollectionSnap.docs.map(doc => {
          const rec = doc.data();
          rec.id = doc.id;
          return rec;
        });
        setProductPrices(prices => ({
          ...prices,
          [product.id]: priceDocs[0],
        }));
      });
      unsubbables.push(unsubby);
    });
    // now make a function to unsubscribe from all of them
    const unsub = () => unsubbables.forEach(unsubby => unsubby());
    return unsub;
  }, [products]);

  useEffect(() => {
    if (!activeProjectId) return;
    // get the credits doc
    const docRef = doc(db, `projects/${activeProjectId}/meta/credits`);
    const unsub = onSnapshot(docRef, doc => {
      if (!doc.exists()) return;
      const data = doc.data();
      setCredits(data.amount);
    });
    return unsub;
  }, [activeProjectId]);

  const createPayment = async priceObj => {
    if (!user) return;
    if (!priceObj) return;
    log("createPayment");
    setWaiting(true);
    const baseUrl = "https://dangbot.com";
    const success_url = baseUrl + "/payments";
    const cancel_url = baseUrl + "/payments";
    const rec = {
      id: uuid(),
      mode: "payment",
      price: priceObj.id,
      success_url,
      cancel_url,
    };
    const docRef = doc(db, `users/${user.uid}/checkout_sessions/${rec.id}`);
    await setDoc(docRef, rec);
    // then I need to set up a subscription to the docRef
    // and when it changes, I need to redirect to the url property of the doc
    const unsub = onSnapshot(docRef, doc => {
      if (!doc.exists()) return null;
      const data = doc.data();
      // this is the redirect url
      if (data.url) {
        unsub();
        return (window.location.href = data.url);
      }
    });
  };

  if (!user) return null;

  const renderNotWaiting = () => {
    return (
      <>
        <div>
          <h3>Credits: {credits}</h3>
        </div>
        <div>
          <h2>Buy More Credits</h2>
          {products.map(product => {
            if (!productPrices[product.id]) return null;
            return (
              <ProductCard
                key={product.id}
                product={product}
                createPayment={createPayment}
                waiting={waiting}
                price={productPrices[product.id]}
              />
            );
          })}
        </div>
      </>
    );
  };

  const renderWaiting = () => {
    // scale the spinner div up by 2x
    return (
      <>
        <div>
          <h3>Credits: {credits}</h3>
        </div>
        <div>
          <h2>Thanks!</h2>
          <div style={{ height: 120 }} />
          <div className="spinner" style={{ scale: "10" }} />
        </div>
      </>
    );
  };

  return waiting ? renderWaiting() : renderNotWaiting();
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
