import React from "react";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Sigil from "../../components/canvas/";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithGooglePopup, useLogPageView } from "../../db";
import "./style.css";

function Space({ height = 64 }) {
  return <div style={{ height }} />;
}

function Page() {
  useLogPageView("Login");
  const [user] = useAuthState(auth);
  const history = useHistory();
  async function logOut() {
    console.log("Logging out...");
    await signOut(auth);
    history.push("/");
  }
  async function signIn() {
    console.log("Signing in...");
    try {
      const creds = await signInWithGooglePopup();
      console.log("signed in creds", creds);
      history.push("/chats");
      return creds;
    } catch (e) {
      console.error("failed to sign in");
      return null;
    }
  }
  return (
    <div className="login-page-container">
      <Sigil seed={uuid()} gen={"xenophon"} style={{ width: 128 }} />
      <Space />
      <Sigil seed={user?.uid} gen={"xenophon"} style={{ width: 256 }} />
      <Space />
      <Sigil seed={uuid()} gen={"xenophon"} style={{ width: 128 }} />
      <div className="absolute-center">
        {user ? null : (
          <Button color="inherit" onClick={() => signIn()}>
            Sign In
          </Button>
        )}
        {user ? (
          <Button color="inherit" onClick={() => logOut()}>
            Sign Out
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default Page;
