import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DataObjectIcon from "@mui/icons-material/DataObject";
import MessageIcon from "@mui/icons-material/Message";
import PersonIcon from "@mui/icons-material/Person";
import WebhookIcon from "@mui/icons-material/Webhook";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  collection,
  doc,
  getCountFromServer,
  query,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { auth, db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import "./style.css";

const COLLECTION = "projects";
const COLLECTION_DISPLAY_NAME = "Project";

function Data() {
  useLogPageView("User");
  const [user] = useAuthState(auth);
  const { activeProjectId = "" } = useEphemera();
  const [record, setRecord] = useState(null);
  const [recentChats, setRecentChats] = useState([]);
  const [chatCount, setChatCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [sourceCount, setSourceCount] = useState(0);
  const [studioCount, setStudioCount] = useState(0);
  const [credits, setCredits] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    const docRef = doc(db, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (snap.exists) setRecord(snap.data());
    });
    return unsubscribe;
  }, [id]);

  // subscribe to the current user's credits
  useEffect(() => {
    if (!id) return;
    const docRef = doc(db, "projects", id, "meta", "credits");
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists()) setCredits(0);
      const { amount = 0 } = snap.data();
      setCredits(amount);
    });
    return unsubscribe;
  }, [id]);

  // get the count for several collections

  // chats
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "chats");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setChatCount(count);
    };
    // and also get the most recently updated 3 chats (just get it once)
    const fetchData2 = async () => {
      const q = query(colRef, orderBy("lastUpdated", "desc"), limit(3));
      const snap = await getDocs(q);
      const chats = snap.docs.map(doc => doc.data());
      setRecentChats(chats);
    };
    fetchData();
    fetchData2();
  }, [id]);

  // characters
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "characters");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setCharacterCount(count);
    };
    fetchData();
  }, [id]);

  // templates
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "templates");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setTemplateCount(count);
    };
    fetchData();
  }, [id]);

  // services
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "services");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setServiceCount(count);
    };
    fetchData();
  }, [id]);

  // sources
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "sources");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setSourceCount(count);
    };
    fetchData();
  }, [id]);

  // studios
  useEffect(() => {
    if (!id) return;
    const colRef = collection(db, COLLECTION, id, "studios");
    const fetchData = async () => {
      const snap = await getCountFromServer(colRef);
      const count = snap.data().count;
      setStudioCount(count);
    };
    fetchData();
  }, [id]);

  const addCredits = async amount => {
    if (!id) return;
    const docRef = doc(db, "projects", id, "meta", "credits");
    // increment the credits by the amount
    const updateData = { amount: credits + amount };
    await updateDoc(docRef, updateData, { merge: true });
  };

  const renderChats = () => {
    if (!id) return null;
    if (!recentChats?.length) return null;

    return (
      <div>
        <h3>Chats</h3>
        {recentChats.map(chat => {
          return (
            <div key={"chat-" + chat.id}>
              {activeProjectId === record.id ? (
                <Link to={`/chats/${chat.id}`}>{chat.displayName}</Link>
              ) : (
                <span>{chat.displayName}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (!record) return null;
  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <h2>
        {COLLECTION_DISPLAY_NAME}: {record.displayName}: {credits} Credits{" "}
      </h2>
      <Button onClick={() => addCredits(5000)}>Add 5000 Credits</Button>
      <Button onClick={() => addCredits(25000)}>Add 25000 Credits</Button>
      <pre>{JSON.stringify(record, null, 2)}</pre>
      {renderChats()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="package-icon-chip">
          {chatCount || 0}
          <MessageIcon />
        </div>
        <div className="package-icon-chip">
          {characterCount || 0}
          <PersonIcon />
        </div>
        <div className="package-icon-chip">
          {templateCount || 0}
          <DataObjectIcon />
        </div>
        <div className="package-icon-chip">
          {serviceCount || 0}
          <WebhookIcon />
        </div>
        <div className="package-icon-chip">
          {sourceCount || 0}
          <CollectionsBookmarkIcon />
        </div>
        <div className="package-icon-chip">
          {studioCount || 0}
          <ColorLensIcon />
        </div>
      </div>
      <Button
        onClick={() => {
          if (!user || !user.uid) return;
          const docRef = doc(db, "ephemera", user.uid);
          const updateData = { activeProjectId: id };
          updateDoc(docRef, updateData, { merge: true });
        }}
      >
        Set as Active Project
      </Button>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
