import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import TextField from "@mui/material/TextField";

export const SyncTf = props => <TextField {...props} />;

export const SequenceNameInput = props => {
  const [val, setVal] = useState(props.value || "");
  // this debounced onChange is usually a call to save in Firestore
  const debouncedChange = debounce(e => props.onChange(e), 500);

  return (
    <input
      type="text"
      {...props}
      value={val}
      onChange={e => {
        setVal(e.target.value);
        debouncedChange(e);
      }}
    />
  );
};

const TF = ({ value, ...props }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  // this debounced onChange is usually a call to save in Firestore
  const debouncedChange = debounce(e => props.onChange(e), 500);

  return (
    <TextField
      {...props}
      value={val}
      onChange={e => {
        setVal(e.target.value);
        debouncedChange(e);
      }}
    />
  );
};

export default TF;
