import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Source } from "../../models";
import "./style.css";

const COLLECTION = "sources";
const COLLECTION_DISPLAY_NAME = "Sources";

function Data() {
  useLogPageView("Sources");
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const [anchorEl, setAnchorEl] = useState(null);
  const colPath = `projects/${activeProjectId}/${COLLECTION}`;
  const [records = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createNewWebSource = async character => {
    if (!activeProjectId) return;
    const rec = Source();
    await setDoc(doc(db, "projects", activeProjectId, COLLECTION, rec.id), rec);
    history.push(`/${COLLECTION}/${rec.id}`);
  };

  const createNewNoteSource = async character => {
    if (!activeProjectId) return;
    const rec = Source();
    rec.type = "NOTE";
    await setDoc(doc(db, "projects", activeProjectId, COLLECTION, rec.id), rec);
    history.push(`/${COLLECTION}/${rec.id}`);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ float: "right" }}
        variant="contained"
      >
        New Source
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            log("createNoteSource");
            createNewNoteSource();
            handleClose();
          }}
          variant="contained"
        >
          New Note Source
        </MenuItem>
        <MenuItem
          onClick={() => {
            log("createWebSource");
            createNewWebSource();
            handleClose();
          }}
          variant="contained"
        >
          New Web Source
        </MenuItem>
      </Menu>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <p>
        Sources are the long-term memory for your characters. Give us a URL and
        we'll try to pull in the information for you. If it all looks right, you
        can give the signal and we'll calculate the embeddings for that new
        source information, after that, assign it to your characters and enjoy
        the newly-expanded understanding they have of the universe.
      </p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const { id, displayName, lastUpdated } = record;
              const path = `/${COLLECTION}/${id}`;
              return (
                <TableRow
                  key={id}
                  className="animated-fade-in-from-left"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "--delay": `${index * 0.1}s`,
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(lastUpdated.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore ? (
        <Button onClick={loadMore} disabled={!hasMore}>
          Load more
        </Button>
      ) : (
        <p>All loaded</p>
      )}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
