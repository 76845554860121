import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { auth, db, useLogPageView } from "../../db";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import "./style.css";

function timeAgo(date) {
  const now = new Date();
  const secondsAgo = Math.round((now - date) / 1000);
  const minutesAgo = Math.round(secondsAgo / 60);
  const hoursAgo = Math.round(minutesAgo / 60);
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  if (secondsAgo < 60) {
    return rtf.format(-secondsAgo, "second");
  } else if (minutesAgo < 60) {
    return rtf.format(-minutesAgo, "minute");
  } else if (hoursAgo < 24) {
    return rtf.format(-hoursAgo, "hour");
  } else {
    return rtf.format(-Math.round(hoursAgo / 24), "day");
  }
}

function Data() {
  useLogPageView("Users");
  const [user] = useAuthState(auth);
  const [tic, setTic] = React.useState(0);
  const colPath = `analytics/status/users`;
  const [records = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );

  useEffect(() => {
    // necessary for the lastUpdated field to update
    const interval = setInterval(() => {
      setTic(tic => tic + 1);
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  console.log("tic", tic);

  return (
    <div>
      <h2>Analytics: Status - Users</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Most Recent Page</TableCell>
              <TableCell>Time Ago</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(record => {
              const { userId, userDisplayName } = record;
              const id = userId;
              if (!id) return null;
              const path = "/admin/users/" + id;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {userDisplayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Button
                      onClick={() => {
                        if (!user || !user.uid) return;
                        if (!record?.activeProjectId) return;
                        const docRef = doc(db, "ephemera", user.uid);
                        const updateData = {
                          activeProjectId: record.activeProjectId,
                        };
                        updateDoc(docRef, updateData, { merge: true });
                      }}
                    >
                      Set as Active Project
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <a href={record?.mostRecentURL}>{record?.mostRecentURL}</a>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {timeAgo(
                      new Date(record?.lastUpdated?.seconds * 1000 || 0)
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(
                      record?.lastUpdated?.seconds * 1000
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && (
        <Button className="load-more" onClick={loadMore}>
          Load More
        </Button>
      )}
    </div>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
