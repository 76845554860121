import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { auth, db } from "../../db";
import { COLLECTION } from "./constants";
import "./style.css";

function Data() {
  const [record, setRecord] = useState(null);
  const [credits, setCredits] = useState(0);
  const { id } = useParams();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!id) return;
    if (!user) return;
    const docRef = doc(db, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
      setCredits(data.credits || 0);
    });
    return unsubscribe;
  }, [id, user]);

  const changeCredits = async () => {
    if (!user) return;
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, { credits });
  };

  if (!record) return null;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <span style={{ float: "left", marginRight: 40, marginTop: 40 }}>
            <img src={record.images[0]} alt="product" width={160} />
          </span>
          <div style={{ marginLeft: 200 }}>
            <h1>{record.name}</h1>
            <h3>{record.credits || 0} credits</h3>
            <p>description:</p>
            <p>{record.description}</p>
            <p>Active: {record.active ? "True" : "False"}</p>
            <Accordion>
              <AccordionSummary>edit</AccordionSummary>
              <AccordionDetails>
                <h3>Edit Credit Amount</h3>
                <Input
                  type="number"
                  fullWidth
                  value={credits || 0}
                  onChange={e => setCredits(parseFloat(e.target.value))}
                />
                <Button fullWidth variant="contained" onClick={changeCredits}>
                  Change Amount of Credits
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
