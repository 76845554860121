import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { collection, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../db";
import MinorFrame from "../../components/minor-frame/";
import "./style.css";

const COLLECTION = "products";
const COLLECTION_DISPLAY_NAME = "Products";

function Data() {
  const [records, setRecords] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;
    const collectionRef = collection(db, COLLECTION);
    const unsub = onSnapshot(collectionRef, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [user]);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(record => {
              const { id, name } = record;
              const path = "/" + COLLECTION + "/" + id;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {name} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row"></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
