import React, { useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import generators from "./generators";

/*
const randomColor = () => {
  //const random = () => Math.floor(Math.random() * 255);
  //return `rgb(${random()}, ${random()}, ${random()})`;
  const hue = Math.floor(Math.random() * 360);
  console.log(hue);
  return `hsla(${hue}, 100%, 30%, 0.8)`;
};
*/

/*
Different Icon Generators
Hexagons
 - xenophon
 - archimedes
Circles
 - thales
*/

function Canvas({ style = {}, seed = uuid(), gen = "xenophon", ...props }) {
  const canvasRef = useRef(null);
  useEffect(() => {
    let ctx = canvasRef.current.getContext("2d");
    const opts = {
      seed,
      x: canvasRef.current.width / 2,
      y: canvasRef.current.height / 2,
      radius: Math.min(canvasRef.current.width, canvasRef.current.height) / 2,
      sides: 6,
      fillColor: props.fillColor || "#fff",
      //fillColor: props.fillColor || randomColor(),
      //strokeColor: props.strokeColor || "#fff",
      strokeColor: props.strokeColor || "rgba(0, 0, 0, 1)",
      //strokeColor: props.strokeColor || randomColor(),
    };
    if (!generators[gen]) {
      generators["xenophon"](ctx, opts);
    } else {
      generators[gen](ctx, opts);
    }
  }, [seed, gen, props.fillColor, props.strokeColor]);
  return (
    <canvas
      style={style}
      width={style.width}
      height={style.width}
      ref={canvasRef}
    />
  );
}

export default Canvas;
