import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Template } from "../../models";
import "./style.css";

const COLLECTION = "templates";
const COLLECTION_DISPLAY_NAME = "Templates";

function Data() {
  useLogPageView("Templates");
  const [records, setRecords] = useState([]);
  const { activeProjectId = "" } = useEphemera();

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION
    );
    const q = query(collectionRef, orderBy("lastUpdated", "desc"));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [activeProjectId]);

  const createTemplate = character => {
    if (!activeProjectId) return;
    log("createTemplate");
    const rec = Template();
    setDoc(doc(db, "projects", activeProjectId, COLLECTION, rec.id), rec);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        onClick={createTemplate}
        variant="contained"
        sx={{ float: "right" }}
      >
        New Template
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <p>
        Behind the scenes, we use{" "}
        <a href="https://handlebarsjs.com">Handlebars JS</a> for building
        Templates in Yormungandr. These Templates are hydrated with data about
        the characters and the chat messages, which then produces the prompts
        sent to the LLM. You can take full control of those templates and assign
        different Templates to different Characters, giving you more power,
        flexibility, and modularity.
      </p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const { id, displayName, lastUpdated } = record;
              const path = `/${COLLECTION}/${id}`;
              return (
                <TableRow
                  key={id}
                  className="animated-fade-in-from-left"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "--delay": `${index * 0.1}s`,
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(lastUpdated.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
