// this is the hook that will be used to fetch the ephemera data
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../db";

export default function useEphemera() {
  const [user] = useAuthState(auth);
  const [ephemera, setEphemera] = useState({});

  useEffect(() => {
    if (!user) return;
    const docRef = doc(db, "ephemera", user.uid);
    const unsub = onSnapshot(docRef, snapshot => {
      if (!snapshot.exists()) return;
      const data = snapshot.data();
      setEphemera(data);
    });
    return unsub;
  }, [user]);

  return ephemera;
}
