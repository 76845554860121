import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { db } from "../../db";
import { ClientAccessToken } from "../../models";
import useEphemera from "../../hooks/useEphemera";

export function ClientAccessTokenDashboard() {
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [queryLimit, setQueryLimit] = useState(10);
  const COLLECTION = "client_access_tokens";

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION
    );
    const q = query(
      collectionRef,
      orderBy("lastUpdated", "desc"),
      limit(queryLimit)
    );
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        records.push(data);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [queryLimit, activeProjectId]);

  const loadMore = () => setQueryLimit(queryLimit + 10);

  const createClientAccessToken = () => {
    if (!activeProjectId) return;
    const rec = ClientAccessToken();
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + rec.id);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }} className="padded">
      <h2>Client Access Tokens</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Number of Calls</TableCell>
              <TableCell>Last Called</TableCell>
              <TableCell>Last Changed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const {
                id,
                displayName = "",
                numberOfCalls = 0,
                lastUpdated,
                lastCalled,
              } = record;
              const path = "/" + COLLECTION + "/" + id;
              return (
                <tr
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {numberOfCalls}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lastCalled
                      ? new Date(lastCalled.seconds * 1000).toLocaleString()
                      : null}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lastUpdated
                      ? new Date(lastUpdated.seconds * 1000).toLocaleString()
                      : null}
                  </TableCell>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={loadMore} style={{ float: "right" }}>
        Load More
      </Button>
      <Button onClick={createClientAccessToken} variant="contained" fullWidth>
        Add New Client Access Token
      </Button>
    </Box>
  );
}
