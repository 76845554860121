import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { CharacterImage } from "../../components/character-image/";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Character, Studio, Tag } from "../../models";

const SDXL = "8beff3369e81422112d93b89ca01426147de542cd4684c244b673b105188fe5f";

const randomInt = () => Math.floor(Math.random() * 1000000000);

const Spacer = () => <div style={{ height: 40 }} />;

const style = {
  border: "1px solid #ccc",
  padding: "1rem",
  marginTop: "1rem",
};

const COLLECTION = "image_requests";

function Data() {
  useLogPageView("Image");
  const { activeProjectId = "" } = useEphemera();
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    // query the collection by created date descending
    const unsubscribe = onSnapshot(docRef, snapshot => {
      if (!snapshot.exists()) return console.log("No such document!");
      const data = snapshot.data();
      console.log("data: ", data);
      setRecord(data);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  const updateRecord = async update => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    return await updateDoc(docRef, update);
  };

  const createCharacter = async () => {
    const character = Character();
    character.photoId = record.response.id;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "characters",
      character.id
    );
    await setDoc(docRef, character, { merge: true });
    history.push("/characters/" + character.id);
  };

  const createStudioFromImage = async () => {
    if (!activeProjectId) return;
    const newRec = Studio();
    const tags = record?.data?.tags?.map(t => Tag(t));
    newRec.activeCharacterTags = tags;
    newRec.seed = record?.seed || randomInt();
    newRec.engineId = record?.engineId || SDXL;
    newRec.exampleData = {
      joinToken: ", ",
      tags: record?.data?.tags || [],
    };
    newRec.displayName = record.displayName;
    const docRef = doc(db, "projects", activeProjectId, "studios", newRec.id);
    await setDoc(docRef, newRec);
    history.push("/studios/" + newRec.id);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <div
            style={{
              maxWidth: "100%",
              textAlign: "right",
            }}
          >
            <CharacterImage imageId={record?.response?.id} dimensions={512} />;
          </div>
        </Grid>
        <Grid item xs={12} lg={6} style={style}>
          <p>
            from studio:{" "}
            <Link to={"/studios/" + record?.studioId}>
              {record?.studioDisplayName}
            </Link>
          </p>
          <p className="faint">{record?.prompt}</p>
          <p>seed: {record?.options?.seed}</p>
          <p>character photo id: {record?.responseImageId}</p>
          {record?.displayName && (
            <Tf
              fullWidth
              value={record?.displayName}
              onChange={e => updateRecord({ displayName: e.target.value })}
              InputProps={{ style: { fontSize: 32 } }}
            />
          )}
          <Button onClick={createCharacter}>Create Character</Button>
          <Spacer />
          <Button onClick={createStudioFromImage}>
            Create Studio From Image
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
