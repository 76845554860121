import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sigil from "../../components/canvas";
import MinorFrame from "../../components/minor-frame/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Character, Chat } from "../../models";
import "./style.css";

const COLLECTION = "characters";
const COLLECTION_DISPLAY_NAME = "Characters";

export function CharacterImage({ imageId }) {
  const [record, setRecord] = useState(null);
  const dimensions = 256;

  useEffect(() => {
    if (!imageId) return;
    const docRef = doc(db, "character_images_256x256", imageId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId]);

  if (!record || !record.imageData) {
    const nullStyle = {
      height: dimensions,
      width: dimensions,
      background: "#333",
      margin: "-21px -21px 0 -21px",
    };
    return (
      <span>
        <div style={nullStyle} />
      </span>
    );
  }

  return (
    <div style={{ margin: "-21px -21px 0 -21px" }}>
      <img
        width={256}
        height={256}
        src={"data:image/png;base64, " + record.imageData}
        alt=""
      />
    </div>
  );
}

function Data() {
  const { activeProjectId = "" } = useEphemera();
  const colPath = "projects/" + activeProjectId + "/characters";
  const [records = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );
  const history = useHistory();
  useLogPageView("Characters");

  const createChat = async character => {
    if (!activeProjectId) return;
    log("createChat");
    const chat = Chat();
    chat.characterId = character.id;
    chat.characters = [character.id];
    await setDoc(doc(db, "projects", activeProjectId, "chats", chat.id), chat);
    history.push("/chats/" + chat.id);
  };

  const createCharacter = async evt => {
    if (!activeProjectId) return null;
    log("createCharacter");
    evt.preventDefault();
    const character = Character();
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      character.id
    );
    await setDoc(docRef, character);
    history.push("/characters/" + character.id);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        sx={{ float: "right" }}
        variant="contained"
        onClick={createCharacter}
      >
        New Character
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <div>
        {records.map((character, index) => {
          const { id, displayName, iconSeed, iconSeedToken = {} } = character;
          let truncatedDescription = character.description.substring(0, 30);
          if (character.description.length > 30) truncatedDescription += "...";
          const path = "/characters/" + id;
          return (
            <div
              className="character-card animated-fade-in-from-bottom"
              style={{ "--delay": index * 0.1 + "s" }}
              key={id}
            >
              <Link to={path}>
                {character.photoId ? (
                  <CharacterImage imageId={character.photoId} />
                ) : (
                  <div style={{ marginLeft: -20, marginTop: -20 }}>
                    <Sigil
                      seed={iconSeedToken?.seed || iconSeed || id}
                      gen={iconSeedToken?.gen || "xenophon"}
                      style={{ width: 256 }}
                    />
                  </div>
                )}
              </Link>
              <Link to={path}> {displayName || ""}</Link>
              <p className="faint" style={{ height: 20, marginTop: 0 }}>
                {truncatedDescription}
              </p>
              <Button
                fullWidth
                variant="contained"
                onClick={() => createChat(character)}
                sx={{ background: "#a99" }}
              >
                Start a new Chat
              </Button>
            </div>
          );
        })}
      </div>
      {hasMore && <Button onClick={loadMore}>Load More</Button>}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
