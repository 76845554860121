import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Sigil from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { InstallPackageRequest } from "../../models";
import { CharacterImage } from "../../components/character-image";
import { COLLECTION } from "./constants";
import "./style.css";

function Data() {
  useLogPageView("PublicPackage");
  const [record, setRecord] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [services, setServices] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [chats, setChats] = useState([]);
  const { activeProjectId = "" } = useEphemera();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // when we have the record, we'll get the characters
  // and then we'll get the images for each character
  useEffect(() => {
    if (!record) return;
    if (!record.characterIds) return;
    if (!record.characterIds.length) return;
    const { characterIds = [] } = record;
    const docRefs = characterIds.map(characterId =>
      doc(
        db,
        "packages",
        id,
        "archive",
        id + "-" + record.version,
        "characters",
        characterId
      )
    );
    const unsubbies = docRefs.map(docRef => {
      return onSnapshot(docRef, snap => {
        if (!snap.exists) return;
        const rec = snap.data();
        console.log("rec", rec);
        if (!rec) return;
        const alreadyPresent = characters.find(c => c.id === rec.id);
        if (alreadyPresent) return;
        setCharacters([...characters, rec]);
      });
    });
    return () => {
      unsubbies.forEach(unsubscribe => unsubscribe());
    };
  }, [record, characters, id]);

  // and get the services
  useEffect(() => {
    if (!record) return;
    if (!record.serviceIds) return;
    if (!record.serviceIds.length) return;
    const { serviceIds = [] } = record;
    const docRefs = serviceIds.map(serviceId =>
      doc(
        db,
        "packages",
        id,
        "archive",
        id + "-" + record.version,
        "services",
        serviceId
      )
    );
    const unsubbies = docRefs.map(docRef => {
      return onSnapshot(docRef, snap => {
        if (!snap.exists) return;
        const rec = snap.data();
        if (!rec) return;
        const alreadyPresent = services.find(c => c.id === rec.id);
        if (alreadyPresent) return;
        setServices([...services, rec]);
      });
    });
    return () => {
      unsubbies.forEach(unsubscribe => unsubscribe());
    };
  }, [record, services, id]);

  // and get the chats
  useEffect(() => {
    if (!record) return;
    if (!record.chatIds) return;
    if (!record.chatIds.length) return;
    const { chatIds = [] } = record;
    const docRefs = chatIds.map(chatId =>
      doc(
        db,
        "packages",
        id,
        "archive",
        id + "-" + record.version,
        "chats",
        chatId
      )
    );
    const unsubbies = docRefs.map(docRef => {
      return onSnapshot(docRef, snap => {
        if (!snap.exists) return;
        const rec = snap.data();
        const alreadyPresent = chats.find(c => c.id === rec.id);
        if (alreadyPresent) return;
        setChats([...chats, rec]);
      });
    });
    return () => {
      unsubbies.forEach(unsubscribe => unsubscribe());
    };
  }, [record, chats, id]);

  // and get the templates
  useEffect(() => {
    if (!record) return;
    if (!record.templateIds) return;
    if (!record.templateIds.length) return;
    const { templateIds = [] } = record;
    const docRefs = [];
    templateIds?.forEach(templateId => {
      if (!templateId) return;
      const docRef = doc(
        db,
        "packages",
        id,
        "archive",
        id + "-" + record.version,
        "templates",
        templateId
      );
      docRefs.push(docRef);
    });
    const unsubbies = docRefs.map(docRef => {
      return onSnapshot(docRef, snap => {
        if (!snap.exists) return;
        const rec = snap.data();
        const alreadyPresent = templates.find(c => c.id === rec.id);
        if (alreadyPresent) return;
        setTemplates([...templates, rec]);
      });
    });
    return () => {
      unsubbies.forEach(unsubscribe => unsubscribe());
    };
  }, [record, templates, id]);

  const createInstallPackageRequest = async () => {
    if (!activeProjectId || !id) return;
    const rec = InstallPackageRequest(id);
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "install_package_requests",
      rec.id
    );
    await setDoc(docRef, rec);
  };

  const renderCharacters = () => {
    if (!characters.length) return null;
    return (
      <div>
        {characters.map(character => {
          return (
            <div key={character?.id} className="character-row">
              <CharacterImage imageId={character?.photoId} />
              <h3>{character?.displayName}</h3>
              <p>{character?.description}</p>
              <div style={{ clear: "both", height: 12 }} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderServices = () => {
    if (!services.length) return null;
    return (
      <div>
        {services.map(service => {
          if (!service) return null;
          let seed = service?.iconSeed || service?.id;
          if (service.iconSeedToken) seed = service.iconSeedToken.seed;
          let gen = "xenophon";
          if (service.iconSeedToken) gen = service.iconSeedToken.gen;
          return (
            <div key={service.id} className="character-row">
              <Sigil
                seed={seed}
                style={{ width: 128, float: "left", marginRight: 40 }}
                gen={gen}
              />
              <h3>{service.displayName}</h3>
              <p>{service.description}</p>
              <div style={{ clear: "both", height: 12 }} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderTemplates = () => {
    if (!templates.length) return null;
    return (
      <div>
        {templates.map(template => {
          let seed = template.iconSeed || template.id;
          if (template.iconSeedToken) seed = template.iconSeedToken.seed;
          let gen = "xenophon";
          if (template.iconSeedToken) gen = template.iconSeedToken.gen;
          return (
            <div key={template.id} className="character-row">
              <Sigil
                seed={seed}
                style={{ width: 128, float: "left", marginRight: 40 }}
                gen={gen}
              />
              <h3>{template.displayName}</h3>
              <p>{template.description}</p>
              <div style={{ clear: "both", height: 12 }} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderChats = () => {
    if (!chats.length) return null;
    return (
      <div>
        <h2>Chats</h2>
        {chats.map(chat => {
          let seed = chat.iconSeed || chat.id;
          if (chat.iconSeedToken) seed = chat.iconSeedToken.seed;
          let gen = "xenophon";
          if (chat.iconSeedToken) gen = chat.iconSeedToken.gen;
          return (
            <div key={chat.id} className="character-row">
              <Sigil
                seed={seed}
                style={{ width: 128, float: "left", marginRight: 40 }}
                gen={gen}
              />
              <h3>{chat.displayName}</h3>
              <p>{chat?.stageDirections[0]?.text}</p>
              <div style={{ clear: "both", height: 12 }} />
              <Link
                to={`/public-packages/${id}/archive/${record.version}/chat/${chat.id}`}
              >
                link to shareable public copy
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  console.log("record", record);
  if (!record) return null;

  let seed = record.iconSeed || record.id;
  if (record.iconSeedToken) seed = record.iconSeedToken.seed;
  let gen = "xenophon";
  if (record.iconSeedToken) gen = record.iconSeedToken.gen;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <div style={{ textAlign: "center" }}>
            <Sigil seed={seed} style={{ width: 256 }} gen={gen} />
          </div>
          <h2>{record?.displayName}</h2>
          <div style={{ height: 24 }} />
          <h3>{record?.description}</h3>
          <Button onClick={createInstallPackageRequest}>Install Package</Button>
        </Grid>
        <Grid item lg={9} xs={12}>
          {renderCharacters()}
          {renderServices()}
          {renderTemplates()}
          {renderChats()}
        </Grid>
      </Grid>
    </>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
