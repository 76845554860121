import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  query,
  onSnapshot,
} from "firebase/firestore";
import { db, useLogPageView } from "../../db";
import MinorFrame from "../../components/minor-frame/";
import "./style.css";

const COLLECTION = "feature-flags";
const COLLECTION_DISPLAY_NAME = "Feature Flag";

function Data() {
  useLogPageView("FeatureFlags");
  const [record, setRecord] = useState(null);
  const [users, setUsers] = useState([]);
  const [includedUsers, setIncludedUsers] = useState([]);
  const { id } = useParams();
  const recordId = id;

  useEffect(() => {
    const COLLECTION = "users";
    const q = query(collection(db, COLLECTION));
    const unsub = onSnapshot(q, querySnapshot => {
      const users = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        users.push(record);
      });
      setUsers(users);
    });
    return () => unsub();
  }, [recordId]);

  useEffect(() => {
    const q = query(collection(db, COLLECTION, recordId, "users"));
    const unsub = onSnapshot(q, querySnapshot => {
      const users = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        users.push(record);
      });
      setIncludedUsers(users);
    });
    return () => unsub();
  }, [recordId]);

  useEffect(() => {
    if (!recordId) return;
    const docRef = doc(db, COLLECTION, recordId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (snap.exists) setRecord(snap.data());
    });
    return unsubscribe;
  }, [recordId]);

  const handleChange = event => {
    let {
      target: { value },
    } = event;
    const values = typeof value === "string" ? value.split(",") : value;
    const uid = values[0];
    const docRef = doc(db, COLLECTION, recordId, "users", uid);
    const update = { uid };
    setDoc(docRef, update, { merge: true });
  };

  const exclude = async uid => {
    const docRef = doc(db, COLLECTION, recordId, "users", uid);
    await deleteDoc(docRef);
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <h2>
        {COLLECTION_DISPLAY_NAME}: {record?.displayName}
      </h2>
      <b>key: {record?.id}</b>
      <h3>Included Users</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell className="centered">Exclude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {includedUsers.map(({ uid }) => {
              const user = users.find(u => u.uid === uid);
              if (!user) return null;
              return (
                <TableRow
                  key={user.uid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.displayName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.email}
                  </TableCell>
                  <TableCell component="th" scope="row" className="centered">
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={() => exclude(uid)}
                    >
                      Exlcude From Feature
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Add Users</h3>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Users</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={""}
          label="Users"
          onChange={handleChange}
        >
          {users.map(user => {
            const { displayName, uid } = user;
            return (
              <MenuItem key={uid} value={uid}>
                {displayName} ({user.email})
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
