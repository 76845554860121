import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { doc, onSnapshot } from "firebase/firestore";
import { db, useLogPageView } from "../../db";
import MinorFrame from "../../components/minor-frame/";
import "./style.css";

const COLLECTION = "users";
const COLLECTION_DISPLAY_NAME = "User";

function Data() {
  useLogPageView("User");
  const [record, setRecord] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    const docRef = doc(db, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (snap.exists) setRecord(snap.data());
    });
    return unsubscribe;
  }, [id]);

  if (!record) return null;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <h2>
        {COLLECTION_DISPLAY_NAME}: {record.displayName}
      </h2>
      <pre>{JSON.stringify(record, null, 2)}</pre>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
