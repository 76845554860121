import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Ajv from "ajv";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Link, useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import {
  CompletionRequest,
  Turn,
  defaultData,
  defaultStopSequence,
  defaultTemplateString,
} from "../../models";
// import the react markdown package
import ReactMarkdown from "react-markdown";
import "./style.css";

const COLLECTION = "templates";
const ajv = new Ajv();

const Spacer = () => <div style={{ height: 40 }} />;

// get the last item in an array
Handlebars.registerHelper("last", function (arr, key) {
  return arr[arr.length - 1][key] || "";
});

Handlebars.registerHelper("json", function (context) {
  return JSON.stringify(context, null, 2);
});

// human readable date and time handlebars helper
Handlebars.registerHelper("now", function () {
  return new Date().toString();
});

function SelectExampleData({ onChange, activeProjectId }) {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      "example_datas"
    );
    const q = query(collectionRef, orderBy("lastUpdated", "desc"));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [activeProjectId]);

  return (
    <FormControl fullWidth>
      <InputLabel>Reset Example Data</InputLabel>
      <Select
        label="Example Data"
        value={""}
        onChange={event => {
          const record = records.find(rec => rec.id === event.target.value);
          if (!record) return;
          onChange(record?.content);
        }}
        fullWidth
      >
        {records?.map(rec => {
          return (
            <MenuItem key={rec?.id} value={rec?.id}>
              {rec?.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function Data() {
  useLogPageView("Template");
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();
  const [record, setRecord] = useState(null);
  const [templateString, setTemplateString] = useState(defaultTemplateString);
  const [jsonSchemaString, setJsonSchemaString] = useState("");
  const [data, setData] = useState(defaultData);
  const [stopSequence, setStopSequence] = useState(defaultStopSequence);
  const [completion, setCompletion] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
      setTemplateString(rec.templateString);
      setJsonSchemaString(rec.returnJsonSchema);
      setStopSequence(rec.stopSequence || defaultStopSequence);
      if (rec.exampleData) setData(rec.exampleData);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the most recent completion request
  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const COLLECTION = "completion_requests";
    const colRef = collection(db, "projects", activeProjectId, COLLECTION);
    // query the collection by created date descending
    const q = query(
      colRef,
      where("templateId", "==", id),
      orderBy("created", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, snap => {
      if (snap.empty) return;
      const rec = snap.docs[0].data();
      setCompletion(rec);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  if (!record) return null;

  const updateRecord = update => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    update.lastUpdated = serverTimestamp();
    updateDoc(docRef, update, { merge: true });
  };

  const duplicateTemplate = async () => {
    log("duplicateTemplate");
    const newTemplate = { ...record };
    const id = uuid();
    newTemplate.id = id;
    newTemplate.displayName = `${record.displayName} (copy)`;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await setDoc(docRef, newTemplate);
    history.push(`/${COLLECTION}/${id}`);
  };

  const saveTemplate = () => {
    updateRecord({ templateString, stopSequence });
  };

  const testPrompt = async () => {
    log("testTemplatePrompt");
    const tplt = Handlebars.compile(templateString);
    const prompt = tplt(data);
    const turn = Turn();
    turn.templateId = record.id;
    turn.templateDisplayName = record.displayName;
    turn.templateString = templateString;
    turn.data = data;
    turn.prompt = prompt;
    const docRef = doc(db, "projects", activeProjectId, "turns", turn.id);
    await setDoc(docRef, turn);
    // and trigger the test
    const request = CompletionRequest(prompt);
    request.templateId = record.id;
    request.templateDisplayName = record.displayName;
    request.templateString = templateString;
    request.data = data;
    request.turnId = turn.id;
    const testDocRef = doc(
      db,
      "projects",
      activeProjectId,
      "completion_requests",
      request.id
    );
    await setDoc(testDocRef, request);
  };

  const resetTemplate = () => {
    updateRecord({
      templateString: defaultTemplateString,
      exampleData: defaultData,
      stopSequence: "[[END OF BOT MESSAGE]]",
    });
  };

  const renderPrompt = prompt => {
    return <div className="template-preview">{prompt}</div>;
  };

  const renderInterpolatedTemplate = (templateString, data) => {
    data.stopSequence = stopSequence;
    try {
      const tplt = Handlebars.compile(templateString);
      const prompt = tplt(data);
      return renderPrompt(prompt);
    } catch (e) {
      return e.message;
    }
  };

  const updateExampleData = data => updateRecord({ exampleData: data });

  const onEditExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const onDeleteExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const onAddExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const renderCompletionJSON = () => {
    if (!completion) return null;
    return (
      <div className="completion-area">
        <ReactJson
          src={completion}
          name={false}
          indentWidth={2}
          collapsed={2}
          collapseStringsAfterLength={12}
        />
      </div>
    );
  };

  const renderCompletionChoices = choices => {
    if (!choices) return null;
    return (
      <div className="completion-choices-area">
        <h3>Completion Choices</h3>
        {choices.map((choice, i) => (
          <ReactMarkdown
            key={"choice" + i}
            className="react-markdown-code-block"
          >
            {choice?.text}
          </ReactMarkdown>
        ))}
      </div>
    );
  };

  const renderJsonSchemaEditor = () => {
    if (record?.returnType !== "JSON") return null;
    return (
      <div className="json-schema-editor">
        <h3>JSON Schema</h3>
        <TextField
          value={jsonSchemaString || "{\n\n}"}
          onChange={e => setJsonSchemaString(e.target.value)}
          multiline
          rows={20}
          fullWidth
        />
        <Button
          onClick={() => {
            try {
              const jsonSchema = JSON.stringify(
                JSON.parse(jsonSchemaString.trim()),
                null,
                2
              );
              // now use ajv to validate that the schema (not the data) is valid
              const validate = ajv.compile(JSON.parse(jsonSchema));
              if (!validate) throw new Error("Invalid JSON Schema");
              updateRecord({ returnJsonSchema: jsonSchema });
              setJsonSchemaString(jsonSchema);
            } catch (e) {
              alert(e.message);
            }
          }}
        >
          Save JSON Schema
        </Button>
      </div>
    );
  };

  if (!record) return <div>Loading...</div>;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <Tf
        label="Template Name"
        fullWidth
        value={record?.displayName}
        onChange={e => updateRecord({ displayName: e.target.value })}
        InputProps={{ style: { fontSize: 24 } }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <h3>Example Data</h3>
          <ReactJson
            src={data}
            name={false}
            indentWidth={2}
            collapsed={2}
            collapseStringsAfterLength={12}
            displayDataTypes={true}
            onEdit={onEditExampleData}
            onDelete={onDeleteExampleData}
            onAdd={onAddExampleData}
          />
          <Spacer />
          <SelectExampleData
            onChange={updateExampleData}
            activeProjectId={activeProjectId}
          />
          <Spacer />
          <Link to={`/example-datas`}>Manage Example Data</Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Template</h3>
          <TextField
            value={templateString}
            onChange={e => setTemplateString(e.target.value)}
            multiline
            rows={20}
            fullWidth
          />
          <div style={{ height: 40 }} />
          <TextField
            value={stopSequence}
            onChange={e => {
              setStopSequence(e.target.value);
              setData({ ...data, stopSequence: e.target.value });
            }}
            label="Stop Sequence"
            fullWidth
          />
          <div style={{ height: 40 }} />
          <Button variant="contained" onClick={() => saveTemplate()}>
            Save
          </Button>
          <Button variant="contained" onClick={() => resetTemplate()}>
            Reset
          </Button>
          <div>
            <p>
              Templates are made with the Handlebars templating language.{" "}
              <a
                href="https://handlebarsjs.com/guide/"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </a>
              .
            </p>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Example Output Prompt</h3>
          {renderInterpolatedTemplate(templateString, data)}
        </Grid>
        <Grid item xs={12}>
          <div>
            <FormControl>
              <RadioGroup
                row
                value={record?.returnType || "TEXT"}
                onChange={e => {
                  const returnType = e.target.value || "TEXT";
                  updateRecord({ returnType });
                }}
              >
                <FormControlLabel
                  value="TEXT"
                  control={<Radio />}
                  label="Text"
                />
                <FormControlLabel
                  value="JSON"
                  control={<Radio />}
                  label="JSON"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12}>
          {renderJsonSchemaEditor()}
        </Grid>
        <Grid item xs={12}>
          <Button onClick={testPrompt}>Test</Button>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Response Data</h3>
          {renderCompletionJSON()}
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Prompt</h3>
          {renderPrompt(completion?.prompt)}
        </Grid>
        <Grid item xs={12} lg={4}>
          {renderCompletionChoices(completion?.response?.choices)}
        </Grid>
      </Grid>
      <Button
        style={{ background: "#555", color: "#fff", float: "right" }}
        variant="contained"
        onClick={duplicateTemplate}
      >
        Duplicate {record?.displayName}
      </Button>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
