import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DataObjectIcon from "@mui/icons-material/DataObject";
import PersonIcon from "@mui/icons-material/Person";
import WebhookIcon from "@mui/icons-material/Webhook";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import { Link } from "react-router-dom";
import Sigil from "../../components/canvas";
import { CharacterImage } from "../../components/character-image";
import MinorFrame from "../../components/minor-frame/";
import { useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import {
  useFirestorePagination,
  useFirestorePaginationWithWhere,
} from "../../hooks/useFirestorePagination";
import "./style.css";

const COLLECTION = "packages";
const COLLECTION_DISPLAY_NAME = "Packages";

function PackageCard({ rec, personal = false, style = {} }) {
  const { id, displayName, iconSeedToken, iconSeed } = rec;
  const path = (personal ? "/packages/" : "/public-packages/") + id;

  return (
    <div
      className="package-card animated-fade-in-from-bottom"
      key={id}
      style={style}
    >
      <Link to={path}>
        {rec.photoId ? (
          <CharacterImage imageId={rec.photoId} />
        ) : (
          <Sigil
            seed={iconSeedToken?.seed || iconSeed || id}
            gen={iconSeedToken?.gen || "xenophon"}
            style={{ width: 256 }}
          />
        )}
      </Link>
      <h3>
        <Link to={path}>{displayName || ""}</Link>
      </h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="package-icon-chip">
          {rec?.characterIds?.length || 0}
          <PersonIcon />
        </div>
        <div className="package-icon-chip">
          {rec?.templateIds?.length || 0}
          <DataObjectIcon />
        </div>
        <div className="package-icon-chip">
          {rec?.serviceIds?.length || 0}
          <WebhookIcon />
        </div>
        <div className="package-icon-chip">
          {rec?.sourceIds?.length || 0}
          <CollectionsBookmarkIcon />
        </div>
        <div className="package-icon-chip">
          {rec?.studioIds?.length || 0}
          <ColorLensIcon />
        </div>
      </div>
    </div>
  );
}

function Data() {
  useLogPageView("PublicPackages");
  const { activeProjectId = "" } = useEphemera();
  const wherePath = ["public", "==", true];
  const [publicPackages = [], loadMorePublicPackages, hasMorePublicPackages] =
    useFirestorePaginationWithWhere(COLLECTION, 12, "created", wherePath);
  const colPath = `projects/${activeProjectId}/packages`;
  const [
    privatePackages = [],
    loadMorePrivatePackages,
    hasMorePrivatePackages,
  ] = useFirestorePagination(colPath);

  console.log("publicPackages", publicPackages);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <p>
        These are the packages that have been published to the public. You can
        install them into your project by clicking the install button, but be
        careful! If you install a package that you've installed before, it will
        overwrite the existing package.
      </p>
      {publicPackages.map((rec, index) => (
        <PackageCard
          rec={rec}
          key={rec.id}
          style={{ "--delay": index * 0.1 + "s" }}
        />
      ))}
      <div>
        {hasMorePublicPackages ? (
          <Button
            onClick={loadMorePublicPackages}
            disabled={!hasMorePublicPackages}
          >
            Load more
          </Button>
        ) : (
          <p className="faint">All loaded</p>
        )}
      </div>
      <div>
        <h2>Your Packages</h2>
        {privatePackages.map(rec => (
          <PackageCard rec={rec} key={rec.id} personal={true} />
        ))}
        <div>
          {hasMorePrivatePackages ? (
            <Button
              onClick={loadMorePrivatePackages}
              disabled={!hasMorePrivatePackages}
            >
              Load more
            </Button>
          ) : (
            <p className="faint">All loaded</p>
          )}
        </div>
      </div>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
