import { v4 as uuid } from "uuid";
const MersenneTwister = require("mersenne-twister");
const rGen = new MersenneTwister();

function hashCode(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

const drawCircle = (ctx, opts = {}) => {
  const {
    x,
    y,
    radius,
    lineWidth = ctx.canvas.width * 0.01,
    strokeColor = "#000",
    //fillColor = "#fff",
  } = opts;
  ctx.beginPath();
  ctx.save();
  ctx.strokeColor = strokeColor;
  ctx.lineWidth = lineWidth;
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.restore();
  return ctx;
};

const circlesPattern = (ctx, opts = {}) => {
  // draw some circles
  let {
    x = 0,
    y = 0,
    radius = 3,
    strokeColor = "#000",
    fillColor = "#fff",
    lineWidth = ctx.canvas.width * 0.01,
    steps = 12,
    depth = 0,
  } = opts;
  let r = radius * rGen.random();
  const w = ctx.canvas.width;
  const h = ctx.canvas.height;
  strokeColor = rGen.random() > 0.5 ? strokeColor : fillColor;
  lineWidth = rGen.random() * (radius * 0.05);
  if (rGen.random() > 0.1) {
    drawCircle(ctx, { x, y, radius: r, lineWidth, strokeColor, fillColor });
    if (depth)
      circlesPattern(ctx, {
        x,
        y,
        radius: r,
        lineWidth,
        steps,
        depth: depth - 1,
        strokeColor,
        fillColor,
      });
  } else {
    r *= 0.1;
    lineWidth *= 0.2;
    radius += rGen.random() * (radius * 0.1);
    for (let i = 0; i < steps; i++) {
      // put a circle one radius from the center
      const theta = Math.PI / 2 + (2 * Math.PI * i) / steps;
      const x = w / 2 + (radius - 2 * r) * Math.cos(theta);
      const y = h / 2 + (radius - 2 * r) * Math.sin(theta);
      ctx.beginPath();
      drawCircle(ctx, { x, y, radius: r, lineWidth, strokeColor, fillColor });
      ctx.stroke();
      if (depth)
        circlesPattern(ctx, {
          x,
          y,
          radius: r,
          steps,
          lineWidth,
          depth: depth - 1,
          strokeColor,
          fillColor,
        });
    }
  }
  return ctx;
};

const trianglesPattern = (ctx, opts = {}) => {
  let {
    //x = 0,
    //y = 0,
    w = ctx.canvas.width,
    h = ctx.canvas.height,
    radius = Math.min(w, h) / 2,
    steps = 12,
    depth = 0,
    fillColor = "#fff",
    strokeColor = "#000",
    lineWidth = ctx.canvas.width * 0.01,
  } = opts;
  let r = radius * rGen.random() * (depth * 0.1 + 1);
  lineWidth = rGen.random() * (radius * 0.1);
  strokeColor = rGen.random() > 0.5 ? strokeColor : fillColor;
  const startAngle = (((rGen.random() * 12) | 0) * Math.PI) / 6;
  if (rGen.random() > 0.9) {
    let x = w / 2;
    let y = h / 2;
    drawNgon(ctx, {
      x,
      y,
      radius: r,
      sides: 3,
      lineWidth,
      strokeColor,
      startAngle,
    });
    if (depth)
      trianglesPattern(ctx, {
        x,
        y,
        radius: r,
        steps,
        depth: depth - 1,
        strokeColor,
        fillColor,
      });
  } else {
    r *= 0.5;
    lineWidth *= 0.5;
    for (let i = 0; i < steps; i++) {
      // put a circle one radius from the center
      const theta = Math.PI / 2 + (2 * Math.PI * i) / steps;
      const x = w / 2 + (radius - 2 * r) * Math.cos(theta);
      const y = h / 2 + (radius - 2 * r) * Math.sin(theta);
      drawNgon(ctx, {
        x,
        y,
        radius: r,
        sides: 3,
        lineWidth,
        strokeColor,
        startAngle: theta,
      });
      if (depth)
        trianglesPattern(ctx, {
          x,
          y,
          radius: r,
          steps,
          depth: depth - 1,
          strokeColor,
          fillColor,
        });
    }
  }
  return ctx;
};

const pattern = (ctx, opts = {}) => {
  let {
    //x = 0,
    //y = 0,
    w = ctx.canvas.width,
    h = ctx.canvas.height,
    radius = Math.min(w, h) / 2,
    steps = 12,
    depth = 0,
    fillColor = "#fff",
    strokeColor = "#000",
  } = opts;
  let r = radius * rGen.random() * (depth * 0.1 + 1);
  let lineWidth = rGen.random() * (radius * 0.05);
  strokeColor = rGen.random() > 0.5 ? strokeColor : fillColor;
  const startAngle = (((rGen.random() * 12) | 0) * Math.PI) / 6;
  if (rGen.random() > 0.9) {
    let x = w / 2;
    let y = h / 2;
    drawNgon(ctx, {
      x,
      y,
      radius: r,
      sides: 6,
      lineWidth,
      strokeColor,
      startAngle,
    });
    if (depth)
      pattern(ctx, {
        x,
        y,
        radius: r,
        steps,
        depth: depth - 1,
        strokeColor,
        fillColor,
      });
  } else {
    r *= 0.5;
    lineWidth *= 0.5;
    for (let i = 0; i < steps; i++) {
      // put a circle one radius from the center
      const theta = Math.PI / 2 + (2 * Math.PI * i) / steps;
      const x = w / 2 + (radius - 2 * r) * Math.cos(theta);
      const y = h / 2 + (radius - 2 * r) * Math.sin(theta);
      drawNgon(ctx, { x, y, radius: r, lineWidth, strokeColor, startAngle });
      if (depth)
        pattern(ctx, {
          x,
          y,
          radius: r,
          steps,
          depth: depth - 1,
          strokeColor,
          fillColor,
        });
    }
  }
  return ctx;
};

const drawNgon = (ctx, opts = {}) => {
  const {
    x = ctx.canvas.width / 2,
    y = ctx.canvas.height / 2,
    radius = Math.min(ctx.canvas.width, ctx.canvas.height) / 2,
    sides = 6,
    startAngle = (2 * Math.PI) / 3, // don't forget this is in radians
    lineWidth = ctx.canvas.width * 0.01,
    strokeColor = "rgba(0, 0, 0, 0.8)",
  } = opts;
  //const w = ctx.canvas.width;
  //const h = ctx.canvas.height;
  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = strokeColor;
  // draw the polygon
  let startingPoint = null;
  for (let i = 0; i < sides; i++) {
    const xCoord =
      x + radius * Math.cos(startAngle + (i * 2 * Math.PI) / sides);
    const yCoord =
      y + radius * Math.sin(startAngle + (i * 2 * Math.PI) / sides);
    if (!startingPoint) startingPoint = [xCoord, yCoord];
    if (i === 0) ctx.moveTo(xCoord, yCoord);
    ctx.lineTo(xCoord, yCoord);
  }
  ctx.lineTo(startingPoint[0], startingPoint[1]);
  ctx.lineJoin = "miter";
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
  return ctx;
};

const drawIcon = (ctx, opts = {}) => {
  let {
    seed = uuid(),
    radius = Math.min(ctx.canvas.width, ctx.canvas.height) / 2,
    sides = 6,
    startAngle = Math.PI / 2, // don't forget this is in radians
    fillColor = "#fff",
    strokeColor = "rgba(0, 0, 0, 0.8)",
  } = opts;
  rGen.init_seed(hashCode(seed));
  const w = ctx.canvas.width;
  const h = ctx.canvas.height;
  const x = w / 2;
  const y = h / 2;
  ctx.beginPath();
  if (rGen.random() > 0.9) {
    [fillColor, strokeColor] = [strokeColor, fillColor];
  }
  const lineWidth = rGen.random() * (radius * 0.05);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = strokeColor;
  // draw the polygon
  for (let i = 0; i < sides; i++) {
    const xCoord =
      x + radius * Math.cos(startAngle + (i * 2 * Math.PI) / sides);
    const yCoord =
      y + radius * Math.sin(startAngle + (i * 2 * Math.PI) / sides);
    if (i === 0) ctx.moveTo(xCoord, yCoord);
    ctx.lineTo(xCoord, yCoord);
  }
  // this is actually the border of the icon
  drawNgon(ctx, {
    x,
    y,
    radius: radius - 2,
    sides,
    startAngle,
    fillColor,
    strokeColor,
    lineWidth: 2,
  });
  ctx.clip();
  ctx.fillStyle = fillColor;
  ctx.fill();
  for (let i = 0; i < 8; i++) {
    const steps = 3 * ((rGen.random() * 3) | 0);
    const depth = (rGen.random() * 3) | 0;
    const rv = rGen.random();
    if (rv > 0.8)
      ctx.fillStyle = pattern(ctx, {
        x,
        y,
        radius,
        steps,
        depth,
        strokeColor,
        fillColor,
        lineWidth,
      });
    if (rGen.random() > 0.3)
      ctx.fillStyle = trianglesPattern(ctx, {
        x,
        y,
        radius,
        steps,
        depth,
        strokeColor,
        fillColor,
        lineWidth,
      });
    if (rGen.random() > 0.1)
      ctx.fillStyle = circlesPattern(ctx, {
        x,
        y,
        radius,
        steps,
        depth,
        strokeColor,
        fillColor,
        lineWidth,
      });
  }
  return ctx;
};

export default drawIcon;
