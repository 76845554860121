import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../db";
import "./style.css";

function useCharacterImage(imageId, dimensions) {
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (!imageId) return;
    let d = 64;
    for (let i = 0; i < 4; i++) {
      if (dimensions <= d) break;
      d *= 2;
    }
    const COLLECTION = `character_images_${d}x${d}`;
    const docRef = doc(db, COLLECTION, imageId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId, dimensions]);

  return record;
}

export function CharacterImage({ imageId, dimensions = 128 }) {
  const record = useCharacterImage(imageId, dimensions);

  if (!record || !record.imageData) {
    const nullStyle = {
      height: dimensions,
      width: dimensions,
      background: "#333",
      display: "inline-block",
      marginBottom: "-7px",
    };
    return <div style={nullStyle} />;
  }

  return (
    <img
      alt=""
      src={"data:image/png;base64, " + record.imageData}
      width={dimensions}
      height={dimensions}
      // make sure the image is not wider than the screen
      // and that the image is not taller than the image is wide
      style={{ marginBottom: "-7px", maxWidth: "100%", objectFit: "contain" }}
      className="animated-fade-in"
    />
  );
}
