import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import {
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Task, Chat, StageDirection } from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

const find = (s, fn) => {
  // a recursive function to find a value in a nested object
  if (fn(s)) return s;
  if (!s.subtasks) return null;
  for (let i = 0; i < s.subtasks.length; i++) {
    const res = find(s.subtasks[i], fn);
    if (res) return res;
  }
  return null;
};

// recursive forEach
const forEach = (s, fn) => {
  fn(s);
  if (!s.subtasks?.length) return;
  for (let i = 0; i < s.subtasks.length; i++) {
    forEach(s.subtasks[i], fn);
  }
};

const reCountNodes = (node, count = 0) => {
  // given an object with a subtasks array property
  // recursively count the number of nodes in the tree
  // including the root node
  if (!node) return count;
  count++;
  if (!node.subtasks) return count;
  node.subtasks.forEach(subtask => {
    count = reCountNodes(subtask, count);
  });
  return count;
};

/*
const dedupeTree = (node, key = "id", seen = new Set()) => {
  // given an object with a subtasks array property
  // recursively remove duplicate nodes from the tree
  // including the root node
  if (!node) return node;
  if (seen.has(node[key])) {
    console.log("duplicate", node[key]);
    return null;
  }
  seen.add(node[key]);
  if (!node.subtasks) return node;
  node.subtasks = node.subtasks.map(subtask => dedupeTree(subtask, key, seen));
  node.subtasks = node.subtasks.filter(subtask => subtask);
  return node;
};
*/

const TaskCard = ({
  task,
  addSubtask,
  deleteTask,
  updateTask,
  plan,
  credits,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(task.description || "");
  const [newResult, setNewResult] = useState(task.result || "");

  const handleUpdateTask = () => {
    updateTask(task.id, {
      description: newDescription.trim(),
      result: newResult.trim(),
    });
  };

  // color the left border of the card based on the task status
  let badgeColor = "transparent";
  const GREEN = "#4caf50";
  //const YELLOW = "#ffeb3b";
  const ORANGE = "#ff9800";
  const BLUE = "#2196f3";
  const RED = "#f44336";

  if (task.status === "COMPLETE") badgeColor = GREEN;
  if (task.status === "READY") badgeColor = BLUE;
  if (task.status === "INCOMPLETE") badgeColor = RED;
  if (task.status === "PENDING") badgeColor = ORANGE;

  const badgeStyle = {
    background: badgeColor,
    height: 20,
    width: 20,
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: -40,
    marginRight: 8,
    marginBottom: -6,
    cursor:
      task.status === "READY" || task.status === "INCOMPLETE"
        ? "pointer"
        : "default",
  };

  const style = {
    borderBottom: `2px solid ${badgeColor}`,
  };

  const badgeClick = () => {
    // if the status is READY then set it to INCOMPLETE
    // if the status is INCOMPLETE then set it to READY
    // otherwise do nothing
    if (task.status === "READY") {
      updateTask(task.id, { status: "INCOMPLETE" });
    } else if (task.status === "INCOMPLETE") {
      updateTask(task.id, { status: "READY" });
    }
  };

  const canPlan =
    task.status === "INCOMPLETE" && task.description && credits?.amount > 0;
  let tooltip = "";
  if (task.status === "INCOMPLETE") tooltip = "Mark as READY";
  if (task.status === "READY") tooltip = "Mark as INCOMPLETE";

  return (
    <div className="task-card">
      <Tooltip title={tooltip}>
        <div style={badgeStyle} onClick={badgeClick} />
      </Tooltip>
      {isEditing ? (
        <div className="edit-task">
          <Tf
            value={newDescription || ""}
            multiline
            fullWidth
            label="description"
            onChange={e => {
              setNewDescription(e.target.value);
            }}
            onBlur={handleUpdateTask}
          />
          <span className="float-right">
            <RadioGroup
              aria-label="status"
              name="status"
              value={task?.status}
              onChange={e => updateTask(task.id, { status: e.target.value })}
              row
            >
              <FormControlLabel
                value="COMPLETE"
                control={<Radio />}
                label="COMPLETE"
              />
              <FormControlLabel
                value="INCOMPLETE"
                control={<Radio />}
                label="INCOMPLETE"
              />
              <FormControlLabel
                value="PENDING"
                control={<Radio />}
                label="PENDING"
              />
              <FormControlLabel
                value="READY"
                control={<Radio />}
                label="READY"
              />
            </RadioGroup>
          </span>
          <Tf
            value={newResult || ""}
            multiline
            fullWidth
            label="result"
            onChange={e => setNewResult(e.target.value)}
            onBlur={handleUpdateTask}
          />
        </div>
      ) : (
        <>
          <span style={style}>
            {task.description.substr(0, 60) ||
              "Click Edit to add a description..."}
            {task.description.length > 60 && "..."}
          </span>
        </>
      )}
      {isEditing ? (
        <Button onClick={() => setIsEditing(prev => !prev)}>close</Button>
      ) : (
        <Button
          onClick={() => setIsEditing(prev => !prev)}
          sx={{ color: badgeColor }}
        >
          edit
        </Button>
      )}
      {canPlan && (
        <Button
          onClick={() => {
            console.log("plan");
            plan(task.id);
          }}
          variant="contained"
          size="small"
        >
          Plan
        </Button>
      )}
      <Tooltip title="Add Subtask">
        <IconButton onClick={() => addSubtask(task.id)} className="add-subtask">
          <AddIcon />
        </IconButton>
      </Tooltip>
      <IconButton onClick={() => deleteTask(task.id)}>
        <DeleteIcon />
      </IconButton>
      <TaskList
        tasks={task.subtasks}
        addSubtask={addSubtask}
        deleteTask={deleteTask}
        updateTask={updateTask}
        plan={plan}
        credits={credits}
      />
    </div>
  );
};

const TaskList = ({
  tasks,
  addSubtask,
  deleteTask,
  updateTask,
  plan,
  credits,
}) => (
  <ul className="task-tree">
    {tasks.map(task => {
      return (
        <li key={task.id}>
          <TaskCard
            task={task}
            addSubtask={addSubtask}
            deleteTask={deleteTask}
            updateTask={updateTask}
            plan={plan}
            credits={credits}
          />
        </li>
      );
    })}
  </ul>
);

function Data() {
  useLogPageView("Task");
  const [record, setRecord] = useState(null);
  const [credits, setCredits] = useState({ amount: 0 });
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const { id } = useParams();
  // the progressive planning setInterval
  const [progressivePlanningInterval, setProgressivePlanningInterval] =
    useState(null);

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (data.tasks.length) {
        //let rootTask = data.tasks[0];
        //rootTask = dedupeTree(rootTask);
        //rootTask = dedupeTree(rootTask, "description");
        //data.tasks = [rootTask];
      }
      console.log("data", data);
      if (!data) return;
      setRecord(data);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the credits for the project
  useEffect(() => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, "meta", "credits");
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return setCredits({ amount: 0 });
      setCredits(data);
    });
    return unsubscribe;
  }, [activeProjectId]);

  const clean = (arr, fn) => {
    // clean will remove any nodes that don't pass the truth test fn
    // BUT! if that node has subtasks, and they have descriptions that pass the test,
    // then the node will be removed, and the subtasks will be instead added to the safeParent.subtasks
    const superSafeParent = { subtasks: arr };
    const walk = (sibs, fn, safeParent = superSafeParent) => {
      let nextSubtasks = [];
      for (let i = 0; i < sibs.length; i++) {
        let sib = sibs[i];
        if (fn(sib)) {
          if (safeParent) {
            nextSubtasks.push(sib);
          } else {
            nextSubtasks.push(sib);
          }
          if (sib.subtasks) walk(sib.subtasks, fn, sib);
        } else {
          if (sib.subtasks) sibs.push(...sib.subtasks);
          sibs.splice(i--, 1);
        }
      }
      if (safeParent) {
        const safeParentSubtaskIds = new Set(
          safeParent.subtasks.map(s => s.id)
        );
        nextSubtasks = nextSubtasks.filter(
          s => !safeParentSubtaskIds.has(s.id)
        );
        safeParent.subtasks.push(...nextSubtasks);
      } else {
        const topLevelSubtaskIds = new Set(arr.map(s => s.id));
        nextSubtasks = nextSubtasks.filter(s => !topLevelSubtaskIds.has(s.id));
        arr.push(...nextSubtasks);
      }
    };
    walk(arr, fn, null);
    return arr;
  };

  async function triggerProgressivePlanning() {
    // this function will trigger progressive planning which will trigger
    // a plan every 20 seconds as long as there are incomplete tasks
    // and there are credits to spend
    if (!activeProjectId) return;
    if (!record) return;
    if (!id) return;
    if (progressivePlanningInterval || !record.progressivePlanning) return;
    setProgressivePlanningInterval(
      setInterval(() => {
        plan();
      }, 20000)
    );
  }

  async function plan(taskId = "") {
    if (!activeProjectId) return;
    if (!record) return;
    if (!id) return;
    let tree = JSON.parse(JSON.stringify(record?.tasks || []));
    tree = clean(tree, n => n.description);
    const docRef = doc(db, "projects", activeProjectId, "plans", id);
    const newRec = { ...record, tasks: tree };
    setRecord(prev => ({ ...prev, status: "IN_PROGRESS" }));
    await setDoc(docRef, newRec);
    const COLLECTION = "planning_requests";
    const rec = { planId: id, id: uuid(), taskId };
    const reqDocRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    await setDoc(reqDocRef, rec);
    // if the progressive planning interval is not set, and record.progressivePlanning is true
    // then set the interval
    if (!progressivePlanningInterval && record.progressivePlanning) {
      triggerProgressivePlanning();
    }
  }

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { ...update, lastUpdated: Timestamp.now() });
  };

  const addSubtask = parentId => {
    if (!record) return;
    const newTask = Task();
    const prevTasks = record?.tasks || [];
    const updatedTasks = JSON.parse(JSON.stringify(prevTasks));
    let parentTask = findTaskById(updatedTasks, parentId);
    if (!parentTask) {
      // this is the first task, just add it to the tasks array
      updatedTasks.push(newTask);
    } else {
      parentTask.subtasks.push(newTask);
    }
    updateRecord({ tasks: updatedTasks });
  };

  const deleteTask = taskId => {
    if (!record) return;
    const prevTasks = record?.tasks || [];
    let updatedTasks = JSON.parse(JSON.stringify(prevTasks));
    let parentTask = findTaskParent(updatedTasks, taskId);
    if (!parentTask) {
      // this task is in the top level, just remove it from the tasks array
      console.log("deleteTask", taskId);
      updatedTasks = updatedTasks.filter(t => t.id !== taskId);
    } else {
      parentTask.subtasks = parentTask.subtasks.filter(t => t.id !== taskId);
    }
    return updateRecord({ tasks: updatedTasks });
  };

  const updateTask = (taskId, update) => {
    if (!record) return;
    const prevTasks = record?.tasks || [];
    const updatedTasks = JSON.parse(JSON.stringify(prevTasks));
    const task = findTaskById(updatedTasks, taskId);
    Object.assign(task, update);
    return updateRecord({ tasks: updatedTasks });
  };

  const createChat = async () => {
    const COLLECTION = "chats";
    const rec = Chat();
    const plan = record;
    let message = "The conversation is focused on the top level objective: ";
    message += plan.tasks[0].description;
    message += "\n\n";
    message += "Though in order to achieve that objective, ";
    message += "we need to satisfy potentially many subtasks.";
    const sd = StageDirection(message);
    rec.planId = id;
    rec.stageDirections.push(sd);
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    await setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + rec.id);
  };

  const duplicatePlan = async () => {
    if (!activeProjectId) return;
    if (!record) return;
    if (!id) return;
    const newRec = {
      ...record,
      id: uuid(),
      displayName: record.displayName + " (copy)",
    };
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, newRec.id);
    await setDoc(docRef, newRec);
    history.push("/" + COLLECTION + "/" + newRec.id);
  };

  const findTaskById = (tasks, taskId) => {
    for (const task of tasks) {
      if (task.id === taskId) return task;
      const foundTask = findTaskById(task.subtasks, taskId);
      if (foundTask) return foundTask;
    }
    return null;
  };

  const findTaskParent = (tasks, taskId) => {
    if (!tasks) return null;
    for (const task of tasks) {
      if (task.subtasks.find(subtask => subtask.id === taskId)) return task;
      const foundParent = findTaskParent(task.subtasks, taskId);
      if (foundParent) return foundParent;
    }
    return null;
  };

  const renderProgressivePlanningArea = () => {
    return (
      <span>
        <Switch
          onChange={e => {
            console.log("e.target.checked", e.target.checked);
            updateRecord({ progressivePlanning: e.target.checked });
          }}
        />
      </span>
    );
  };

  const renderRecursiveTreeEditor = () => {
    //console.log("renderRecursiveTreeEditor", record);
    if (!record) return null;
    const { status = "READY" } = record;
    let addButton = (
      <IconButton onClick={() => addSubtask(null)}>
        <AddIcon />
      </IconButton>
    );
    if (record?.tasks?.length) {
      addButton = <div style={{ width: 100, height: 40 }} />;
    }
    let planButton = null;
    if (credits?.amount > 0) {
      if (status === "IN_PROGRESS") {
        planButton = (
          <Button
            variant="contained"
            className="float-right"
            style={{ zIndex: 2, background: "lightgray" }}
            onClick={() => plan()}
          >
            Planning...
          </Button>
        );
      } else {
        planButton = (
          <Button
            variant="contained"
            className="float-right"
            onClick={() => plan()}
            style={{ zIndex: 2 }}
          >
            Plan
          </Button>
        );
      }
    }
    // now we're going to check if the plan is even eligible to be planned
    // if it has no tasks, it's not eligible
    if (!record?.tasks?.length) planButton = null;
    let eligibleTask = find(record?.tasks[0] || {}, t => t.description);
    if (!eligibleTask) planButton = null;
    eligibleTask = find(record?.tasks[0] || {}, t => t.status === "INCOMPLETE");
    if (!eligibleTask) planButton = null;
    const numberOfTasks = reCountNodes(record?.tasks[0]) || 0;
    let numberIncomplete = 0;
    let numberComplete = 0;
    let numberPending = 0;
    let numberReady = 0;
    forEach(record?.tasks[0] || {}, t => {
      t.status === "INCOMPLETE" && numberIncomplete++;
      t.status === "COMPLETE" && numberComplete++;
      t.status === "PENDING" && numberPending++;
      t.status === "READY" && numberReady++;
    });
    return (
      <div>
        {renderProgressivePlanningArea()}
        {planButton}
        {addButton}
        <h2 style={{ marginLeft: 40, marginTop: -20, marginBottom: -16 }}>
          {numberOfTasks} Tasks
        </h2>
        <p className="faint" style={{ marginLeft: 40 }}>
          {numberComplete} COMPLETE | {numberIncomplete} INCOMPLETE |{" "}
          {numberPending} PENDING | {numberReady} READY
        </p>
        <div className="outer-task-list-container">
          <TaskList
            tasks={record?.tasks}
            addSubtask={addSubtask}
            deleteTask={deleteTask}
            updateTask={updateTask}
            plan={plan}
            credits={credits}
          />
        </div>
      </div>
    );
  };

  if (!record) return null;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <div>
            <Tf
              label="Name"
              InputProps={{ style: { fontSize: "2em" } }}
              value={record?.displayName}
              onChange={e => updateRecord({ displayName: e.target.value })}
              fullWidth
            />
            <div style={{ height: 24 }} />
            <Tf
              label="Description"
              value={record?.description}
              onChange={e => updateRecord({ description: e.target.value })}
              multiline
              rows={4}
              fullWidth
            />
            <Button onClick={createChat}>Begin</Button>
          </div>
        </Grid>
        <Grid item lg={9} xs={12}>
          {renderRecursiveTreeEditor()}
          {record?.tasks?.length ? (
            <Button
              className="float-right"
              sx={{ background: "#333" }}
              onClick={duplicatePlan}
              variant="contained"
              color="primary"
            >
              Duplicate {record.displayName || "Plan"}
            </Button>
          ) : null}
          <div style={{ height: 24, clear: "both" }} />
        </Grid>
      </Grid>
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="secondary" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
