import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AppsIcon from "@mui/icons-material/Apps";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import TemplateIcon from "@mui/icons-material/DataObject";
import GroupsIcon from "@mui/icons-material/Groups";
import MessageIcon from "@mui/icons-material/Message";
import PaletteIcon from "@mui/icons-material/Palette";
import PersonIcon from "@mui/icons-material/Person";
import WebhookIcon from "@mui/icons-material/Webhook";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { signOut } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWithGooglePopup } from "../../db";
import "./style.css";
import { BackgroundThing } from "./BackgroundThing";

function Double({ children }) {
  return (
    <Grid container className="double">
      <Grid item xs={12} md={6}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={6}>
        {children[1]}
      </Grid>
    </Grid>
  );
}

function RevDouble({ children }) {
  // if the size is small, and the elements stack, then make the second element
  // come first, like reverse the order
  return (
    <Grid container direction="row-reverse" className="double">
      <Grid item xs={12} md={6}>
        {children[1]}
      </Grid>
      <Grid item xs={12} md={6}>
        {children[0]}
      </Grid>
    </Grid>
  );
}

function Triple({ children }) {
  // a three column layout
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={4}>
        {children[1]}
      </Grid>
      <Grid item xs={12} md={4}>
        {children[2]}
      </Grid>
    </Grid>
  );
}

function Hextuple({ children }) {
  return (
    <Grid container className="centered">
      <Grid item xs={6} md={2}>
        {children[0]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[1]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[2]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[3]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[4]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[5]}
      </Grid>
    </Grid>
  );
}

function Footer() {
  return <div className="landing-footer">support@dangbot.com</div>;
}

const taglines = [
  "dangbot is an experimental autonomous agent platform.",
  "dangbot is a platform for building and experimenting with autonomous agents.",
];

const tagline = taglines[Math.floor(Math.random() * taglines.length)];

export default function Landing() {
  const [user] = useAuthState(auth);
  const history = useHistory();

  async function logOut() {
    await signOut(auth);
    history.push("/");
  }

  async function signIn() {
    try {
      const creds = await signInWithGooglePopup();
      history.push("/chats");
      return creds;
    } catch (e) {
      console.error("failed to sign in");
      return null;
    }
  }

  const renderCharacterCards = () => {
    return (
      <div className="landing-character-cards">
        <div className="character-card" style={{ transform: "rotate(-4deg)" }}>
          <div className="character-card-content">
            <img
              src="/images/characters/krakatoa_256.png"
              alt="character portrait"
            />
            <h2>Concierge</h2>
          </div>
        </div>
        <div className="character-card" style={{ transform: "rotate(4deg)" }}>
          <div className="character-card-content">
            <img
              src="/images/characters/singular_256.png"
              alt="character portrait"
              // make it a diamond
            />
            <h2>Project Manager</h2>
          </div>
        </div>
        <div className="character-card" style={{ transform: "rotate(-6deg)" }}>
          <div className="character-card-content">
            <img
              src="/images/characters/jung_256.png"
              alt="character portrait"
            />
            <h2>Coach</h2>
          </div>
        </div>
        <div className="character-card">
          <div className="character-card-content">
            <img
              src="/images/characters/harmony_256.png"
              alt="character portrait"
            />
            <h2>User Experience Expert</h2>
          </div>
        </div>
        <div className="character-card" style={{ transform: "rotate(6deg)" }}>
          <div className="character-card-content">
            <img
              src="/images/characters/kristof_256.png"
              alt="character portrait"
            />
            <h2>Contrarian</h2>
          </div>
        </div>
      </div>
    );
  };

  const linkContainerStyle = {
    color: "#fff",
    textDecoration: "none",
  };

  // make the signInBlock centered top to bottom but coming out of the left
  // just get the date dd/mm/yyyy
  //<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"></svg>
  // don't allow scrolling
  /*
          <path
            fill="#0099ff"
            fill-opacity="1"
            d="M0,96L48,122.7C96,149,192,203,288,224C384,245,480,235,576,202.7C672,171,768,117,864,122.7C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
 
  */
  return (
    <div className="App">
      <BackgroundThing />
      <AppBar position="static" sx={{ backgroundColor: "#282c34" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            dangbot
          </Typography>
          {user ? (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link style={linkContainerStyle} to="/chats">
                chats
              </Link>{" "}
            </Typography>
          ) : null}
          {user ? (
            <Button color="inherit" onClick={() => logOut()}>
              SIGN OUT
            </Button>
          ) : (
            <Button color="inherit" onClick={() => signIn()}>
              SIGN IN
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <h1 className="landing-exclaimation">Make your own dang team!</h1>
      {renderCharacterCards()}
      <div className="cta-container">
        <button
          className="lfg-button"
          onClick={() => {
            user ? history.push("/chats") : signIn();
          }}
        >
          LFG!
        </button>
      </div>
      <div className="landing-content">
        <Double>
          <h2>this won't be perfect</h2>
          <h2 className="bold">but it might be fun</h2>
        </Double>
        <p className="dark centered big">{tagline}</p>
        <Hextuple>
          <Tooltip title="Characters">
            <div style={{ marginTop: 60 }}>
              <PersonIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
          <Tooltip title="Chat">
            <div style={{ marginTop: 60 }}>
              <MessageIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
          <Tooltip title="Templates">
            <div style={{ marginTop: 60 }}>
              <TemplateIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
          <Tooltip title="Sources">
            <div style={{ marginTop: 60 }}>
              <CollectionsBookmarkIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
          <Tooltip title="Services">
            <div style={{ marginTop: 60 }}>
              <WebhookIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
          <Tooltip title="Studios">
            <div style={{ marginTop: 60 }}>
              <PaletteIcon style={{ transform: "scale(3)" }} />
            </div>
          </Tooltip>
        </Hextuple>
        <p className="landing-exposition">
          dangbot is a laboratory for experimenting with new kinds of autonomous
          agents. It is a place to explore new kinds of organizations by rapidly
          iterating not on the foundational LLMs at the core, but on the
          structure within which they are nested and the ways in which many
          agents can be combined to make teams effective for a variety of
          purposes.
        </p>
        <Double>
          <img
            src="/images/build-unique-characters.png"
            width="100%"
            alt="characters screenshot"
          />
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Characters">
                <div style={{ paddingTop: 60 }}>
                  <PersonIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Build unique characters</h2>
            <p className="padded big">
              dangbot is a place to build unique characters and explore their
              relationships with each other. Characters can be built from
              templates, or from scratch.
            </p>
          </div>
        </Double>
        <RevDouble>
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Team">
                <div style={{ paddingTop: 60 }}>
                  <GroupsIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Craft a Team</h2>
            <p className="padded big">
              Many Characters can be included in a single chat. This allows
              Characters to collaborate on projects. Compose the team you need,
              then get to work.
            </p>
          </div>
          <img
            src="/images/chats-view.png"
            width="100%"
            alt="chat screenshot"
          />
        </RevDouble>
        <Double>
          <img src="/images/plan-view.png" width="100%" alt="chat screenshot" />
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Chat">
                <div style={{ paddingTop: 60 }}>
                  <AccountTreeIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Make a Plan</h2>
            <p className="padded big">
              Set a goal for your team and let dangbot split it into tasks that
              can be assigned to individual Characters.
            </p>
          </div>
        </Double>
        <RevDouble>
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Plans">
                <div style={{ paddingTop: 60 }}>
                  <MessageIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Delegate!</h2>
            <p className="padded big">
              Plans can be assigned to individual Characters, or to the whole
              team. Characters can execute the plan by working together with
              each other or with humans.
            </p>
          </div>
          <img src="/images/execution.png" width="100%" alt="chat screenshot" />
        </RevDouble>
        <Double>
          <img
            src="/images/full-control-over-llm-templates.png"
            width="100%"
            alt="templates screenshot"
          />
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Templates">
                <div style={{ paddingTop: 60 }}>
                  <TemplateIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Customize your Prompts</h2>
            <p className="padded big">
              Customize your prompts to make them unique to your characters.
            </p>
          </div>
        </Double>
        <RevDouble>
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Sources">
                <div style={{ paddingTop: 60 }}>
                  <CollectionsBookmarkIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Transparent editable memory</h2>
            <p className="padded big">
              Persistant, transparent, editable memory for each character.
            </p>
          </div>
          <img
            src="/images/persistant-transparent-editable-memory.png"
            width="100%"
            alt="sources screenshot"
          />
        </RevDouble>
        <Double>
          <img
            src="/images/flexible-api-integrations.png"
            width="100%"
            alt="services screenshot"
          />
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Services">
                <div style={{ paddingTop: 60 }}>
                  <WebhookIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Flexible API integrations</h2>
            <p className="padded big">
              Flexible API integrations for integrating web search, private APIs
              and more.
            </p>
          </div>
        </Double>
        <RevDouble>
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Studios">
                <div style={{ paddingTop: 60 }}>
                  <PaletteIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>ImageGen for Character Portraits</h2>
            <p className="padded big">
              Use OpenJourney or Stable Diffusion from the studio to generate
              character portraits.
            </p>
          </div>
          <img
            src="/images/image-generation-studio.png"
            width="100%"
            alt="studio screenshot"
          />
        </RevDouble>
        <Double>
          <img
            src="/images/package-manager.png"
            width="100%"
            alt="package manager screenshot"
          />
          <div className="slate" style={{ height: "100%", marginTop: 0 }}>
            <div className="centered">
              <Tooltip title="Packages">
                <div style={{ paddingTop: 60 }}>
                  <AppsIcon style={{ transform: "scale(3)" }} />
                </div>
              </Tooltip>
            </div>
            <h2>Share your Characters</h2>
            <p className="padded big">
              Characters, Sources, Templates, Services, and Studios can be
              shared with other users through the package manager.
            </p>
          </div>
        </Double>
        <Triple>
          <p className="padded big">
            Autonomous Agents are still in their infancy. They're not ready for
            the real world yet, but if we're going to learn how to fold them
            into our lives effectively, we need to start experimenting with them
            now.
          </p>
          <p className="padded big">
            We're going to need a way for a lot of us, from all walks of life,
            to experiment with them, and to share our findings with each other
            more easily than Twitter threads and screenshots.
          </p>
          <p className="padded big">
            My hope is that this platform will allow laypeople, artists, and
            researchers to experiment with Autonomous Agents without having to
            worry about the technical details of spinning up a server, or
            implementing a chatbot from scratch. No technical expertise
            required.
          </p>
        </Triple>
        <div className="centered">
          <h2>Get started now for free</h2>
          {user ? null : (
            <button
              className="sign-in-button fade-up-second"
              onClick={() => signIn()}
            >
              SIGN IN
            </button>
          )}
          {user ? (
            <>
              <span style={{ paddingLeft: 20 }}>
                <Link to="/chats">CHATS</Link>
              </span>
              <span style={{ paddingLeft: 20 }}>
                <Link to="/characters">CHARACTERS</Link>
              </span>
            </>
          ) : null}
        </div>
        <Footer />
      </div>
    </div>
  );
}
