import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Ff from "./components/ff/";
import { auth } from "./db";
import {
  adminPaths,
  ProtectedRoutePath,
  RoutePath,
  unprotectedPaths,
} from "./routes";
import "./style.css";

function App() {
  const [user, loading = true] = useAuthState(auth);
  return (
    <Router>
      <Switch>
        {unprotectedPaths.map(RoutePath)}
        <Ff flag="admin">
          {adminPaths.map(r => ProtectedRoutePath(r, loading, user))}
        </Ff>
      </Switch>
    </Router>
  );
}

export default App;
