import formatDistance from "date-fns/formatDistance";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const storage = getStorage();

export const radiansToDegrees = radians => radians * (180 / Math.PI);

export const degreesToRadians = degrees => degrees * (Math.PI / 180);

// memoize this later
export const getImageUrl = async fileRef => {
  const pathRef = ref(storage, fileRef);
  return await getDownloadURL(pathRef)
    .then(url => url)
    .catch(console.error);
};

export function fromNow(date) {
  if (!date) return null;
  if (!date.seconds) return null;
  // date should look something like {seconds: 1620000000, nanoseconds: 0}
  return formatDistance(new Date(date.seconds * 1000), new Date(), {
    addSuffix: true,
  });
}

export function toDataURL(src, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    const canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
}

export function downscaleImage(src, callback) {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    const width = 160;
    const height = 90;
    const canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;
    ctx.drawImage(this, 0, 0, width, height);
    const dataURL = canvas.toDataURL();
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
}

export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export const trunc = (num, places = 2) => {
  // take in a long float and return a (potentially) shorter float
  return Number(num.toFixed(places));
};

export const downsample = (data, n) => {
  const result = [];
  let sum = 0;
  let count = 0;
  let k = Math.floor(data.length / n);
  for (let i = 0; i < data.length; i++) {
    if (!data[i]) continue;
    sum += data[i];
    count++;
    if (count === k) {
      result.push(sum / k);
      sum = 0;
      count = 0;
    }
  }
  return result;
};
