import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {
  collection,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import Sigil from "../../components/canvas";
import { CharacterImage } from "../../components/character-image";
import { auth, db, signInWithGooglePopup, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { InstallPackageRequest } from "../../models";
import { fromNow } from "../../utils";
import { BackgroundThing } from "../landing/BackgroundThing";
import { COLLECTION } from "./constants";
import "./style.css";

const BouncingElipsis = ({ delay = 0 }) => {
  // we want ot wait for "delay" milliseconds before showing the dots
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!show) return null;

  return (
    <Card
      sx={{ p: 1, m: 1 }}
      style={{ background: "#f0f0f0", fontSize: "2em", opacity: 1 }}
      className="pseudo-message-card "
    >
      <div className="jumping-dots">
        <span className="dot-1">.</span>
        <span className="dot-2">.</span>
        <span className="dot-3">.</span>
      </div>
    </Card>
  );
};

function truncatedText(text, length) {
  if (text.length <= length) return text;
  return text.substring(0, length) + "...";
}

function MetaMessageCard({ metaMessage = {}, style = {} }) {
  const [expanded, setExpanded] = useState(false);
  const textAlign = "right";
  const margin = 2;
  return (
    <div
      style={{ ...style, textAlign, margin }}
      className="animated-fade-in-from-right"
    >
      <span
        className="meta-message-card-title"
        onClick={() => setExpanded(exp => !exp)}
      >
        {truncatedText(metaMessage.text, 40)}
      </span>
      <div
        style={{
          display: expanded ? "block" : "none",
          textAlign: "left",
          padding: 12,
          border: "1px solid #ccc",
        }}
      >
        <p className=" faint">{fromNow(metaMessage.created)}</p>
        <ReactMarkdown className="react-markdown-code-block">
          {metaMessage.text}
        </ReactMarkdown>
        <p className="faint">Credit Cost: {metaMessage.creditCost || 0}</p>
      </div>
    </div>
  );
}

function MessageCard({ message, character = {}, style = {} }) {
  const backgroundColor = message.author.bot ? "#f0f0f0" : "#ffffff";
  const authorStyle = { fontWeight: "bold" };
  // make a few chips at the bottom of the card that only fade into view
  // when the card is hovered over
  const d = 128;
  let img = <CharacterImage imageId={character?.photoId} dimensions={d} />;
  if (!character.photoId) {
    img = (
      <div style={{ display: "inline-block", marginTop: 8 }}>
        <Sigil
          seed={
            character?.iconSeedToken?.seed ||
            character?.iconSeed ||
            character?.id
          }
          gen={character?.iconSeedToken?.gen || "xenophon"}
          style={{ width: d }}
        />
      </div>
    );
  }
  if (!message.author.bot && message.author.photoURL) {
    const author = message.author;
    img = (
      <div style={{ display: "inline-block", marginTop: 8 }}>
        <Sigil
          seed={author?.iconSeedToken?.seed || author?.iconSeed || author?.id}
          gen={author?.iconSeedToken?.gen || "xenophon"}
          style={{ width: d }}
        />
      </div>
    );
  }
  return (
    <Card
      sx={{ p: 1, m: 1 }}
      style={{ ...style, backgroundColor }}
      className="message-card animated-fade-in-from-left"
    >
      <span className="character-chat-icon" style={{ float: "left" }}>
        {img}
      </span>
      <span style={authorStyle}>{message.author.displayName} </span>
      <p className="faint">{character.description}</p>
      <ReactMarkdown className="react-markdown-code-block">
        {message.text}
      </ReactMarkdown>
      <div style={{ clear: "both" }} />
    </Card>
  );
}

function MessagesArea({
  messages = [],
  metaMessages = [],
  deleteMessage = () => {},
  characters = [],
  messagingInProgress = false,
}) {
  // show the bouncing elipsis when the last message is from a human
  let cards = messages.concat(metaMessages);
  cards = cards.sort((a, b) => a?.created?.seconds - b?.created?.seconds);
  let triggered = false;
  return (
    <>
      {cards.map((message, index) => {
        let delay = index * 0.1 + "s";
        if (index === cards.length - 1) delay = "0s";
        const style = { "--delay": delay };
        if (message.type === "META" || message.type === "SYSTEM") {
          if (!triggered) return null;
          return (
            <MetaMessageCard
              key={message.id}
              metaMessage={message}
              style={style}
            />
          );
        }
        triggered = true;
        const character = characters.find(c => c.id === message?.author?.id);
        return (
          <MessageCard
            key={message.id}
            message={message}
            deleteMessage={deleteMessage}
            character={character}
            style={style}
          />
        );
      })}
      {messagingInProgress && <BouncingElipsis />}
    </>
  );
}

function Page() {
  useLogPageView("PublicChat");
  const [record, setRecord] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageLimit, setMessageLimit] = useState(8);
  const history = useHistory();
  const [user] = useAuthState(auth);
  const { activeProjectId = "" } = useEphemera();
  const messagesAreaRef = useRef();
  const { packageId, archiveId = 1, chatId } = useParams();

  const id = chatId;

  useEffect(() => {
    if (!packageId) return;
    if (!archiveId) return;
    if (!chatId) return;
    const collectionRef = collection(
      db,
      "packages",
      packageId,
      "archive",
      packageId + "-" + archiveId,
      COLLECTION,
      chatId,
      "messages"
    );
    const q = query(
      collectionRef,
      orderBy("created", "asc"),
      limit(messageLimit)
    );
    const unsubscribe = onSnapshot(q, snap => {
      // reverse the order of the documents
      let data = snap.docs.map(doc => doc.data()).reverse();
      if (!data) return;
      setMessages(data);
    });
    return unsubscribe;
  }, [archiveId, packageId, chatId, messageLimit]);

  useEffect(() => {
    // get the chat document
    if (!packageId || !archiveId || !chatId) return;
    const docRef = doc(
      db,
      "packages",
      packageId,
      "archive",
      packageId + "-" + archiveId,
      COLLECTION,
      chatId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
    });
    return unsubscribe;
  }, [archiveId, packageId, chatId]);

  // get the characters, all of them...
  useEffect(() => {
    if (!packageId || !archiveId || !chatId) return;
    const colRef = collection(
      db,
      "packages",
      packageId,
      "archive",
      packageId + "-" + archiveId,
      "characters"
    );
    const unsubscribe = onSnapshot(colRef, snap => {
      const characters = [];
      snap.docs.forEach(doc => characters.push(doc.data()));
      setCharacters(characters);
    });
    return unsubscribe;
  }, [archiveId, packageId, chatId]);

  const createInstallPackageRequest = async () => {
    if (!activeProjectId || !packageId) return;
    const rec = InstallPackageRequest(packageId);
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "install_package_requests",
      rec.id
    );
    await setDoc(docRef, rec);
    history.push("/chats");
  };

  async function signIn() {
    console.log("Signing in...");
    try {
      const creds = await signInWithGooglePopup();
      if (creds) {
        await createInstallPackageRequest(packageId);
        history.push("/chats");
      }
      return creds;
    } catch (e) {
      console.error("failed to sign in");
      return null;
    }
  }

  const deleteMessage = messageId => {
    if (!activeProjectId) return;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages",
      messageId
    );
    deleteDoc(docRef);
  };

  if (!record) return <div ref={messagesAreaRef}>Loading...</div>;

  const renderTryItButton = () => {
    // okay, this button is very fancy
    // if you're logged in it will install a package
    // and if you're not logged in, it will log you in
    const signedIn = !!user;
    if (!signedIn) {
      return (
        <button
          className="lfg-button"
          onClick={() => {
            return signIn();
          }}
        >
          Sign in to Try it!
        </button>
      );
    }
    return (
      <button
        className="lfg-button"
        onClick={() => {
          createInstallPackageRequest();
        }}
      >
        Try it!
      </button>
    );
  };

  return (
    <>
      <div className="public-messages-area-container">
        <div className="public-messages-area" ref={messagesAreaRef}>
          <h1>DANGBOT: {record?.displayName}</h1>
          <div style={{ background: "#555", color: "#fff", padding: 12 }}>
            {record?.stageDirections.map(stageDirection => {
              return <p key={stageDirection.id}>{stageDirection.text}</p>;
            })}
          </div>
          <MessagesArea
            messages={messages}
            metaMessages={[]}
            deleteMessage={deleteMessage}
            characters={characters}
            messagingInProgress={record?.messagingInProgress || false}
          />
          <Button
            onClick={() => setMessageLimit(lim => lim + 8)}
            style={{ background: "#333", color: "#fff", marginLeft: 8 }}
          >
            Load More
          </Button>
        </div>
        <div
          className="lower-inner"
          style={{ textAlign: "center", padding: 20 }}
        >
          {renderTryItButton()}
        </div>
      </div>
      <BackgroundThing />
    </>
  );
}

export default Page;
