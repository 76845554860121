import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView, log } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { ClientAccessTokenDashboard } from "./ClientAccessTokenDashboard";
import { Service } from "../../models";
import "./style.css";

function ServicesDashboard() {
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();
  const [records, setRecords] = useState(null);
  const [queryLimit, setQueryLimit] = useState(10);
  const COLLECTION = "services";

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION
    );
    const q = query(
      collectionRef,
      orderBy("lastUpdated", "desc"),
      limit(queryLimit)
    );
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        records.push(data);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [queryLimit, activeProjectId]);

  const loadMore = () => setQueryLimit(queryLimit + 10);

  const createService = () => {
    if (!activeProjectId) return;
    log("createService");
    const rec = Service();
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    setDoc(docRef, rec);
    history.push("/services/" + rec.id);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        onClick={createService}
        variant="contained"
        sx={{ float: "right" }}
      >
        New Service
      </Button>
      <h2>Services</h2>
      <p>
        Services let you go between natural language interfaces and the rest of
        the web. They use <Link to="/templates">Templates</Link> to coax the
        data you need out of human prompts, turn it into something a machine
        would understand, and then to reverse the trick, turning the machine's
        response into something a human would understand.
      </p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Changed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records
              ? records.map((record, index) => {
                  const { id, displayName = "", lastUpdated } = record;
                  const path = "/services/" + id;
                  return (
                    <TableRow
                      key={id}
                      className="animated-fade-in-from-left"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "--delay": `${index * 0.1}s`,
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Link to={path}> {displayName} </Link>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {lastUpdated
                          ? new Date(
                              lastUpdated.seconds * 1000
                            ).toLocaleString()
                          : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={loadMore} style={{ float: "right" }}>
        Load More
      </Button>
    </Box>
  );
}

const Page = () => {
  useLogPageView("Services");
  return (
    <MinorFrame>
      <ServicesDashboard />
      <ClientAccessTokenDashboard />
    </MinorFrame>
  );
};

export default Page;
