import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import TextField from "../../components/text-field/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import "./style.css";

const COLLECTION = "client_access_tokens";

const Space = () => <div style={{ height: "12px" }} />;

function Data() {
  useLogPageView("ClientAccessToken");
  const [record, setRecord] = useState(null);
  const { activeProjectId } = useEphemera();
  const history = useHistory();
  const { id } = useParams();
  const recordId = id;

  useEffect(() => {
    if (!recordId) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, recordId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      const data = snap.data();
      setRecord(data);
    });
    return unsubscribe;
  }, [recordId, activeProjectId]);

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, recordId));
    history.push("/services");
  };

  const updateRecord = update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, recordId);
    const lastUpdated = serverTimestamp();
    setDoc(docRef, { ...update, lastUpdated }, { merge: true });
  };

  const setDisplayName = displayName => updateRecord({ displayName });

  const setDescription = description => updateRecord({ description });

  if (!record) return null;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <TextField
        type="text"
        label="Display Name"
        variant="standard"
        fullWidth
        value={record.displayName}
        onChange={e => setDisplayName(e.target.value)}
      />
      <Space />
      <TextField
        type="text"
        label="Description"
        variant="standard"
        fullWidth
        value={record.description || ""}
        onChange={e => setDescription(e.target.value)}
      />
      <Space />
      <h2 className="faint">Project ID: {activeProjectId}</h2>
      <h2>Auth Token: {record?.secret} </h2>
      <Space />
      <Space />
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="secondary" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
