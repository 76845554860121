import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CharacterImage } from "../../components/character-image/";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Studio } from "../../models";
import "./style.css";

const COLLECTION = "studios";
const COLLECTION_DISPLAY_NAME = "Studios";

function StudioCard({ studio, style = {} }) {
  const [records, setRecords] = useState([]);
  const { activeProjectId = "" } = useEphemera();

  useEffect(() => {
    if (!activeProjectId) return;
    if (!studio || !studio.id) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      "image_requests"
    );
    const q = query(
      collectionRef,
      orderBy("created", "desc"),
      where("studioId", "==", studio.id),
      limit(5)
    );
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [activeProjectId, studio]);

  const { id, displayName } = studio;
  const path = `/${COLLECTION}/${id}`;

  return (
    <div className="studio-card animated-fade-in-from-bottom" style={style}>
      <div style={{ height: "36px", padding: "8px" }}>{displayName}</div>
      <Link to={path}>
        <CharacterImage
          imageId={records[0]?.responseImageId}
          dimensions={256}
        />
      </Link>
      <div style={{ textAlign: "center" }}>
        {[1, 2, 3].map(i => {
          if (!records[i]) {
            return (
              <div
                key={"dummy" + i}
                style={{
                  display: "inline-block",
                  width: 64,
                  height: 12,
                  margin: 4,
                }}
              />
            );
          }
          return (
            <div
              key={records[i].id + "-placeholder"}
              style={{
                display: "inline-block",
                margin: 4,
                padding: 0,
                width: 64,
                height: 64,
              }}
            >
              <CharacterImage
                imageId={records[i].responseImageId}
                dimensions={64}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Data() {
  useLogPageView("Studios");
  const [records, setRecords] = useState([]);
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION
    );
    const q = query(collectionRef, orderBy("lastUpdated", "desc"));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [activeProjectId]);

  const handleSubmit = () => {
    if (!activeProjectId) return;
    const rec = Studio();
    setDoc(doc(db, "projects", activeProjectId, COLLECTION, rec.id), rec);
    history.push(`/${COLLECTION}/${rec.id}`);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{ float: "right" }}
      >
        New Studio
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      {records.map((record, index) => (
        <StudioCard
          studio={record}
          key={record.id}
          style={{ "--delay": index * 0.1 + "s" }}
        />
      ))}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
