import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

// this is the page that will be shown after a successful payment
const Page = () => {
  return (
    <div className="payment-success">
      <h1>Payment Success!</h1>
      <p>Thank you for your payment</p>
      <Link to="/chats">Go back to chats</Link>
    </div>
  );
};

export default Page;
