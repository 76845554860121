import Button from "@mui/material/Button";
import { v4 as uuid } from "uuid";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { signOut, updateProfile } from "firebase/auth";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import TextField from "../../components/text-field/";
import { auth, db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Project } from "../../models";
import "./style.css";

function Data() {
  useLogPageView("Settings");
  const [user] = useAuthState(auth);
  const [projectIds, setProjectIds] = useState([]);
  const [projects, setProjects] = useState({});
  const [email, setEmail] = useState("");
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();

  useEffect(() => {
    if (!user) return;
    // get all the project Ids that this user id a member of
    const docRef = doc(db, "meta", "roles", "member", user.uid);
    const unsub = onSnapshot(docRef, snapshot => {
      if (!snapshot.exists()) return;
      const data = snapshot.data();
      const projectIds = Object.keys(data).filter(key => data[key]);
      setProjectIds(projectIds);
    });
    return unsub;
  }, [user]);

  useEffect(() => {
    if (!projectIds) return;
    if (!projectIds.length) return;
    const unsubbables = [];
    projectIds.forEach(pid => {
      const docRef = doc(db, "projects", pid);
      const unsubby = onSnapshot(docRef, snap => {
        const data = snap.data();
        setProjects(projects => ({
          ...projects,
          [data.id]: data,
        }));
      });
      unsubbables.push(unsubby);
    });
    const unsub = () => unsubbables.forEach(unsubby => unsubby());
    return unsub;
  }, [projectIds]);

  const updateRecord = async update => {
    if (!user) return;
    const docRef = doc(db, "users", user.uid);
    await setDoc(docRef, update, { merge: true });
  };

  const changeActiveProjectId = async activeProjectId => {
    if (!user) return;
    log("changeActiveProjectId");
    const docRef = doc(db, "ephemera", user.uid);
    return await setDoc(docRef, { activeProjectId }, { merge: true });
  };

  const changeActiveProjectName = async displayName => {
    if (!user) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId);
    return await setDoc(docRef, { displayName }, { merge: true });
  };

  const updateDisplayName = displayName => {
    updateRecord({ displayName });
    updateProfile(auth.currentUser, { displayName })
      .then(update => {
        console.log("updated display name", update);
      })
      .catch(console.error);
  };

  if (!user) return null;

  const createNewProject = async () => {
    log("createProject");
    const project = Project();
    const docRef = doc(
      db,
      "users",
      user.uid,
      "create_project_requests",
      project.id
    );
    await setDoc(docRef, project);
  };

  async function logOut() {
    log("signOut");
    await signOut(auth);
    history.push("/");
  }

  const createProjectInvite = async () => {
    if (!email) return;
    log("createProjectInvite");
    const id = uuid();
    const invite = { id, email };
    const inviteDocRef = doc(db, "projects", activeProjectId, "invites", id);
    console.log("inviting user to project ", email);
    setEmail("");
    await setDoc(inviteDocRef, invite);
  };

  const renderProjectSelectArea = () => {
    if (!projectIds.length) return null;
    if (!activeProjectId) return null;
    const activeProject = projects[activeProjectId];
    const displayName = activeProject?.displayName || "";
    return (
      <div>
        <h1>Projects</h1>
        <h3>Active Project: {displayName}</h3>
        <p>
          Projects are a great way to organize your characters, templates,
          sources, and services.
        </p>
        <p>
          It's a good idea to create a new project when you want to separate
          different types of characters, like having one project for work and
          another for your DnD campaign or personal writing.
        </p>
        <h4>Change your Active Project</h4>
        <Select
          fullWidth
          value={activeProjectId || ""}
          onChange={e => changeActiveProjectId(e.target.value)}
        >
          {projectIds.map(pid => {
            const project = projects[pid];
            if (!project) return null;
            return (
              <MenuItem key={pid} value={pid}>
                {project.displayName}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  let activeProjectDisplayName = "";
  if (activeProjectId) {
    const activeProject = projects[activeProjectId];
    activeProjectDisplayName = activeProject?.displayName || "";
  }
  console.log("activeProjectDisplayName", activeProjectDisplayName);

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ padding: "12px" }}>
        <TextField
          label="User Display Name"
          fullWidth
          value={user.displayName}
          onChange={e => updateDisplayName(e.target.value)}
          InputProps={{ style: { fontSize: "2em" } }}
        />
        <div style={{ height: "40px" }} />
        <TextField
          label="Active Project Display Name"
          fullWidth
          value={activeProjectDisplayName}
          onChange={e => changeActiveProjectName(e.target.value)}
        />
        <div style={{ height: "40px" }} />
        <TextField
          label="Email Address"
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button variant="contained" onClick={() => createProjectInvite()}>
          Invite a User to this Project by Email
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ background: "#f0f0f0", padding: "12px" }}>
          {renderProjectSelectArea()}
          <div style={{ height: "40px" }} />
          <Button
            color="primary"
            variant="contained"
            sx={{ float: "right" }}
            onClick={createNewProject}
          >
            New Project
          </Button>
          <div style={{ clear: "both" }} />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button color="error" onClick={() => logOut()}>
          Sign Out
        </Button>
      </Grid>
    </Grid>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
