import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../db";

export function CharacterImage({ imageId, dimensions = 512 }) {
  const [record, setRecord] = useState(null);
  const perSecondCost = 0.0023; // dollars

  useEffect(() => {
    if (!imageId) return;
    const COLLECTION = `character_images_${dimensions}x${dimensions}`;
    const docRef = doc(db, COLLECTION, imageId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId, dimensions]);

  if (!record || !record.imageData) {
    const nullStyle = {
      height: dimensions,
      maxWidth: "100%",
      maxHeight: "100vw",
      background: "#333",
    };
    return <div style={nullStyle} />;
  }

  let cost = null;
  if (record.lastUpdated && record.created) {
    cost = (
      <div>
        Cost: $
        {(record.lastUpdated.seconds - record.created.seconds) * perSecondCost}
      </div>
    );
  }

  return (
    <span>
      <img
        alt=""
        src={"data:image/png;base64, " + record.imageData}
        style={{ maxWidth: "100%" }}
      />
      {false && cost}
    </span>
  );
}
