import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  collection,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import "./style.css";

const COLLECTION = "image_requests";
const COLLECTION_DISPLAY_NAME = "Characters Images";
const queryIncrement = 12;

const style = {
  display: "inline-block",
  border: "1px solid black",
  margin: "8px",
  padding: "4px",
  textAlign: "center",
};

function CharacterImage({ imageId, dimension = 512 }) {
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (!imageId) return;
    const docRef = doc(
      db,
      `character_images_${dimension}x${dimension}`,
      imageId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageId, dimension]);

  if (!record) return null;

  return (
    <img
      alt=""
      width={dimension + "px"}
      src={"data:image/png;base64, " + record.imageData}
    />
  );
}

function Data() {
  useLogPageView("Gallery");
  const [records, setRecords] = useState([]);
  const { activeProjectId = "" } = useEphemera();
  const [queryLimit, setQueryLimit] = useState(queryIncrement);

  useEffect(() => {
    if (!activeProjectId) return;
    const q = query(
      collection(db, "projects", activeProjectId, COLLECTION),
      orderBy("created", "desc"),
      limit(queryLimit)
    );
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        records.push(record);
      });
      setRecords(records);
    });
    return unsub;
  }, [activeProjectId, queryLimit]);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h1>{COLLECTION_DISPLAY_NAME}</h1>
      {records.map(record => {
        return (
          <div style={style} key={record.id}>
            <Link to={"/images/" + record.id}>
              <CharacterImage
                imageId={record.responseImageId}
                dimension={256}
              />
            </Link>
            <div>
              <Button
                variant="contained"
                fullWidth
                color="error"
                onClick={() => {
                  if (!activeProjectId) return;
                  const docRef = doc(
                    db,
                    "projects",
                    activeProjectId,
                    COLLECTION,
                    record.id
                  );
                  return deleteDoc(docRef);
                }}
              >
                Delete Image
              </Button>
            </div>
          </div>
        );
      })}
      <div>
        <Button
          onClick={() => {
            setQueryLimit(queryLimit + queryIncrement);
          }}
        >
          Load More...
        </Button>
      </div>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
