import React from "react";
import { Redirect, Route } from "react-router-dom";
import Character from "./pages/character/";
import Characters from "./pages/characters/";
import Chat from "./pages/chat/";
import Chats from "./pages/chats/";
import CircleIcons from "./pages/circle-icons";
import ClientAccessToken from "./pages/client-access-token/";
import FeatureFlag from "./pages/feature-flag/";
import FeatureFlags from "./pages/feature-flags/";
import ExampleDatas from "./pages/example-datas/";
import ExampleData from "./pages/example-data/";
import Gallery from "./pages/gallery/";
import Icon from "./pages/icon/";
import Icons from "./pages/icons/";
import ImagePage from "./pages/image/";
import Landing from "./pages/landing/";
import LegalPage from "./pages/legal/";
import LoginPage from "./pages/login/";
import Package from "./pages/package/";
import Packages from "./pages/packages/";
import PaymentSuccess from "./pages/payment-success/";
import Payments from "./pages/payments/";
import Product from "./pages/product/";
import Products from "./pages/products/";
import Projects from "./pages/projects/";
import Project from "./pages/project/";
import PublicChat from "./pages/public-chat/";
import PublicPackage from "./pages/public-package/";
import PublicPackages from "./pages/public-packages/";
import Service from "./pages/service/";
import Services from "./pages/services/";
import Settings from "./pages/settings/";
import Source from "./pages/source/";
import Sources from "./pages/sources/";
import Studio from "./pages/studio/";
import Studios from "./pages/studios/";
import Plan from "./pages/plan/";
import Plans from "./pages/plans/";
import Template from "./pages/template/";
import Templates from "./pages/templates/";
import User from "./pages/user/";
import Users from "./pages/users/";
import UserStatus from "./pages/analytics-user-status/";
import "./style.css";
import { SchemaInterface } from "./pages/template/SchemaInterface";

export const unprotectedPaths = [
  ["", Landing],
  ["login", LoginPage],
  ["landing", Landing],
  ["client_access_tokens/:id", ClientAccessToken, "Client Access Token"],
  ["icons", Icons, "Icons"],
  ["icons/:id", Icon, "Icon"],
  ["services", Services, "Services"],
  ["services/:id", Service, "Service"],
  ["payments", Payments, "Payments"],
  ["payment-success", PaymentSuccess, "Payment Success"],
  ["products", Products, "Products"],
  ["products/:id", Product, "Product"],
  ["sources", Sources, "Sources"],
  ["sources/:id", Source, "Source"],
  ["templates", Templates, "Templates"],
  ["templates/:id", Template, "Template"],
  ["circle-icons", CircleIcons, "Circle Icons"],
  ["legal", LegalPage, "Legal"],
  ["settings", Settings, "Settings"],
  ["chats", Chats, "Chats"],
  ["chats/:id", Chat, "Chat"],
  ["characters", Characters, "Characters"],
  ["characters/:id", Character, "Character"],
  ["studios", Studios, "Studios"],
  ["studios/:id", Studio, "Studio"],
  ["gallery", Gallery, "Gallery"],
  ["images/:id", ImagePage, "Image"],
  ["packages", Packages, "Packages"],
  ["packages/:id", Package, "Package"],
  [
    "public-packages/:packageId/archive/:archiveId/chat/:chatId",
    PublicChat,
    "Public Chat",
  ],
  ["public-packages", PublicPackages, "Public Packages"],
  ["public-packages/:id", PublicPackage, "Public Package"],
  ["plans", Plans, "Plans"],
  ["plans/:id", Plan, "Plan"],
  ["json-schema-playground", SchemaInterface, "JSON Schema playground"],
  ["example-datas", ExampleDatas, "Example Datas"],
  ["example-datas/:id", ExampleData, "Example Data"]
];

export const adminPaths = [
  ["admin/users", Users, "Users"],
  ["admin/users/:id", User, "User"],
  ["admin/feature-flags", FeatureFlags, "Feature Flags"],
  ["admin/feature-flags/:id", FeatureFlag, "Feature Flag"],
  ["admin/projects", Projects, "Projects"],
  ["admin/projects/:id", Project, "Project"],
  ["admin/analytics/status/users", UserStatus, "User Status"],
];

export const RoutePath = route => {
  return (
    <Route
      key={"route-key-" + route[0]}
      exact
      path={"/" + route[0]}
      component={route[1]}
    />
  );
};

export const ProtectedRoutePath = (route, loading, user) => {
  if (loading) {
    return (
      <Route key={"route-key-" + route[0]} exact path={"/" + route[0]}>
        <div>Loading...</div>
      </Route>
    );
  } else if (user) {
    return RoutePath(route);
  }
  return <Redirect key={"route-key-" + route[0]} to="/login" />;
};
