import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Canvas from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import { db } from "../../db";
import { COLLECTION, COLLECTION_DISPLAY_NAME } from "./constants";
import "./style.css";

const IconCard = props => {
  const { id, gen = "archimedes" } = props;
  return (
    <Paper
      sx={{ width: "100%", bgcolor: "background.paper" }}
      key={id}
      elevation={1}
    >
      <div>
        <Canvas seed={id} style={{ width: 256 }} gen={gen} />
        <Canvas seed={id} style={{ width: 128 }} gen={gen} />
        <Canvas seed={id} style={{ width: 64 }} gen={gen} />
        <Canvas seed={id} style={{ width: 32 }} gen={gen} />
      </div>
      <div>
        <Canvas seed={id} style={{ width: 512 }} gen={gen} />
      </div>
    </Paper>
  );
};

function Data() {
  const [record, setRecord] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    const docRef = doc(db, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      const data = snap.data();
      if (!data) return;
      setRecord(data);
      setDisplayName(data.displayName || "");
    });
    return unsubscribe;
  }, [id]);

  const changeName = async () => {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, { displayName });
  };

  const deleteRecord = async () => {
    await deleteDoc(doc(db, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  if (!record) return null;

  return (
    <Box sx={{ flexGrow: 1 }} className="padded">
      <h2>
        {COLLECTION_DISPLAY_NAME}: {record.displayName}
      </h2>
      <IconCard id={id} displayName={record.displayName} gen={record.gen} />
      <TextField
        type="text"
        fullWidth
        value={displayName}
        onChange={e => setDisplayName(e.target.value)}
      />
      <Button fullWidth variant="contained" onClick={changeName}>
        Change Name
      </Button>
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="secondary" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
