import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import {
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Handlebars from "handlebars";
import React, { useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { Link, useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Ff from "../../components/ff";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePaginationWithWhere } from "../../hooks/useFirestorePagination";
import {
  defaultData,
  defaultTemplateString,
  ImageRequest,
  Tag,
} from "../../models";
import { CharacterImage } from "./CharacterImage";
import "./style.css";

const COLLECTION = "studios";
//const STABLE_DIFFUSION = "stable-diffusion-xl-beta-v2-2-2";
const OPEN_JOURNEY =
  "ad59ca21177f9e217b9075e7300cf6e14f7e5b4505b87b9689dbd866e9768969";
const SDXL = "8beff3369e81422112d93b89ca01426147de542cd4684c244b673b105188fe5f";

const randomInt = () => Math.floor(Math.random() * 1000000000);

// get the last item in an array
Handlebars.registerHelper("last", function (arr, key) {
  return arr[arr.length - 1][key] || "";
});

Handlebars.registerHelper("json", function (context) {
  return JSON.stringify(context, null, 2);
});

const Spacer = () => <div style={{ height: 20 }} />;

const BaseImageDisplay = ({ imageRequestId, activeProjectId }) => {
  const [record, setRecord] = useState(null);
  useEffect(() => {
    if (!imageRequestId) return;
    if (!activeProjectId) return;
    const COLLECTION = "image_requests";
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      imageRequestId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) {
        return null;
      }
      const rec = snap.data();
      setRecord(rec);
    });
    return unsubscribe;
  }, [imageRequestId, activeProjectId]);

  if (!imageRequestId) return null;
  if (!record?.responseImageId) return null;

  return (
    <div style={{ maxWidth: "100%", height: 64, maxHeight: "100vw" }}>
      <CharacterImage imageId={record?.responseImageId} dimensions={64} />
    </div>
  );
};

function Data() {
  useLogPageView("Studio");
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const activeTagInputRef = useRef();
  const inactiveTagInputRef = useRef();
  const [credits, setCredits] = useState(0);
  const [templateString, setTemplateString] = useState(defaultTemplateString);
  const [seed, setSeed] = useState(1);
  const [data, setData] = useState(defaultData);
  const colPath = "projects/" + activeProjectId + "/image_requests";
  let [completions = [], loadMore, hasMore, deleteById] =
    useFirestorePaginationWithWhere(colPath, 3, "created", [
      "studioId",
      "==",
      id,
    ]);
  const [newActiveTag, setNewActiveTag] = useState("");
  const [newInactiveTag, setNewInactiveTag] = useState("");

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setRecord(rec);
      console.log("rec: ", rec);
      if (rec.seed) setSeed(rec.seed);
      // compile the templateString
      if (rec.templateString) {
        const tplt = Handlebars.compile(rec.templateString);
        const prompt = tplt(rec.exampleData);
        console.log("prompt: ", prompt);
      }
      setTemplateString(rec.templateString);
      if (rec.exampleData) setData(rec.exampleData);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the project credits
  useEffect(() => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, "meta", "credits");
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return null;
      const rec = snap.data();
      setCredits(rec.amount || 0);
    });
    return unsubscribe;
  }, [activeProjectId]);

  if (!record) return null;

  const updateRecord = update => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    update.lastUpdated = serverTimestamp();
    updateDoc(docRef, update, { merge: true });
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const duplicateRecord = async () => {
    if (!activeProjectId) return;
    const newRec = { ...record };
    newRec.id = uuid();
    newRec.displayName = newRec.displayName + " (copy)";
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, newRec.id);
    await setDoc(docRef, newRec);
    history.push("/" + COLLECTION);
  };

  const saveTemplate = () => {
    log("updateStudioTemplate");
    updateRecord({ templateString });
  };

  const selectEngine = engineId => {
    log("updateStudioEngine");
    updateRecord({ engineId });
  };

  const makeBaseImage = async completion => {
    console.log("makeBaseImage");
    console.log("completion: ", completion);
    updateRecord({ baseImageId: completion.id });
  };

  const createImage = async () => {
    if (!activeProjectId) return null;
    log("createImageInStudio");
    const tplt = Handlebars.compile(templateString);
    const prompt = tplt(data);
    // and trigger the test
    const request = ImageRequest(prompt);
    request.studioId = record.id;
    request.projectId = activeProjectId;
    request.studioDisplayName = record.displayName;
    request.templateString = templateString;
    request.data = data;
    request.seed = seed;
    request.engineId = record.engineId || OPEN_JOURNEY;
    if (record.baseImageId) request.baseImageId = record.baseImageId;
    const testDocRef = doc(
      db,
      "projects",
      activeProjectId,
      "image_requests",
      request.id
    );
    await setDoc(testDocRef, request);
  };

  const resetTemplate = () => {
    updateRecord({
      templateString: defaultTemplateString,
      exampleData: defaultData,
    });
  };

  const renderPrompt = prompt => {
    return <div className="template-preview">{prompt}</div>;
  };

  const renderInterpolatedTemplate = (templateString, data) => {
    try {
      const tplt = Handlebars.compile(templateString);
      const prompt = tplt(data);
      return renderPrompt(prompt);
    } catch (e) {
      return e.message;
    }
  };

  const onEditExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const onDeleteExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const onAddExampleData = data => {
    updateRecord({ exampleData: data.updated_src });
  };

  const renderCompletionJSON = () => {
    if (!completions) return null;
    return (
      <div className="completion-area">
        <ReactJson
          src={completions[0]}
          name={false}
          indentWidth={2}
          collapsed={2}
          collapseStringsAfterLength={12}
        />
      </div>
    );
  };

  const renderCompletionChoices = response => {
    if (!response) return null;
    return (
      <div className="completion-choices-area">
        <h3>Response</h3>
        <ReactJson
          src={response}
          name={false}
          indentWidth={2}
          collapsed={0}
          collapseStringsAfterLength={12}
        />
      </div>
    );
  };

  const addInactiveTag = () => {
    if (!newInactiveTag) return;
    const inactiveCharacterTags = record.inactiveCharacterTags || [];
    const newTags = [...inactiveCharacterTags, Tag(newInactiveTag.trim())];
    updateRecord({ inactiveCharacterTags: newTags });
    setNewInactiveTag("");
    inactiveTagInputRef.current.focus();
  };

  const addActiveTag = () => {
    if (!newActiveTag) return;
    const activeCharacterTags = record.activeCharacterTags || [];
    const newTags = [...activeCharacterTags, Tag(newActiveTag.trim())];
    const tags = newTags.map(t => t.text);
    if (!record.exampleData) record.exampleData = {};
    record.exampleData.tags = tags;
    updateRecord({
      activeCharacterTags: newTags,
      exampleData: record.exampleData,
    });
    setNewActiveTag("");
    activeTagInputRef.current.focus();
  };

  const activateTag = tagId => {
    log("activateStudioTag");
    const inactiveCharacterTags = record.inactiveCharacterTags || [];
    const tag = inactiveCharacterTags.find(t => t.id === tagId);
    if (!tag) return;
    const activeCharacterTags = record.activeCharacterTags || [];
    const newInactiveTags = inactiveCharacterTags.filter(t => t.id !== tagId);
    const newActiveTags = [...activeCharacterTags, tag];
    const tags = newActiveTags.map(t => t.text);
    if (!record.exampleData) record.exampleData = {};
    record.exampleData.tags = tags;
    updateRecord({
      inactiveCharacterTags: newInactiveTags,
      activeCharacterTags: newActiveTags,
      exampleData: record.exampleData,
    });
  };

  const deactivateTag = tagId => {
    log("deactivateStudioTag");
    const activeCharacterTags = record.activeCharacterTags || [];
    const tag = activeCharacterTags.find(t => t.id === tagId);
    if (!tag) return;
    const inactiveCharacterTags = record.inactiveCharacterTags || [];
    const newActiveTags = activeCharacterTags.filter(t => t.id !== tagId);
    const newInactiveTags = [...inactiveCharacterTags, tag];
    const tags = newActiveTags.map(t => t.text);
    if (!record.exampleData) record.exampleData = {};
    record.exampleData.tags = tags;
    updateRecord({
      inactiveCharacterTags: newInactiveTags,
      activeCharacterTags: newActiveTags,
      exampleData: record.exampleData,
    });
  };

  const deleteTag = tagId => {
    log("deleteStudioTag");
    const activeCharacterTags = record.activeCharacterTags || [];
    const inactiveCharacterTags = record.inactiveCharacterTags || [];
    const newActiveTags = activeCharacterTags.filter(t => t.id !== tagId);
    const newInactiveTags = inactiveCharacterTags.filter(t => t.id !== tagId);
    const tags = newActiveTags.map(t => t.text);
    if (!record.exampleData) record.exampleData = {};
    record.exampleData.tags = tags;
    updateRecord({
      inactiveCharacterTags: newInactiveTags,
      activeCharacterTags: newActiveTags,
      exampleData: record.exampleData,
    });
  };

  if (!record) return <div>Loading...</div>;

  const renderTagDeletionArea = () => {
    if (!record.activeCharacterTags || !record.inactiveCharacterTags) return;
    return (
      <div className="tag-deletion-area">
        {record.activeCharacterTags.map(tag => {
          return (
            <div key={"deletion-controls-" + tag.id}>
              <div className="tag-text">{tag.text}</div>
              <div className="tag-controls">
                <button onClick={() => deactivateTag(tag.id)}>
                  Deactivate
                </button>
                <button onClick={() => deleteTag(tag.id)}>Delete</button>
              </div>
            </div>
          );
        })}
        {record.inactiveCharacterTags.map(tag => {
          return (
            <div key={"deletion-controls-" + tag.id}>
              <div className="tag-text">{tag.text}</div>
              <div className="tag-controls">
                <button onClick={() => deactivateTag(tag.id)}>
                  Deactivate
                </button>
                <button onClick={() => deleteTag(tag.id)}>Delete</button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAdvanced = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Delte Tags</h3>
          {renderTagDeletionArea()}
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Example Data</h3>
          <ReactJson
            src={data}
            name={false}
            indentWidth={2}
            collapsed={2}
            collapseStringsAfterLength={12}
            displayDataTypes={true}
            onEdit={onEditExampleData}
            onDelete={onDeleteExampleData}
            onAdd={onAddExampleData}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Template</h3>
          <TextField
            value={templateString}
            onChange={e => setTemplateString(e.target.value)}
            multiline
            rows={20}
            fullWidth
          />
          <div style={{ height: 40 }} />
          <Button variant="contained" onClick={() => saveTemplate()}>
            Save
          </Button>
          <Button variant="contained" onClick={() => resetTemplate()}>
            Reset
          </Button>
          <div>
            <p>
              Templates are made with the Handlebars templating language.{" "}
              <a
                href="https://handlebarsjs.com/guide/"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </a>
              .
            </p>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Example Output Prompt</h3>
          {renderInterpolatedTemplate(templateString, data)}
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Response Data</h3>
          {renderCompletionJSON()}
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Prompt</h3>
          {renderPrompt(completions[0]?.prompt)}
        </Grid>
        <Grid item xs={12} lg={4}>
          <CharacterImage imageId={completions[0]?.response?.id} />
          {renderCompletionChoices(completions[0]?.response)}
        </Grid>
      </Grid>
    );
  };

  const renderRecentHistory = () => {
    return (
      <Card>
        {completions.map((completion, index) => {
          const id = completion.response?.id;
          if (!id) return null;
          const tags = [];
          if (completion?.data?.tags) {
            completion.data.tags.forEach((tag, i) => {
              tags.push(
                <div
                  style={{
                    display: "inline-block",
                    margin: 4,
                    fontSize: "0.8em",
                    backgroundColor: "#333",
                    color: "#fff",
                    border: "1px solid #555",
                    borderRadius: 24,
                    padding: "4px 8px",
                  }}
                  key={"history-" + index + "-" + i}
                >
                  {tag}
                </div>
              );
            });
          }
          return (
            <div
              style={{
                minHeight: "64px",
                border: "1px solid #ccc",
                padding: "8px",
                margin: "4px",
              }}
              key={"image-history-" + completion.id}
            >
              <span className="float-right">
                <Link to={"/images/" + completion.id}>
                  <CharacterImage
                    imageId={completion.response.id}
                    dimensions={128}
                  />
                </Link>
              </span>
              {tags}
              <p className="faint">{id}</p>
              <Button
                variant="contained"
                onClick={() => makeBaseImage(completion)}
                sx={{ background: "#eee", color: "#000" }}
              >
                Make Base Image
              </Button>
              <Button
                color="error"
                sx={{ float: "right" }}
                onClick={() => {
                  return deleteById(completion.id);
                }}
              >
                Delete
              </Button>
              <div style={{ clear: "both" }} />
            </div>
          );
        })}
        {hasMore && loadMore && (
          <Button onClick={() => loadMore()}>Load More</Button>
        )}
      </Card>
    );
  };

  const renderMostRecentCharacterImage = () => {
    const imageId = completions[0]?.response?.id;
    const style = {
      maxWidth: "100%",
      height: 512,
      maxHeight: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 30,
      color: "#fff",
      backgroundColor: "#333",
    };
    if (completions[0]?.status === "PENDING") {
      return <div style={style}>Generating Image...</div>;
    } else if (!imageId) {
      // center vertically and horizontally
      return <div style={style}>No Images Yet</div>;
    }
    return <CharacterImage imageId={imageId} />;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Tf
        label="Template Name"
        fullWidth
        value={record?.displayName}
        onChange={e => updateRecord({ displayName: e.target.value })}
        InputProps={{ style: { fontSize: 24 } }}
      />
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} lg={4}>
          <div style={{ height: 20 }} />
          <div
            style={{
              maxWidth: "100%",
              height: 512,
              maxHeight: "100vw",
              textAlign: "right",
            }}
          >
            {renderMostRecentCharacterImage()}
          </div>
          <div style={{ height: 20 }} />
          {credits > 0 ? (
            <Button
              onClick={createImage}
              variant="contained"
              sx={{ background: "yellow", color: "#333" }}
            >
              New Image
            </Button>
          ) : (
            <p>
              Buy more credits on the <Link to="/payments">payments page</Link>
            </p>
          )}
          {record?.baseImageId && (
            <Button onClick={() => updateRecord({ baseImageId: "" })}>
              Clear Base Image
            </Button>
          )}
          <span style={{ float: "right" }}>
            <BaseImageDisplay
              imageRequestId={record?.baseImageId}
              activeProjectId={activeProjectId}
            />
          </span>
          <div style={{ clear: "both" }} />
          <span style={{ paddingLeft: 20 }}>
            <Spacer />
            <div>
              <Tf
                label="Seed"
                value={seed}
                type="number"
                onChange={e => updateRecord({ seed: parseInt(e.target.value) })}
                InputProps={{ style: { fontSize: 24 } }}
                size="small"
              />
              <Button
                onClick={() => updateRecord({ seed: randomInt() })}
                variant="contained"
                sx={{ background: "#333", height: 52, marginLeft: 8 }}
              >
                Randomize Seed
              </Button>
            </div>
            <RadioGroup
              row
              sx={{ display: "inline" }}
              value={record?.engineId || OPEN_JOURNEY}
            >
              <FormControlLabel
                value={OPEN_JOURNEY}
                control={<Radio onChange={() => selectEngine(OPEN_JOURNEY)} />}
                label="Open Journey"
              />
              <FormControlLabel
                value={SDXL}
                control={<Radio onChange={() => selectEngine(SDXL)} />}
                label="SDXL"
              />
            </RadioGroup>
          </span>
        </Grid>
        <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
          <h3>Active Tags</h3>
          <TextField
            label="New Active Tag"
            inputRef={activeTagInputRef}
            fullWidth
            value={newActiveTag || ""}
            onChange={e => setNewActiveTag(e.target.value)}
            InputProps={{ style: { fontSize: 24 } }}
          />
          <Button onClick={addActiveTag}>Add Tag</Button>
          <div>
            {record?.activeCharacterTags?.map(tag => {
              const activeTagStyle = {
                display: "inline-block",
                margin: 4,
                backgroundColor: "#f37",
                color: "#fff",
                border: "1px solid #555",
                cursor: "pointer",
                borderRadius: 24,
                padding: "4px 8px",
              };
              return (
                <div
                  style={activeTagStyle}
                  key={tag.id}
                  onClick={() => deactivateTag(tag.id)}
                >
                  {tag.text}
                </div>
              );
            })}
            {/* align the following div to the bottom of the parent */}
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h3>Candidate Tags</h3>
          <TextField
            label="New Candidate Tag"
            inputRef={inactiveTagInputRef}
            fullWidth
            value={newInactiveTag || ""}
            onChange={e => setNewInactiveTag(e.target.value)}
            InputProps={{ style: { fontSize: 24 } }}
          />
          <Button onClick={addInactiveTag}>Add Tag</Button>
          <div>
            {record?.inactiveCharacterTags?.map(tag => {
              const inactiveTagStyle = {
                display: "inline-block",
                margin: 4,
                backgroundColor: "#eee",
                border: "1px solid #555",
                cursor: "pointer",
                borderRadius: 24,
                padding: "4px 8px",
              };
              return (
                <div
                  style={inactiveTagStyle}
                  key={tag.id}
                  onClick={() => activateTag(tag.id)}
                >
                  {tag.text}
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <div style={{ height: 40 }} />
      {renderRecentHistory()}
      <Ff flag="admin">
        <Accordion>
          <AccordionSummary>Advanced Controls</AccordionSummary>
          <AccordionDetails>{renderAdvanced()}</AccordionDetails>
        </Accordion>
      </Ff>
      <div style={{ height: 40 }} />
      <div style={{ textAlign: "right", margin: "20px 0 20px 0" }}>
        <Button
          variant="contained"
          onClick={() => duplicateRecord()}
          sx={{ background: "#333" }}
        >
          Duplicate studio
        </Button>
      </div>
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="error" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
