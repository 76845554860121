import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { collection, doc, setDoc, onSnapshot, query } from "firebase/firestore";
import slugify from "slugify";
import { db, useLogPageView } from "../../db";
import MinorFrame from "../../components/minor-frame/";
import "./style.css";

const COLLECTION = "feature-flags";
const COLLECTION_DISPLAY_NAME = "Feature Flags";

const cleanName = name => {
  const opts = { lower: true, strict: true };
  return slugify(name.substr(0, 32), opts);
};

function Data() {
  const [records, setRecords] = useState([]);
  const [name, setName] = useState("");
  useLogPageView("FeatureFlags");

  useEffect(() => {
    const q = query(collection(db, COLLECTION));
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        let record = doc.data();
        record.id = doc.id;
        records.push(record);
      });
      setRecords(records);
    });
    return () => unsub();
  }, []);

  const handleSubmit = async evt => {
    evt.preventDefault();
    const slug = cleanName(name);
    console.log(name, slug, COLLECTION);
    await setDoc(doc(db, COLLECTION, slug), { id: slug, displayName: name });
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(record => {
              const { id, displayName } = record;
              const path = "/admin/" + COLLECTION + "/" + id;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row"></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Add new {COLLECTION_DISPLAY_NAME}</h3>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Button fullWidth variant="contained" onClick={handleSubmit}>
          Add Feature Flag
        </Button>
      </form>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <div className="padded">
        <Data />
      </div>
    </MinorFrame>
  );
};

export default Page;
