const pick = arr => arr[(Math.random() * arr.length) | 0];

export const goodNames = `
absurd
fearful
aloof
amazing
ancient
angry
aquatic
awesome
awful
bawdy
big
bitter
blue
bold
brave
breezy
bright
brown
busy
calm
charming
chatty
cheerful
chilly
chosen
civil
clever
cold
colorful
colossal
confident
cruel
decent
deranged
devilish
double
dreamy
dull
durable
elastic
electric
elder
elegant
eloquent
empirical
enhanced
epic
evil
exotic
fabulous
famous
fancy
fast
fearless
feeble
festive
fierce
flashy
flawless
foolish
forlorn
fragile
frail
frank
frantic
fresh
frosty
gallant
gigantic
golden
gorgeous
graceful
gracious
grand
gray
great
green
grey
handsome
hard
hardy
harsh
heavy
hence
heroic
hideous
holy
horrible
hot
huge
human
humane
humble
humorous
hypnotic
ideal
illegal
immense
infamous
innocent
jolly
jovial
joyous
jubilant
just
juvenile
kind
large
lavish
lean
legal
lethal
light
little
long
loud
lovely
loyal
lucid
lucky
macabre
mad
magic
majestic
martial
massive
mean
meek
mellow
melodic
merry
mighty
modern
modest
moody
mundane
naive
naughty
neat
needy
new
nice
nifty
nimble
noble
normal
obscene
odd
ordinary
organic
perfect
placid
plain
plastic
poetic
polite
popular
potent
pragmatic
precious
premium
pretty
prime
prompt
proud
punctual
quaint
quick
quirky
rad
radical
ragged
rapid
real
rebel
red
regal
regular
resolute
rich
rigid
robust
romantic
rotten
rough
royal
rude
rugged
rustic
ruthless
sage
selfish
serene
serious
shallow
sharp
short
shrewd
shy
silent
simple
sincere
skinny
sleek
slick
slim
slow
small
smart
smooth
social
soft
sole
solid
solitary
somber
special
spiffy
stiff
stoic
strange
strong
suave
subtle
sudden
supreme
sweet
swift
tall
tame
tearful
tedious
tender
terrible
thin
thorough
tidy
tough
towering
tranquil
unruly
venomous
vigorous
violent
warm
weak
wicked
wild
wise
witty
worthy
wretched
young
zealous
reflection
schema
damage
epiphany
reaction
creature
glamour
muse
truth
substitute
alternative
founder
pick
deadline
expiration
advance
maneuver
burden
justice
law
gaze
theory
advantage
foundation
solution
loss
engine
operation
judge
presence
ideal
indication
obligation
process
game
guide
reason
action
network
challenge
reprieve
petition
machine
automata
adventure
venture
echo
cache
apocalypse
dance
waltz
dash
race
horizon
logic
interface
breakdown
clash
mess
rule
comeback
consequences
market
paradigm
function
method
theme
trouble
lock
factor
envoy
nonsence
gibberish
exit
guarantee
mistake
expert
mindset
welcome
ruin
holiday
wit
vibe
margin
conscience
surplus
rascal
evidence
indiscretion
wisdom
pass
comedy
nerve
angle
dream
action
decree
variant
inspiration
deviation
domination
macro
notion
vortex
`
  .trim()
  .split(/\s+/);

export const adjectives = `
absurd
admiring
adoring
advanced
atavistic
fearful
aloof
amazing
amusing
amiable
ancient
angry
animated
anti
anxious
aquatic
average
awesome
awful
awkward
bad
bawdy
beefy
big
bitter
blissful
blue
blushing
bold
boring
brave
breezy
bright
brown
busy
calm
charming
chatty
cheerful
chilly
chosen
civil
clean
clever
clingy
clumsy
cold
colorful
colossal
confident
cowardly
cranky
crazy
creepy
cruel
curvy
cute
dazzling
decent
deranged
devilish
dirty
double
dreamy
drunk
dull
durable
eager
elastic
electric
elder
elegant
eloquent
empty
empirical
enhanced
epic
evil
excited
exciting
exotic
fabulous
faded
famed
famous
fancy
fast
fat
fearless
feeble
festive
fierce
filthy
finicky
flashy
flawless
flimsy
fluffy
flying
focused
foolish
forlorn
fragile
frail
frank
frantic
freezing
fresh
friendly
frosty
funny
fuzzy
gallant
gentle
giant
gigantic
glorious
glowing
golden
goofy
gorgeous
graceful
gracious
grand
grateful
gray
greasy
great
greedy
green
grey
groovy
grumpy
gullible
hallowed
handsome
hanging
hapless
happy
hard
hardcore
hardy
harsh
hateful
heavy
hellish
helpful
helpless
hence
heroic
hesitant
hideous
hip
holy
honest
hopeful
horrible
hot
huge
hulking
human
humane
humble
humorous
hungry
hypnotic
icy
ideal
idiotic
ignorant
illegal
immense
impolite
infamous
innocent
inappropriate
itchy
jaded
jazzy
jealous
jittery
jobless
jolly
jovial
joyful
joyous
jubilant
juicy
jumpy
just
juvenile
kind
large
laughing
lavish
lawful
lazy
lean
learned
legal
legit
lethal
light
little
long
loud
lousy
lovely
loyal
lucid
lucky
lying
macabre
mad
magic
majestic
maniacal
martial
massive
mean
meaty
meek
mellow
melodic
merciful
merry
mighty
mindless
misunderstood
modern
modest
moldy
momentary
moody
moonlit
moving
mundane
mushy
naive
nasty
naughty
neat
needy
nervous
new
nice
nifty
nimble
noble
noisy
normal
noxious
obscene
odd
old
ordinary
organic
outgoing
outstanding
pale
paltry
peaceful
perfect
placid
plain
plant
plastic
pleasant
poetic
polite
poor
popular
potent
powerful
pragmatic
precious
premium
pretty
prime
prolific
prompt
proud
pseudo
punctual
pure
puzzled
quaint
quasi
questionable
quick
quiet
quirky
rabid
rad
radical
ragged
rainy
rampant
rapid
rare
real
rebel
red
reformed
regal
regular
relaxed
relieved
resolute
rich
rigid
robust
romantic
rotten
rough
round
royal
rude
rugged
rustic
ruthless
sage
salty
sassy
scared
scary
selfish
serene
serious
shaggy
shaky
shallow
sharp
shiny
shocking
short
shrewd
shy
silent
silly
simple
sincere
skillful
skinny
sleek
sleepy
slick
slim
slippery
slow
small
smart
smooth
snappy
sneaky
social
soft
soggy
sole
solid
solitary
somber
sordid
special
spicy
spiffy
spiky
spiteful
splendid
spooky
spotless
spotty
squalid
stale
sticky
stiff
stingy
stoic
strange
strong
stunning
suave
subdued
subtle
sudden
sulky
supreme
sweet
swift
tactical
talented
tall
tame
tearful
tedious
tender
terrible
terrific
thankful
thin
thorough
tolerant
tidy
tiny
tired
torpid
tough
towering
tranquil
tricky
ugly
unarmed
unbiased
uneven
unique
unkempt
unknown
unlucky
unreliable
unruly
unusual
upbeat
vengeful
venomous
vigorous
violent
warm
wasteful
weak
wealthy
wet
wicked
wiggly
wild
wise
witty
worried
worthy
wrathful
wretched
young
youthful
zealous
`
  .trim()
  .split(/\s+/);

export const nouns = `
trash
garbage
reflection
constraint
schema
drill
damage
emphasis
gradient
qualifier
mask
epiphany
reaction
animal
creature
income
glamour
muse
reality
truth
substitute
alternative
founder
choice
pick
advice
glance
deadline
expiration
advance
maneuver
burden
justice
law
gaze
look
system
theory
thing
unit
concept
skill
advantage
foundation
solution
loss
entry
reputation
tradition
delivery
engine
comparison
operation
sample
extent
bonus
judge
assumption
presence
contribution
affair
ideal
indication
obligation
example
process
game
guide
end
reason
demand
cause
action
plan
side
figure
source
question
tool
network
post
challenge
thought
reprieve
artifact
memory
drive
intent
petition
axiom
definition
machine
automata
wish
plea
request
adventure
venture
education
response
echo
cache
apocalypse
dance
waltz
dash
race
horizon
anticipation
reversal
state
logic
interface
breakdown
clash
mess
rule
news
proof
comeback
consequences
adversary
market
paradigm
function
method
theme
influence
feeling
lesson
trouble
edge
lock
factor
diplomat
envoy
business
development
nonsence
gibberish
rave
exit
ideal
guarantee
layer
mistake
expert
mindset
disposition
blame
foresight
prophecy
adjustment
refund
fear
welcome
specialist
highlight
incident
loss
gain
value
ruin
holiday
wit
report
accomplice
application
groove
vibe
ending
beginning
margin
opportunity
requirement
energy
conscience
surplus
rascal
evidence
indiscretion
demeanour
instructions
wisdom
pass
comedy
tragedy
nerve
promise
scheme
excuse
equivalent
outcome
point
devil
angel
angle
dream
delay
action
event
decree
release
variant
model
idea
inspiration
deviation
flaw
archetype
domination
oversight
experience
story
tale
macro
surprise
curve
notion
ride
enemy
vortex
`
  .trim()
  .split(/\s+/);

export const createNameId = name => {
  return pick(adjectives) + "-" + pick(nouns);
};
