import React from "react";
import Box from "@mui/material/Box";
import "./style.css";

const appName = "dangbot";

const Page = () => {
  return (
    <div className="legal-notice-page">
      <Box>
        <h3>dangbot:</h3>
        <h1>Legal Notice</h1>
        <h2>{appName} Privacy Policy</h2>
        <p>Last updated: May 2023</p>
        <p>
          Welcome to {appName}! We value your privacy and are committed to being
          transparent about our data collection, usage, and sharing practices.
          By using our app, you agree to the terms outlined in this privacy
          policy.
        </p>
        <h3>Information Collection and Use</h3>
        <p>
          We collect various types of information from our users to provide,
          improve, and personalize our services. The types of information
          collected may include, but are not limited to:
        </p>
        <ul>
          <li>
            <strong>a. Personal Information:</strong> When you register for an
            account, we collect information such as your name, email address,
            phone number, and any other information you choose to provide.
          </li>
          <li>
            <strong>b. Usage Information:</strong> We collect information about
            your interactions with the app, including the pages you visit,
            content you interact with, and features you use.
          </li>
          <li>
            <strong>c. Device and Location Information:</strong> We collect
            information about your device, such as device model, operating
            system, and IP address. We may also collect your location
            information using various technologies, such as GPS, Wi-Fi, or
            cellular data.
          </li>
        </ul>
        <p>
          By using {appName}, you grant us the right to collect, store, use, and
          process your data for any purpose we deem necessary. This may include
          analyzing user behavior, personalizing content, improving our
          services, or developing new features.
        </p>
        <h3>Information Sharing and Disclosure</h3>
        <p>
          We may share your information with third parties for various purposes,
          such as:
        </p>
        <ul>
          <li>
            <strong>a. Service Providers:</strong> We may share your information
            with third-party service providers who help us operate and maintain
            the app, provide customer support, or perform other services on our
            behalf.
          </li>
          <li>
            <strong>b. Business Transfers:</strong> We may share your
            information in connection with a merger, acquisition, or sale of all
            or a portion of our assets.
          </li>
          <li>
            <strong>c. Legal Requirements:</strong> We may disclose your
            information if required by law or in response to legal processes,
            such as subpoenas or court orders.
          </li>
        </ul>
        <p>
          By using {appName}, you agree that we may share your information as
          described in this section.
        </p>
        <h3>Data Retention and Security</h3>
        <p>
          We retain your information for as long as necessary to fulfill the
          purposes outlined in this privacy policy. We implement reasonable
          security measures to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no method of
          transmission or storage is completely secure, and we cannot guarantee
          the absolute security of your information.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We reserve the right to update or modify this privacy policy at any
          time. We will notify you of any changes by posting the updated policy
          on this page, and your continued use of {appName} following the
          posting of any changes constitutes your acceptance of those changes.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this privacy policy,
          please contact us at support@dangbot.com. By using
          {appName}, you agree to this privacy policy and give us permission to
          collect, use, and share your data as outlined above.
        </p>
      </Box>
    </div>
  );
};

export default Page;
